import { getEnvironment } from "helpers"

export const ObjectToURLParams = (data) => {
	try {
		const entries = Object.entries(data).filter((datum) => datum[1] !== undefined)
		if (entries.length === 0) return ""

		const params = entries.map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
		return params.join("&")
	} catch {
		return ""
	}
}

export const URLParamsToObject = (string) => {
	const urlParams = new URLSearchParams(string)
	const entries = urlParams.entries()
	const data = {}
	for (const [key, value] of entries) {
		data[key] = /^[0-9]*$/.test(value) ? parseInt(value) : value
	}
	return data
}

export const NumberFormat = {
	money: new Intl.NumberFormat("es-MX", {
		style: "currency",
		currency: "MXN",
		minimumFractionDigits: 2,
	}).format,
	percent: new Intl.NumberFormat("es-MX", {
		style: "percent",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format,
}

export const generateRandomColor = () => {
	var letters = "0123456789ABCDEF"
	var color = "#"
	for (var i = 0; i < 6; i++) color += letters[Math.floor(Math.random() * 16)]
	return color
}

export const extractUrl = () => {
	const env = getEnvironment()
	const isDevEnv = env.isDevelopment
	const hostname = window.location.hostname
	const urlParts = hostname.split(".")
	const hasSubdomain = urlParts.length > (isDevEnv ? 1 : 2)
	const host = hasSubdomain ? urlParts[0] : process.env.REACT_APP_KIKOYA_MERCHANT
	const subdomain = hasSubdomain ? urlParts[0] : null
	let domain
	if (hasSubdomain) domain = isDevEnv ? urlParts[1] : `${urlParts[1]}.${urlParts[2]}`
	else domain = isDevEnv ? urlParts[0] : `${urlParts[0]}.${urlParts[1]}`

	return {
		hostname,
		host,
		subdomain,
		domain,
		hasSubdomain,
		origin: window.location.origin,
	}
}

export const formatPhoneNumber = (phone = "") => {
	if (!phone) return ""
	phone = reverseString(phone)
	const mobilePhoneArray = phone.match(/.{1,2}/g)
	const newPhone = mobilePhoneArray.join("-")
	return reverseString(newPhone)
}

export const reverseString = (str = "") => {
	return str.split("").reverse().join("")
}

export const createStringOfNumbers = (length) => {
	if (typeof length !== "number") return ""
	return [...Array(length).keys()].map((i) => (i + 1) % 10).join("")
}
