import axios from "axios"
import { getBaseURL } from "helpers"
import { handleCommonErrors } from "models/helpers"

const __endpoint = getBaseURL() + "/api/v1/lender"

const get = async (token) => {
	try {
		const headers = { Authorization: "Bearer " + token }
		const body = { headers }
		const response = await axios.get(__endpoint + "/", body)
		return response.data
	} catch (error) {
		await handleCommonErrors(error)
	}
}

export const LenderModel = { __endpoint, get }
