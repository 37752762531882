import "./change_password.scss"

import { HeaderAction } from "../Home/HeaderAction/HeaderAction"
import { Password } from "../../PasswordChange/Password"

export const ChangePassword = () => {
	return (
		<div className="change_password">
			<HeaderAction
				nameComponent="Contraseña"
				idComponent={2}
				nameActionComponent="Cambio de contraseña"></HeaderAction>
			<Password />
		</div>
	)
}
