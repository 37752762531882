import makeStyles from "@mui/styles/makeStyles"
import AppBar from "@mui/material/AppBar"

const useStyles = makeStyles(() => ({
	root: {
		height: "80px",
	},
}))

export const Header = () => {
	const classes = useStyles()

	return <AppBar position="sticky" className={classes.root} />
}
