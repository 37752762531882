import React, { useEffect } from "react"
import { Grid } from "@mui/material"

import { PrivacyModel } from "models/PrivacyModel"

function TerminosCondiciones() {
	const [terminos, setTerminos] = React.useState("")

	useEffect(() => {
		async function get_config() {
			try {
				const resp = await PrivacyModel.get({ type_config: 11 })
				const jsonData = JSON.parse(resp.config_value)
				let text = ""
				if (jsonData.blocks.length > 0) {
					jsonData.blocks.map((item) => (text += item.text))
					setTerminos(text)
				}
			} catch {
				setTerminos("")
			}
		}

		get_config()
	}, [])

	return (
		<>
			<form className="form__edition">
				<Grid container spacing={2}>
					<Grid item xs={12} md={12} sm={12}>
						<h3
							style={{
								color: "#284056",
								textAlign: "justify",
							}}>
							{terminos}
						</h3>
					</Grid>
				</Grid>
			</form>
		</>
	)
}

export { TerminosCondiciones }
