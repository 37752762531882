import "./index.scss"

import i18n from "i18n"
import theme from "theme"
import { useEffect } from "react"
import { useSelector, Provider } from "react-redux"

import { StyledEngineProvider, ThemeProvider, CssBaseline } from "@mui/material"

import { KikoyaProvider } from "@arbolfinanciero/kikoya-lib-hub/KikoyaProvider"
import { useSingleton } from "@arbolfinanciero/kikoya-lib-hub/KikoyaSingleton"
import { MaintenanceError } from "@arbolfinanciero/kikoya-lib-hub/components/MaintenanceError"

import { AlertMessageProvider } from "./AlertMessageContext"
import { AppRouter } from "./routers/AppRouter"
import { store } from "redux-kikoya/store"
import { initSentry } from "helpers"

const AppContent = () => {
	const { language } = useSelector((state) => state.branding)
	const singleton = useSingleton()

	useEffect(() => {
		initSentry()
	}, [])

	useEffect(() => {
		if (language) i18n.changeLanguage(language)
	}, [language])

	return (
		<KikoyaProvider lng={language || i18n.language}>
			<CssBaseline />
			<MaintenanceError active={singleton.MAINTENANCE_ACTIVATED}>
				<AlertMessageProvider>
					<AppRouter />
				</AlertMessageProvider>
			</MaintenanceError>
		</KikoyaProvider>
	)
}

const App = () => {
	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<Provider store={store}>
					<AppContent />
				</Provider>
			</ThemeProvider>
		</StyledEngineProvider>
	)
}

export default App
