import { createSlice } from "@reduxjs/toolkit"
import { jwtDecode } from "jwt-decode"
import { SessionManager } from "utils/SessionManager"
import { CacheManager } from "utils/CacheManager"

const _handleLogin = (data) => {
	SessionManager.user.change(data)
	if (data.noCloseSession) CacheManager.user.change(data)
	const { id, api_key, userName, email, tokenAccess, tokenRefresh, name } = data
	const { userType, noCloseSession, role_group_id } = data
	return {
		id,
		api_key,
		userName,
		email,
		tokenAccess,
		tokenRefresh,
		name,
		userType,
		noCloseSession,
		role_group_id,
	}
}

const loginAction = (_, action) => {
	return _handleLogin(action.payload)
}

const logoutAction = () => {
	SessionManager.user.remove()
	CacheManager.user.remove()

	return {
		id: null,
		api_key: null,
		userName: null,
		email: null,
		tokenAccess: null,
		tokenRefresh: null,
		name: null,
		userType: null,
		noCloseSession: null,
		role_group_id: null,
	}
}

const checkingLoginAction = () => {
	const userSession = SessionManager.user.get()
	const userCache = CacheManager.user.get()

	const checkTokenHasExpired = (token) => {
		try {
			const { exp } = jwtDecode(token)
			const today = Math.floor(Date.now() / 1000)
			return today >= exp
		} catch {
			return true
		}
	}

	if (userSession) {
		if (checkTokenHasExpired(userSession.tokenAccess)) {
			SessionManager.user.remove()
		}
	} else if (userCache) {
		if (checkTokenHasExpired(userCache.tokenAccess)) {
			CacheManager.user.remove()
			// if (user.noCloseSession) must use refresh token when api endpoint is available
		} else {
			return _handleLogin(userCache)
		}
	}
	return SessionManager.user.get() || CacheManager.user.get() || {}
}

const initialState = SessionManager.user.get() || CacheManager.user.get() || {}

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: { login: loginAction, logout: logoutAction, checkingLogin: checkingLoginAction },
})

export const { login, logout, checkingLogin } = authSlice.actions
export default authSlice.reducer
