import { useTranslation } from "react-i18next"

import validator from "validator"

import { useForm } from "@arbolfinanciero/kikoya-lib-hub/hooks/useForm"

const regExpWords = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/
const regExpPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#.$($)$-$_])[A-Za-z\d$@$!%*?&#.$($)$-$_]{8,16}$/

export const useUserForm = ({ isAddMode }) => {
	const { t: t_general } = useTranslation()
	const customRegexError = t_general("general.error.only_text")
	const inputs = {
		name: { initialValue: "", required: true, min: 3, max: 25, regex: regExpWords, customRegexError },
		first_name: { initialValue: "", required: true, min: 3, max: 25, regex: regExpWords, customRegexError },
		last_name: { initialValue: "", required: true, min: 3, max: 25, regex: regExpWords, customRegexError },
		email: {
			initialValue: "",
			required: true,
			max: 50,
			onValidateFormat: (email) => {
				if (!validator.isEmail(email)) return t_general("financierUser.create.validate_email")
			},
		},
		phone_number: { initialValue: "", required: true, onlyNumbers: true, min: 8, max: 10 },
		username: { initialValue: "", required: true, min: 3, max: 25, regex: regExpWords, customRegexError },
		role_group: { initialValue: 0 },
		check_activation: { initialValue: false },
		check_apply_offer: { initialValue: false },
		password: {
			initialValue: "",
			required: isAddMode,
			regex: regExpPassword,
			customRegexError: t_general("financierUser.create.validate.password"),
		},
		confirm_password: {
			initialValue: "",
			required: isAddMode,
			regex: regExpPassword,
			customRegexError: t_general("financierUser.create.validate.password"),
			onValidateFormat: (confirm_password) => {
				if (confirm_password !== form.values.password)
					return t_general("financierUser.create.validate.confirm_password")
			},
		},
	}
	const form = useForm({ inputs })
	return form
}
