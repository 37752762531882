import classes from "./dashboard_clients.module.scss"

import { Grid } from "@arbolfinanciero/kikoya-lib-hub/controls/Grid"

import { HeaderAuth } from "components/financiero/Home/HeaderAuth/HeaderAuth"

import { DashboardClientsProvider } from "./DashboardClientsContext"

import { DashboardClients__Summary } from "./DashboardClients__Summary"
import { DashboardClients__Chart } from "./DashboardClients__Chart"
import { DashboardClients__Table } from "./DashboardClients__Table"

export const DashboardClients = () => {
	return (
		<Grid alignItems="start" gridTemplateRows="auto 1fr" gridTemplateColumns="100%" h100>
			<HeaderAuth />
			<Grid className={classes.container} justifyItems="center" gridTemplateColumns="100%">
				<DashboardClientsProvider>
					<Grid className={classes.content} gap="1em" alignContent="start" gridTemplateColumns="100%" w100>
						<DashboardClients__Summary />
						<DashboardClients__Chart />
						<DashboardClients__Table />
					</Grid>
				</DashboardClientsProvider>
			</Grid>
		</Grid>
	)
}
