import classes from "./styles/products.module.scss"

import { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useContext } from "react"

import { Button, Loading } from "@arbolfinanciero/kikoya-lib-hub/controls"

import { ProductsContext } from "./ProductsContext"
import { ProductsListItem } from "./ProductsListItem"

import { ProductModel } from "models"
import { useShowHide } from "hooks"

export const ProductsList = () => {
	const { t } = useTranslation("admin", { keyPrefix: "products" })
	const { page, tokenAccess, lender_id } = useContext(ProductsContext)

	const loading = useShowHide()
	const showLoading = loading.show
	const hideLoading = loading.hide
	const [products, setProducts] = useState([])

	const fetchProducts = useCallback(async () => {
		showLoading()
		const response = await ProductModel.getByLender(tokenAccess, lender_id)
		setProducts(response)
		hideLoading()
	}, [tokenAccess, lender_id, showLoading, hideLoading])

	useEffect(() => {
		fetchProducts()
	}, [fetchProducts])

	const handleNewProduct = () => page.setNew()

	return (
		<div className={classes.products__list}>
			<div className={classes.products__list__header}>
				<h1 className={classes.products__list__title}>{t("show.title")}</h1>
				<Button
					variant="contained"
					color="primary"
					className={classes.products__list__new_button}
					onClick={handleNewProduct}>
					{t("show.button.new")}
				</Button>
			</div>
			<Loading loading={loading.isVisible}>
				<div className={classes.products__list__items}>
					{products.map((item, index) => (
						<ProductsListItem item={item} key={index} />
					))}
				</div>
			</Loading>
		</div>
	)
}
