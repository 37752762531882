import classes from "./dashboard_table.module.scss"

import { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { DividedTable } from "@arbolfinanciero/kikoya-lib-hub/controls/Table"
import { Grid } from "@arbolfinanciero/kikoya-lib-hub/controls/Grid"
import { Text } from "@arbolfinanciero/kikoya-lib-hub/controls/Text"
import { Skeleton } from "@arbolfinanciero/kikoya-lib-hub/controls/Skeleton"

export const DashboardTable = ({ className, title, data, columns, maxTableWidth, isLoading, footer }) => {
	const { t } = useTranslation("financier", { keyPrefix: "dashboard" })

	const preparedData = useMemo(() => {
		if (isLoading)
			return Array.from({ length: 6 }, () => ({
				[columns[0].name]: (
					<Skeleton show width={180} data-testid="financier--dashboard__statistics--skeleton" />
				),
				[columns[1].name]: (
					<Skeleton show width={30} data-testid="financier--dashboard__statistics--skeleton" />
				),
			}))

		return data
	}, [columns, isLoading, data])

	return (
		<Grid className={[classes.container, className].join(" ")} gap="2em">
			{title && <Text>{title}</Text>}
			{preparedData.length === 0 ? (
				<Text className={classes.no_data}>{t("table.no_data")}</Text>
			) : (
				<DividedTable
					className={classes.table_divided}
					data={preparedData}
					columns={columns}
					hideHeader
					maxTableWidth={maxTableWidth}
					numberOfColumns={3}
					variant="no_borders"
					footer={footer}
				/>
			)}
		</Grid>
	)
}
