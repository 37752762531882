import { createContext, useContext } from "react"
import { useTranslation } from "react-i18next"

import { useRange } from "../hooks/useRange"
import { useClientStatisticsData } from "../hooks/useClientStatisticsData"
import { useSummaryData } from "../hooks/useSummaryData"

const DashboardClientsContext = createContext()

export const useDashboardClientsContext = () => useContext(DashboardClientsContext)

export const DashboardClientsProvider = ({ children }) => {
	const { t } = useTranslation("financier", { keyPrefix: "dashboard" })
	const range = useRange({ t })
	const summary = useSummaryData({ t })
	const statistics = useClientStatisticsData({ t, range })

	const state = { t, summary, statistics, range }
	return <DashboardClientsContext.Provider value={state}>{children}</DashboardClientsContext.Provider>
}
