import { configureStore } from "@reduxjs/toolkit"

import authReducer from "./auth"
import brandingReducer from "./branding"

export const store = configureStore({
	reducer: {
		auth: authReducer,
		branding: brandingReducer,
	},
})
