import { useSelector } from "react-redux"

import { format } from "date-fns"

import { useRequestController } from "@arbolfinanciero/kikoya-lib-hub/hooks/useRequestController"

import { createCSV, downloadCSV } from "@arbolfinanciero/kikoya-lib-hub/helpers/files"

import { useDashboardClientsContext } from "./DashboardClientsContext"
import { DashboardChartMenu } from "../_DashboardChartMenu"

import { MerchantModel } from "models/MerchantModel"
import { useAlertMessages } from "AlertMessageContext"

export const DashboardClients__ChartMenu = () => {
	const { tokenAccess } = useSelector((state) => state.auth)
	const { name_financier } = useSelector((state) => state.branding)
	const { t, range } = useDashboardClientsContext()
	const messages = useAlertMessages()

	const downloadController = useRequestController()

	const startDownload = (data) => {
		const currentDate = format(new Date(), "d-MM-yyyy")
		const filename = t("export.filename_clients", { currentDate, merchant: name_financier.replace(" ", "_") })
		const columns = {
			pk: t("export.columns.folio_hub"),
			user_created_at: t("export.columns.created_at"),
			branch: t("export.columns.branch"),
			applicant: t("export.columns.name"),
			rfc: t("export.columns.rfc"),
			email: t("export.columns.email"),
			mobile_phone: t("export.columns.mobile_phone"),
			gender: t("export.columns.gender"),
			country: t("export.columns.country"),
			state: t("export.columns.state"),
			city_council: t("export.columns.city"),
			suburb: t("export.columns.suburb"),
			zip_code: t("export.columns.zip_code"),
			birthday: t("export.columns.birthdate"),
			net_income: t("export.columns.net_income"),
		}
		const csv = createCSV({ data, columns })
		downloadCSV({ csv, name: filename })
	}

	const fetchExportData = async () => {
		try {
			const body = { date_filter: range.value }
			const { isValid, startDate, endDate } = range.validateCustomDateRange()
			if (!isValid) return null
			downloadController.showLoading()

			if (startDate) body.start_date = startDate
			if (endDate) body.end_date = endDate
			const response = await MerchantModel.Folio.getClientReport(tokenAccess, body, downloadController.signal)
			startDownload(response)
		} catch (error) {
			if (Object.keys(error).length > 0)
				messages.errors.change([t("error.message")], {
					title: t("error.exporting_title"),
					sx: { maxWidth: "20em" },
				})
		}
		downloadController.hideLoading()
	}

	return <DashboardChartMenu downloadController={downloadController} fetchExportData={fetchExportData} />
}
