import { generalTranslations, modelTranslations } from "models/helpers/translations"
import { SINGLETON } from "@arbolfinanciero/kikoya-lib-hub/KikoyaSingleton"

export const handleAbortError = (error) => {
	if (error?.code === "ERR_CANCELED") throw {}
}

export const handleMaintenanceError = ({ response }) => {
	if (response) {
		if (response.status === 503) SINGLETON.MAINTENANCE_ACTIVATED = true
	}
}

export const handleCommonErrors = async ({ response, request, message }) => {
	const t_general = await generalTranslations()
	let error
	if (response) {
		handleMaintenanceError({ response })
		if (response.status >= 500) error = { non_field_errors: [t_general("error.internal")] }
		if (response.status === 404) error = { non_field_errors: [t_general("error.not_found")] }
		if (response.status === 403) error = { non_field_errors: [t_general("error.unauthorized")] }
		if (response.status === 401) error = { non_field_errors: [t_general("error.expired")] }
		if (error) throw error
		return response
	} else if (request) {
		error = { non_field_errors: [t_general("error.server")] }
	} else {
		error = { non_field_errors: [message] }
	}
	throw error
}

export const createCustomErrorsHandler = async ({ response, message }, model) => {
	const t = await modelTranslations(model)
	const t_general = await generalTranslations()
	return { message, response, t, t_general }
}

export const handleGenericErrors = async ({ response }) => {
	const t_general = await generalTranslations()
	let error
	if (response.status === 400) error = { non_field_errors: [t_general("error.problem")] }
	if (error) throw error
	return response
}

export const handleCustomErrors = async (response, model) => {
	const t = await modelTranslations(model)
	if (response.status === 400) {
		Object.keys(response.data).forEach((field) => {
			const fieldErrors = response.data[field]
			const translatedErrors = fieldErrors.map((message) => t(`error.${field}.${message}`))
			response.data[field] = translatedErrors
		})
		throw response.data
	}
}
