import { createRoot } from "react-dom/client"
import { ClearBrowserCacheBoundary } from "react-clear-browser-cache"
import App from "./App"

const root = createRoot(document.getElementById("root"))

root.render(
	<ClearBrowserCacheBoundary auto duration={60000}>
		<App />
	</ClearBrowserCacheBoundary>
)
