import i18n from "i18n"

export const modelTranslations = async (model) => {
	await i18n.loadNamespaces(`models/${model}`)
	const t = (translation) => i18n.t(`${model}.${translation}`, { ns: "models" })
	return t
}

export const generalTranslations = async () => {
	await i18n.loadNamespaces("models/general")
	const t_general = (translation) => i18n.t(`general.${translation}`, { ns: "models"})
	return t_general
}
