import { createContext, useContext } from "react"
import { useTranslation } from "react-i18next"

import { useRange } from "../hooks/useRange"
import { useChartType } from "../hooks/useChartType"
import { useStatisticsData } from "../hooks/useStatisticsData"
import { useSummaryData } from "../hooks/useSummaryData"

const DashboardOffersContext = createContext()

export const useDashboardOffersContext = () => useContext(DashboardOffersContext)

export const DashboardOffersProvider = ({ children }) => {
	const { t } = useTranslation("financier", { keyPrefix: "dashboard" })
	const range = useRange({ t })
	const chartType = useChartType({ t })
	const summary = useSummaryData({ t })
	const statistics = useStatisticsData({ t, range })

	const state = { t, summary, statistics, range, chartType }
	return <DashboardOffersContext.Provider value={state}>{children}</DashboardOffersContext.Provider>
}
