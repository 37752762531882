import { TabButtons } from "@arbolfinanciero/kikoya-lib-hub/controls/TabButtons"

import { useDashboardOffersContext } from "./DashboardOffersContext"
import { DashboardOffers__ChartBar } from "./DashboardOffers__ChartBar"
import { DashboardOffers__ChartDonut } from "./DashboardOffers__ChartDonut"

import { DashboardChart } from "../_DashboardChart"

export const DashboardOffers__Chart = () => {
	const { range, chartType } = useDashboardOffersContext()

	const extraControls = (
		<TabButtons
			options={chartType.options}
			value={chartType.value}
			onChange={chartType.change}
			variant="vertical_lines"
			disabled
		/>
	)

	return (
		<DashboardChart range={range} extraControls={extraControls}>
			{chartType.is.noInvoices && <DashboardOffers__ChartBar />}
			{chartType.is.percentage && <DashboardOffers__ChartDonut />}
		</DashboardChart>
	)
}
