import { useSelector } from "react-redux"

import { ConfirmMessage } from "@arbolfinanciero/kikoya-lib-hub/controls/ConfirmMessage"
import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks/useShowHide"

import { useAlertMessages } from "AlertMessageContext"
import { MerchantModel } from "models/MerchantModel"

import { useAssociatedGroupsContext } from "./AssociatedGroupsContext"

export const DeleteAssociatedGroupModal = ({ modal, groupId }) => {
	const { tokenAccess } = useSelector((state) => state.auth)
	const { wid, reloadData, t } = useAssociatedGroupsContext()
	const messages = useAlertMessages()

	const loading = useShowHide()

	const handleDelete = async () => {
		loading.show()
		try {
			await MerchantModel.GroupAssociation.delete(tokenAccess, wid, groupId)
			messages.successful.change([t("modal_delete_associated_group.message.success")])
			modal.close()
			reloadData()
		} catch (errors) {
			if (errors.non_field_errors) messages.errors.change(errors.non_field_errors)
		}
		loading.hide()
	}

	return (
		<ConfirmMessage
			modal={modal}
			title={t("modal_delete_associated_group.title")}
			onConfirm={handleDelete}
			confirmButtonText={t("modal_delete_associated_group.button.confirm")}
			isLoading={loading.isVisible}>
			{t("modal_delete_associated_group.description")}
		</ConfirmMessage>
	)
}
