import "./error_view.scss"

import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls"
import { AppBar } from "@mui/material"

export const ErrorView = ({ removeError }) => {
	const { logotipo } = useSelector((state) => state.branding)
	const navigate = useNavigate()

	const goBack = () => {
		removeError()
		navigate(-1)
	}

	return (
		<div className="error_view">
			<AppBar position="sticky" className="error_view__header" color="default">
				<img className="error_view__header__logo" src={logotipo} alt="logo" />
			</AppBar>

			<div className="error_view__content">
				<h1 className="error_view__title">Ups... ha ocurrido un error</h1>
				<Button onClick={goBack} variant="contained" color="primary">
                    Regresar
				</Button>
			</div>
		</div>
	)
}
