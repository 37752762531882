import React from "react"
import { makeStyles } from "@mui/styles"
import {
	Card, CardActions, CardContent, Typography,
	IconButton
} from "@mui/material"
import BookIcon from "@mui/icons-material/Book"
import { Link } from "react-router-dom"
import "./style.scss"

const useStyles = makeStyles({
	card: {
		maxWidth: 500,
		minWidth: 500,
		maxHeight: 250,
		minHeight: 250
	},
	bullet: {
		display: "inline-block",
		margin: "0 2px",
		transform: "scale(0.8)"
	},
	title: {
		fontSize: 14,
		color: "#FFFFFF"
	},
	pos: {
		marginBottom: 12
	}
})

const CardNoticePrivacy = props => {
	const classes = useStyles()

	return (
		<Card className={`card ${classes.card} ${props.risky ? "risky" : ""}`}>
			<CardContent
				style={{
					backgroundColor: "#000000",
				}}>
				<Typography
					className={classes.title}
					color="textSecondary"
					gutterBottom>
            AVISO DE PRIVACIDAD
				</Typography>
			</CardContent>
			<CardActions
				style={{
					justifyContent: "center"
				}}>
				<div
					style={{
						display: "table-cell",
					}}>
					<div
						style={{
							placeContent: "center"
						}}>
						<IconButton
							aria-label="Configurar uso de cookies"
							component={Link}
							to="/financier/Privacy">
							<BookIcon fontSize="large" />
						</IconButton>
					</div>
					<div
						style={{
							placeContent: "center"
						}}>
              Aviso de Privacidad
					</div>
				</div>
			</CardActions>
		</Card>
	)
}

export default CardNoticePrivacy

