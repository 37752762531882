import classes from "./groups.module.scss"

import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls/Button"
import { Modal, ModalContainer, ModalCloseButton } from "@arbolfinanciero/kikoya-lib-hub/controls/Modal"
import { Text } from "@arbolfinanciero/kikoya-lib-hub/controls/Text"
import { TextField } from "@arbolfinanciero/kikoya-lib-hub/controls/TextField"
import { SelectList } from "@arbolfinanciero/kikoya-lib-hub/controls/SelectList"
import { Loading } from "@arbolfinanciero/kikoya-lib-hub/controls/Loading"

import { useGroupsContext } from "./GroupsContext"

const GroupsFormField = ({ form, name, ...props }) => {
	const handleChange = (e) => {
		const { value, name } = e.target
		form.change(name, name === "rfc" ? value.toUpperCase() : value)
	}
	const handleBlur = (e) => form.validateFormat(e.target.name)
	return (
		<TextField
			name={name}
			value={form.values[name]}
			onChange={handleChange}
			onBlur={handleBlur}
			error={form.errors[name] ? true : false}
			errorText={form.errors[name]}
			className={classes.form_modal__textfield}
			disabled={form.readOnly}
			{...props}
		/>
	)
}

export const GroupsForm = ({ modal }) => {
	const { t, loadGroups, loadingForm, form } = useGroupsContext()

	const handleClose = () => {
		form.reset()
		modal.close()
	}

	const handleSave = async (e) => {
		e.preventDefault()
		const submitedSuccessfully = await form.submit()
		if (!submitedSuccessfully) return null

		loadGroups()
		modal.close()
		form.reset()
	}

	const createFieldProps = (name, translationName) => ({
		name,
		label: t(`input.${translationName || name}`),
		"aria-label": t(`arialabel.input.${translationName || name}`),
	})

	const fields = [
		createFieldProps("identifier"),
		createFieldProps("name"),
		createFieldProps("businessName", "business_name"),
		createFieldProps("rfc"),
	]

	return (
		<Modal open={modal.isOpen}>
			<ModalContainer tag="form" onSubmit={handleSave} className={classes.form_modal}>
				<Loading loading={loadingForm.isVisible}>
					<ModalCloseButton onClick={handleClose} />
					<Text className={classes.form_modal__title}>{t("title.new")}</Text>
					<div className={classes.form_modal__inputs}>
						{fields.map((fieldProps, index) => (
							<GroupsFormField {...fieldProps} form={form} key={index} />
						))}
						<SelectList
							name="states"
							aria-label={t("arialabel.input.states")}
							className={classes.form_modal__select_list}
							selectClassName={classes.form_modal__select_list__select}
							label={t("input.states")}
							value={form.values.states}
							onChange={(value) => form.change("states", value)}
							options={form.selectOptions?.states}
							disabled={form.readOnly}
						/>
						<SelectList
							name="inventory"
							aria-label={t("arialabel.input.inventory")}
							className={classes.form_modal__select_list}
							selectClassName={classes.form_modal__select_list__select}
							label={t("input.inventory")}
							value={form.values.inventory}
							onChange={(value) => form.change("inventory", value)}
							options={form.selectOptions.inventory}
							disabled={form.readOnly}
						/>
					</div>
					<div className={classes.form_modal__buttons}>
						{!form.readOnly && (
							<>
								<Button variant="outlined" color="primary" onClick={handleClose}>
									{t("button.cancel")}
								</Button>
								<Button variant="contained" color="primary" type="submit">
									{form.isNew ? t("button.save") : t("button.update")}
								</Button>
							</>
						)}
					</div>
				</Loading>
			</ModalContainer>
		</Modal>
	)
}
