import { useTranslation } from "react-i18next"
import { TextField } from "@arbolfinanciero/kikoya-lib-hub/controls/TextField"

export const TabErrorsTextField = ({ name, filter }) => {
	const { t } = useTranslation("admin", { keyPrefix: "tab_errors" })

	const handleChange = (e) => filter.change(name, e.target.value)
	const handleBlur = () => filter.validateFormat(name)

	return (
		<TextField
			name={name}
			value={filter.values[name]}
			onChange={handleChange}
			onBlur={handleBlur}
			label={t(`column.${name}`)}
			className="admin__home__errors__table__filter__input"
		/>
	)
}
