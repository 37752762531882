import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { Modal, ModalCloseButton, ModalContainer } from "@arbolfinanciero/kikoya-lib-hub/controls/Modal"
import { SelectList } from "@arbolfinanciero/kikoya-lib-hub/controls/SelectList"
import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls/Button"
import { Loading } from "@arbolfinanciero/kikoya-lib-hub/controls/Loading"
import { useForm } from "@arbolfinanciero/kikoya-lib-hub/hooks/useForm"

import { useShowHide } from "hooks"
import { WhitelabelModel } from "models"
import { useAlertMessages } from "AlertMessageContext"

export const ReplaceLenders = ({ wid, lenders, whitelabelLenders, modal }) => {
	const { t } = useTranslation("admin", { keyPrefix: "whitelabel.lender" })
	const { tokenAccess } = useSelector((store) => store.auth)
	const messages = useAlertMessages()

	const form = useForm({
		inputs: {
			lenders: { initialValue: [] },
		},
	})
	const setForm = form.set

	const loading = useShowHide()

	useEffect(() => {
		setForm({ lenders: whitelabelLenders.map((l) => parseInt(l.lender_id)) })
	}, [whitelabelLenders, setForm])

	const onChange = (value) => form.change("lenders", value)

	const onSubmit = async (e) => {
		e.preventDefault()
		loading.show()
		try {
			await WhitelabelModel.Lender.replace(tokenAccess, wid, form.values.lenders)
			modal.close()
		} catch (errors) {
			loading.hide()
			messages.errors.change(errors.non_field_errors || [])
		}
	}

	return (
		<Modal open={modal.isOpen} onClose={modal.close} className="lenders__replace__modal">
			<ModalContainer tag="form" onSubmit={onSubmit}>
				<ModalCloseButton onClick={modal.close} />
				<h2 className="lenders__replace__title">{t("replace.title")}</h2>
				<Loading loading={loading.isVisible}>
					<SelectList
						name="lenders"
						label={t("replace.input.lenders")}
						className="lenders__edit__select"
						value={form.values.lenders}
						onChange={onChange}
						options={lenders.map(({ id, name }) => ({ value: id, text: name }))}
						error={form.errors.lenders ? true : false}
						errorText={form.errors.lenders}
					/>
					<Button variant="contained" color="primary" type="submit">
						{t("button.save")}
					</Button>
				</Loading>
			</ModalContainer>
		</Modal>
	)
}
