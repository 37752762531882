import classes from "./associated_groups.module.scss"

import { useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { Modal, ModalContainer, ModalCloseButton } from "@arbolfinanciero/kikoya-lib-hub/controls/Modal"
import { Text } from "@arbolfinanciero/kikoya-lib-hub/controls/Text"
import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls/Button"
import { ConfirmMessage } from "@arbolfinanciero/kikoya-lib-hub/controls/ConfirmMessage"
import { Loading } from "@arbolfinanciero/kikoya-lib-hub/controls/Loading"

import { useModal } from "@arbolfinanciero/kikoya-lib-hub/hooks/useModal"
import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks/useShowHide"

import { useAlertMessages } from "AlertMessageContext"
import { BranchModel } from "models/BranchModel"

import { useAssociatedGroupsContext } from "./AssociatedGroupsContext"
import { DeleteAssociatedBranchesModalTable } from "./DeleteAssociatedBranchesModalTable"

const useDeletedBranches = () => {
	const [deletedBranches, setDeletedBranches] = useState([])
	const deleteBranch = (branch_id) => setDeletedBranches([...deletedBranches, branch_id])
	return { values: deletedBranches, isEmpty: deletedBranches.length === 0, delete: deleteBranch }
}

const DeleteAssociatedBranchesModalContent = ({ closeModal, groupId }) => {
	const { tokenAccess } = useSelector((state) => state.auth)
	const { t, reloadData, wid } = useAssociatedGroupsContext()
	const messages = useAlertMessages()

	const { t: t_general } = useTranslation("models", { keyPrefix: "general" })
	const deletedBranches = useDeletedBranches()
	const confirmExitModal = useModal()
	const loading = useShowHide()

	const handleClose = () => {
		if (deletedBranches.isEmpty) closeModal()
		else confirmExitModal.open()
	}

	const handleSubmit = async () => {
		if (deletedBranches.isEmpty) return null

		loading.show()
		try {
			await BranchModel.Associations.change(tokenAccess, wid, {
				associate: false,
				branches_id: deletedBranches.values,
			})
			messages.successful.change([t_general("success.message")])
			reloadData()
			closeModal()
		} catch (error) {
			if (error.non_field_errors) messages.errors.change(error.non_field_errors)
		}
		loading.hide()
	}

	return (
		<ModalContainer className={classes.modal_delete_associated_branches}>
			<ModalCloseButton
				onClick={handleClose}
				aria-label={t("modal_delete_associated_branches.button.close_arialabel")}
			/>
			<Text tag="h2">{t("modal_delete_associated_branches.title")}</Text>
			<Loading loading={loading.isVisible}>
				<DeleteAssociatedBranchesModalTable deletedBranches={deletedBranches} groupId={groupId} />
				<div className={classes.modal_delete_associated_branches__buttons}>
					<Button color="primary" variant="outlined" onClick={handleClose}>
						{t("modal_delete_associated_branches.button.cancel")}
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={handleSubmit}
						disabled={deletedBranches.isEmpty}>
						{t("modal_delete_associated_branches.button.update")}
					</Button>
				</div>
			</Loading>
			<ConfirmMessage
				modal={confirmExitModal}
				cancelButtonText={t("modal_delete_associated_branches.confirm_modal.button.cancel")}
				confirmButtonText={t("modal_delete_associated_branches.confirm_modal.button.confirm")}
				onConfirm={closeModal}>
				{t("modal_delete_associated_branches.confirm_modal.text")}
			</ConfirmMessage>
		</ModalContainer>
	)
}

export const DeleteAssociatedBranchesModal = ({ modal, groupId }) => (
	<Modal open={modal.isOpen}>
		<DeleteAssociatedBranchesModalContent closeModal={modal.close} groupId={groupId} />
	</Modal>
)
