import classes from "./branches.module.scss"

import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { Loading, IconButton, Breadcrumb } from "@arbolfinanciero/kikoya-lib-hub/controls"
import { ArrowBack, ArrowForwardIos as ArrowForward } from "@mui/icons-material"

import { HeaderAuth } from "../HeaderAuth/HeaderAuth"
import { BranchesDataGrid } from "./BranchesDataGrid"
import { BranchesNewButton } from "./BranchesNewButton"
import { BranchesContext } from "./BranchesContext"

import { useForm, useBranches, useRepresentationTypeData, useInventoryOptions } from "./hooks"

export const Branches = () => {
	const navigate = useNavigate()
	const { t } = useTranslation("admin", { keyPrefix: "branches" })

	const representations = useRepresentationTypeData()
	const inventoryOptions = useInventoryOptions()
	const branches = useBranches()
	const form = useForm()

	const state = { t, branches, representations, form, inventoryOptions }
	return (
		<div className={classes.container}>
			<HeaderAuth />
			<BranchesContext.Provider value={state}>
				<div className={classes.content}>
					<div className={classes.head}>
						<IconButton size="medium" onClick={() => navigate("/admin/Layout/2")}>
							<ArrowBack fontSize="inherit" />
						</IconButton>
						<Breadcrumb
							className={classes.breadcrumb}
							items={[
								t("breadcrumb.merchant"),
								t("breadcrumb.groups"),
								t("breadcrumb.branches"),
								branches.groupName,
							]}
							Separator={() => <ArrowForward sx={{ fontSize: "0.9em" }} />}
						/>
						<BranchesNewButton />
					</div>
					<Loading loading={branches.isLoading}>
						<BranchesDataGrid data={branches.data} />
					</Loading>
				</div>
			</BranchesContext.Provider>
		</div>
	)
}
