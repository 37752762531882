import React, { useState, useEffect, useCallback } from "react"
import { makeStyles } from "@mui/styles"
import { Tabs, Tab, Box } from "@mui/material"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import FileCopyIcon from "@mui/icons-material/FileCopy"
import DescriptionIcon from "@mui/icons-material/Description"
import { CustomDataGrid, Loading } from "@arbolfinanciero/kikoya-lib-hub/controls"
import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks"
import "./style.scss"
import { useTranslation } from "react-i18next"

import { UserModel } from "models/UserModel"

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	)
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	}
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
}))

export const TabFinancierUser = ({ setVisible_Grid }) => {
	const { tokenAccess } = useSelector((state) => state.auth)
	const [userEnabled, setUserEnabled] = useState([])
	const [userDisabled, setUserDisabled] = useState([])
	const { show: showLoading, hide: hideLoading, isVisible: loading } = useShowHide()

	const classes = useStyles()
	const [value, setValue] = React.useState(0)

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	const getIndexUsers = useCallback(async () => {
		showLoading()
		try {
			const users = await UserModel.get(tokenAccess)

			const arrayGridEnabled = users
				.filter((u) => u.is_active === true)
				.map((u) => ({
					id: u.pk,
					name: u.name,
					firstName: u.paternal_surname,
					secondName: u.maternal_surname,
					email: u.email,
					phone: u.mobile_phone,
					role: u.role_group_users.description,
				}))

			const arrayGridDisabled = users
				.filter((u) => u.is_active === false)
				.map((u) => ({
					id: u.pk,
					name: u.name,
					firstName: u.paternal_surname,
					secondName: u.maternal_surname,
					email: u.email,
					phone: u.mobile_phone,
					role: u.role_group_users.description,
				}))

			setUserEnabled(arrayGridEnabled)
			setUserDisabled(arrayGridDisabled)
		} catch (error) {
			console.error(error)
		}
		setVisible_Grid("grid")
		hideLoading()
	}, [tokenAccess, setVisible_Grid, showLoading, hideLoading])

	useEffect(() => {
		try {
			getIndexUsers()
		} catch (error) {
			console.log(error)
		}
	}, [getIndexUsers])

	const { t } = useTranslation()

	const columns = [
		{
			name: "id",
			text: t("financierUser.index.col_id"),
		},
		{
			name: "name",
			text: t("financierUser.index.col_name"),
		},
		{
			name: "firstName",
			text: t("financierUser.index.col_first_name"),
		},
		{
			name: "secondName",
			text: t("financierUser.index.col_second_name"),
		},
		{
			name: "email",
			text: t("financierUser.index.col_email"),
		},
		{
			name: "phone",
			text: t("financierUser.index.col_mobile_phone"),
		},
		{
			name: "role",
			text: t("financierUser.index.col_role"),
		},
		{
			name: "actions",
			text: t("financierUser.index.col_actions"),
			render: ({ id }) => {
				const onClick = (e) => {
					e.stopPropagation()
					window.location.href = window.location.origin + "/financier/Financiero/edit/" + id
				}

				return (
					<IconButton
						onClick={onClick}
						color="primary"
						aria-label={t("financierUser.index.col_actions_edit_button_place")}
						size="small">
						<EditIcon fontSize="small" />
					</IconButton>
				)
			},
		},
	]

	return (
		<div className={classes.root + " financier__whitelabel__form__table"}>
			<Tabs
				value={value}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="inherit"
				aria-label="Usuarios Financieros">
				<Tab
					label={t("financierUser.index.tab_enabled")}
					icon={<FileCopyIcon color="primary" />}
					{...a11yProps(0)}
				/>
				<Tab
					label={t("financierUser.index.tab_disabled")}
					icon={<DescriptionIcon color="primary" />}
					{...a11yProps(1)}
				/>
			</Tabs>
			<Loading loading={loading}>
				<TabPanel value={value} index={0} className="financier__whitelabel__form__panel">
					<CustomDataGrid data={userEnabled} columns={columns} rowsByPage={20} height="100%" />
				</TabPanel>
				<TabPanel value={value} index={1} className="financier__whitelabel__form__panel">
					<CustomDataGrid data={userDisabled} columns={columns} rowsByPage={20} height="100%" />
				</TabPanel>
			</Loading>
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
}
