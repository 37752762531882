import classes from "./financier.module.scss"

import { useSelector } from "react-redux"

import { InvoiceTable } from "@arbolfinanciero/kikoya-lib-hub/components"

import { HeaderAuth } from "../Home/HeaderAuth/HeaderAuth"
import { CustomActionsField, CustomInvoiceField } from "./CustomFields"

import { getBaseURL } from "helpers"

export const Layout = () => {
	const { tokenAccess } = useSelector((state) => state.auth)

	return (
		<div className={classes.container}>
			<HeaderAuth />
			<InvoiceTable
				tokenAccess={tokenAccess}
				endpoint={getBaseURL()}
				CustomInvoiceField={CustomInvoiceField}
				CustomActionsField={CustomActionsField}
			/>
		</div>
	)
}
