import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls"
import { useModal } from "@arbolfinanciero/kikoya-lib-hub/hooks"

import { BranchesForm } from "./BranchesForm"
import { useBranchesContext } from "./BranchesContext"

export const BranchesNewButton = () => {
	const { t } = useBranchesContext()
	const modal = useModal()
	return (
		<>
			<Button variant="contained" color="primary" type="button" onClick={modal.open} sx={{ marginLeft: "auto" }}>
				{t("button.new")}
			</Button>
			<BranchesForm modal={modal} />
		</>
	)
}
