import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks/useShowHide"

import { MerchantModel } from "models/MerchantModel"
import { useAlertMessages } from "AlertMessageContext"

export const useSummaryData = ({ t }) => {
	const { tokenAccess } = useSelector((state) => state.auth)
	const messages = useAlertMessages()
	const changeErrors = messages.errors.change

	const [summaryData, setSummaryData] = useState({})
	const { show: showLoading, hide: hideLoading, isVisible: isLoading } = useShowHide()

	useEffect(() => {
		const fetchData = async () => {
			showLoading()
			try {
				const response = await MerchantModel.Folio.getSummary(tokenAccess)
				setSummaryData(response)
			} catch (error) {
				changeErrors([t("error.message")], { title: t("error.title"), sx: { maxWidth: "20em" } })
			}
			hideLoading()
		}
		fetchData()
	}, [t, tokenAccess, showLoading, hideLoading, changeErrors])

	return { data: summaryData, isLoading }
}
