import axios from "axios"
import { getBaseURL } from "helpers"
import { handleCommonErrors, handleGenericErrors } from "models/helpers"

const __endpoint = getBaseURL() + "/api/v1/branch"

const Associations = {
	get: async (token, wid, data = {}) => {
		const { associated = true } = data
		try {
			const headers = { Authorization: "Bearer " + token }
			const body = { headers }
			const url = `${__endpoint}/associated_branches/?whitelabel_id=${wid}&associated=${associated}`
			const response = await axios.get(url, body)
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
			await handleGenericErrors(error)
		}
	},
	change: async (token, wid, data = {}) => {
		const { associate = true, branches_id = [] } = data
		try {
			const headers = { Authorization: "Bearer " + token }
			const body = { associate, branch_id: branches_id, merchant_id: wid }
			const url = `${__endpoint}/associate_branches/`
			const response = await axios.post(url, body, { headers })
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
			await handleGenericErrors(error)
		}
	},
}

export const BranchModel = { __endpoint, Associations }
