import { useEffect } from "react"

export const useResponsive = ($container, updateOn) => {
	const addResponsive = () => {
		if ($container.current) {
			const wrapper = $container.current.getElementsByClassName("recharts-wrapper")[0]
			const surface = $container.current.getElementsByClassName("recharts-surface")[0]
			if (wrapper) {
				setTimeout(() => {
					wrapper.classList.add("responsive")
					surface.classList.add("responsive")
				}, 10)
			}
		}
	}
	useEffect(addResponsive, [updateOn, $container])
}
