import classes from "./branches.module.scss"

import { Loading } from "@arbolfinanciero/kikoya-lib-hub/controls/Loading"
import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls/Button"
import { Modal, ModalContainer, ModalCloseButton } from "@arbolfinanciero/kikoya-lib-hub/controls/Modal"
import { Text } from "@arbolfinanciero/kikoya-lib-hub/controls/Text"
import { TextField } from "@arbolfinanciero/kikoya-lib-hub/controls/TextField"
import { Select } from "@arbolfinanciero/kikoya-lib-hub/controls/Select"
import { SelectList } from "@arbolfinanciero/kikoya-lib-hub/controls/SelectList"

import { Card } from "@mui/material"

import { useBranchesContext } from "./BranchesContext"

const BranchesInput = ({ field }) => {
	const { t, form, representations, inventoryOptions } = useBranchesContext()
	const value = form.values[field]
	const representationTypeOptions = representations.data.map((r) => ({ text: r.name, value: r.pk }))

	const handleBlur = () => form.validateFormat(field)
	const handleChangeSelect = (name) => (value) => form.change(name, value)
	const handleChange = (e) => {
		const name = e.target.name
		let value = e.target.value || ""
		if (name === "rfc") value = value.toUpperCase()
		form.change(name, value)
	}

	if (field === "inventory")
		return (
			<SelectList
				name={field}
				value={value}
				onChange={handleChangeSelect(field)}
				label={t(`form.${field}.label`)}
				options={inventoryOptions}
				disabled={form.readOnly}
			/>
		)

	if (field === "representation")
		return (
			<Select
				name={field}
				value={value}
				onChange={handleChangeSelect(field)}
				label={t(`form.${field}.label`)}
				blankValue={t(`form.${field}.placeholder`)}
				options={representationTypeOptions}
				disabled={form.readOnly}
			/>
		)

	return (
		<TextField
			name={field}
			value={value}
			onChange={handleChange}
			onBlur={handleBlur}
			label={t(`form.${field}.label`)}
			error={form.errors[field] ? true : false}
			errorText={form.errors[field]}
			disabled={form.readOnly || field === "state" || field === "city"}
		/>
	)
}

export const BranchesForm = ({ modal }) => {
	const { t, form, branches } = useBranchesContext()

	const handleClose = () => {
		form.reset()
		modal.close()
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (!form.validate()) return null
		if (!(await form.submit())) return null

		form.reset()
		modal.close()
		branches.loadData()
	}

	const branchFields = [
		{
			group: "general",
			fields: ["clave", "distributor", "representation", "business", "rfc", "inventory"],
		},
		{
			group: "domicile",
			fields: ["street", "internal", "external", "cp", "state", "city"],
		},
		{
			group: "extra",
			fields: ["owner", "lada", "phone", "manager", "admin", "sales", "postsale"],
		},
	]

	return (
		<Modal open={modal.isOpen}>
			<ModalContainer className={classes.modal}>
				<ModalCloseButton onClick={handleClose} />
				<Text tag="h1" className={classes.modal__title}>
					{t(`modal.title.${form.readOnly ? "see" : form.isNew ? "new" : "edit"}`)}
				</Text>
				<Loading loading={form.isLoading}>
					<form className={classes.modal__form}>
						{branchFields.map((item) => {
							return (
								<Card className={classes.modal__card} key={item.group}>
									<Text tag="h2" className={classes.modal__card__title}>
										{t(`modal.panel.${item.group}`)}
									</Text>
									<Loading loading={form.isLoadingSuburb && item.group === "domicile"}>
										<div className={classes.modal__card__content}>
											{item.fields.map((field) => (
												<BranchesInput field={field} key={field} />
											))}
										</div>
									</Loading>
								</Card>
							)
						})}
					</form>
				</Loading>
				{!form.readOnly && (
					<div className={classes.modal__buttons}>
						<Button variant="outlined" color="primary" onClick={handleClose}>
							{t("form.button.cancel")}
						</Button>
						<Button color="primary" variant="contained" type="submit" onClick={handleSubmit}>
							{form.isNew ? t("form.button.save") : t("form.button.update")}
						</Button>
					</div>
				)}
			</ModalContainer>
		</Modal>
	)
}
