import { Routes, Navigate, Route } from "react-router-dom"

import { Layout as UserLayout } from "components/user/Layout"
import { Question } from "components/user/Question/Question"
import { ChangePassword as ChangePassUser } from "components/user/Login/ChangePassword"

export const UserRouter = () => (
	<Routes>
		<Route path="/Layout" element={<UserLayout />} />
		<Route path="/Questions" element={<Question />} />
		<Route path="/ChangePassword" element={<ChangePassUser />} />
		<Route path="*" element={<Navigate to="/user/Layout" replace />} />
	</Routes>
)
