import classes from "./groups.module.scss"

import { GroupsProvider } from "./GroupsContext"
import { GroupsNewButton } from "./GroupsNewButton"
import { GroupsDataGrid } from "./GroupsDataGrid"

export const Groups = () => {
	return (
		<GroupsProvider>
			<div className={classes.container} data-testid="admin--groups">
				<GroupsNewButton />
				<GroupsDataGrid />
			</div>
		</GroupsProvider>
	)
}
