import { useEffect, useCallback } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import { UserModel } from "models/UserModel"

export const useFetchData = ({ isAddMode, form, loading }) => {
	const { id } = useParams()
	const { tokenAccess } = useSelector((state) => state.auth)
	const showLoading = loading.show
	const hideLoading = loading.hide
	const setForm = form.set

	const loadData = useCallback(async () => {
		if (!isAddMode) {
			showLoading()
			try {
				const res = await UserModel.find(tokenAccess, { id, user_type: 2 })
				const fields = {
					name: res.name,
					first_name: res.paternal_surname === null ? "" : res.paternal_surname,
					last_name: res.maternal_surname === null ? "" : res.maternal_surname,
					email: res.email,
					phone_number: res.mobile_phone,
					role_group: res.role_group_users.id,
					username: res.username === null ? "" : res.username,
					check_activation: res.is_active,
					check_apply_offer: res.can_apply_offers,
					password: "",
					confirm_password: "",
				}
				setForm(fields)
			} catch (error) {
				console.log(error)
			}
			hideLoading()
		}
	}, [isAddMode, id, tokenAccess, setForm, showLoading, hideLoading])

	useEffect(() => {
		loadData()
	}, [loadData])
}
