import { useState } from "react"

const OPTIONS = { NO_INVOICES: "no_invoices", PERCENTAGE: "percentage" }

export const useChartType = ({ t }) => {
	const [type, setType] = useState(OPTIONS.NO_INVOICES)

	return {
		value: type,
		change: setType,
		options: Object.values(OPTIONS).map((option) => ({ value: option, content: t(`chart_type.${option}`) })),
		is: {
			noInvoices: type === OPTIONS.NO_INVOICES,
			percentage: type === OPTIONS.PERCENTAGE,
		},
	}
}
