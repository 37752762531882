import { getEnvironment } from "helpers"

export const SubdomainManager = {
	is: {
		offers: (subdomain) => {
			const env = getEnvironment()
			if (env.isProduction) return subdomain === "ofertas"
			if (env.isStaging) return subdomain === "ofertas-staging"
			if (env.isTest) return subdomain === "ofertas-test"
			return subdomain === "ofertas-dev"
		},
		default: (subdomain) => {
			const env = getEnvironment()
			if (env.isProduction) return subdomain === "carousel"
			if (env.isStaging) return subdomain === "carousel-staging"
			if (env.isTest) return subdomain === "carousel-test"
			return subdomain === "carousel-dev"
		}
	},
}
