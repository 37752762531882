import classes from "./change_recovery_password.module.scss"

import { useTranslation } from "react-i18next"
import { useParams, useNavigate } from "react-router-dom"

import { Grid } from "@arbolfinanciero/kikoya-lib-hub/controls/Grid"
import { Flex } from "@arbolfinanciero/kikoya-lib-hub/controls/Flex"
import { Text } from "@arbolfinanciero/kikoya-lib-hub/controls/Text"
import { Loading } from "@arbolfinanciero/kikoya-lib-hub/controls/Loading"
import { TextField } from "@arbolfinanciero/kikoya-lib-hub/controls/TextField"
import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls/Button"
import { IconButton } from "@arbolfinanciero/kikoya-lib-hub/controls/IconButton"

import { useForm } from "@arbolfinanciero/kikoya-lib-hub/hooks/useForm"
import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks/useShowHide"

import ArrowBackIcon from "@mui/icons-material/ArrowBack"

import { useAlertMessages } from "AlertMessageContext"
import { UserModel } from "models/UserModel"

import { Header } from "../Header"

const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#.$($)$-$_])[A-Za-z\d$@$!%*?&#.$($)$-$_]{8,16}$/

export const ChangeRecoveryPassword = () => {
	const { t } = useTranslation("auth", { keyPrefix: "change_recovery_password" })
	const { hash } = useParams()
	const navigate = useNavigate()

	const messages = useAlertMessages()
	const loading = useShowHide()

	const handleValidateFormatConfirmPassword = (confirmPassword) => {
		if (confirmPassword !== form.values.password) return t("error.passwords_not_match")
		return null
	}

	const form = useForm({
		inputs: {
			password: {
				required: true,
				regex: PASSWORD_REGEX,
				customRegexError: t("error.password_format"),
			},
			confirm_password: {
				required: true,
				regex: PASSWORD_REGEX,
				customRegexError: t("error.password_format"),
				onValidateFormat: handleValidateFormatConfirmPassword,
			},
		},
	})

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (!form.validate()) return null
		loading.show()
		try {
			await UserModel.Password.changeRecovery({ hash_recover: hash, password: form.values.password })
			messages.successful.change([t("label.success")])
		} catch {
			messages.errors.change([t("label.error")])
		}
		loading.hide()
	}

	const handleBack = () => navigate("/auth/admin/login")
	const handleChange = (e) => form.change(e.target.name, e.target.value)
	const handleBlur = (e) => form.validateFormat(e.target.name)

	return (
		<Grid gridTemplateRows="auto 1fr" alignItems="center" justifyItems="center" h100>
			<Header />
			<Grid component="form" className={classes.form} onSubmit={handleSubmit} gap="1em" justifyItems="center">
				<Flex alignItems="center" gap="0.5em">
					<IconButton className={classes.back_button} color="primary" onClick={handleBack}>
						<ArrowBackIcon sx={{ fontSize: "1em" }} />
					</IconButton>
					<Text tag="h1" className={classes.title}>
						{t("title")}
					</Text>
				</Flex>
				<Text className={classes.text}>{t("text")}</Text>
				<TextField
					name="password"
					type="password"
					label={t("label.password")}
					className={classes.input}
					value={form.values.password}
					onChange={handleChange}
					onBlur={handleBlur}
					error={form.errors.password ? true : false}
					errorText={form.errors.password}
					aria-label={t("input.password.arialabel")}
				/>
				<TextField
					name="confirm_password"
					type="password"
					label={t("label.confirm_password")}
					className={classes.input}
					value={form.values.confirm_password}
					onChange={handleChange}
					onBlur={handleBlur}
					error={form.errors.confirm_password ? true : false}
					errorText={form.errors.confirm_password}
					aria-label={t("input.confirm_password.arialabel")}
				/>
				<Loading loading={loading.isVisible}>
					<Button className={classes.button} type="submit" variant="contained" color="primary">
						{t("button.submit")}
					</Button>
				</Loading>
			</Grid>
		</Grid>
	)
}
