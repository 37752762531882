import { Routes, Navigate, Route } from "react-router-dom"

import { ChangePassword as ChangePassAdmin } from "components/admin/Login/ChangePassword"
import { Products } from "components/admin/Products"
import { Branches } from "components/admin/Home/Branches"

import { Layout } from "components/admin/Home/Layout"

import { Whitelabel } from "components/admin/Home/Whitelabel"

import { AddEdit as UserkikoyaForm } from "components/admin/Home/Users/AddEdit/AddEdit"
import { AddEdit as AdminEditFinancier } from "components/admin/Home/Financier/AddEdit"

export const AdminRouter = () => (
	<Routes>
		<Route path="/ChangePassword" element={<ChangePassAdmin />} />

		<Route path="/Layout" element={<Layout />} />
		<Route path="/Layout/:tab" element={<Layout />} />

		<Route path="/whitelabel/edit/:id" element={<Whitelabel />} />
		<Route path="/whiteLabel/create" element={<Whitelabel />} />

		<Route path="/users/create" element={<UserkikoyaForm />} />
		<Route path="/Financiero/edit/:id" element={<AdminEditFinancier />} />

		<Route path="/whitelabel/groups/:gid/branches" element={<Branches />} />
		<Route path="/products/lender/:lender_id" element={<Products />} />
		<Route path="*" element={<Navigate to="/admin/Layout" replace />} />
	</Routes>
)
