import axios from "axios"
import { getBaseURL } from "helpers"
import { handleCommonErrors } from "models/helpers"

const __endpoint = getBaseURL() + "/api/v1/whitelabels"
const __endpointLender = getBaseURL() + "/api/v1/whitelabel"

const Lender = (() => {
	const resource = "lender"
	const get = async (token, wid) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const body = { headers }
			const url = `${__endpointLender}/${wid}/${resource}/`
			const response = await axios.get(url, body)
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
		}
	}

	const replace = async (token, wid, lenders) => {
		try {
			const headers = { headers: { Authorization: "Bearer " + token } }
			const body = lenders
			const url = `${__endpointLender}/${wid}/${resource}/replace/`
			const response = await axios.post(url, body, headers)
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
		}
	}

	const put = async (token, { id, product_id }) => {
		try {
			const headers = { headers: { Authorization: "Bearer " + token } }
			const body = { product_id }
			const url = `${__endpointLender}/${resource}/${id}/`
			const response = await axios.put(url, body, headers)
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
		}
	}
	return { resource, get, replace, put }
})()

const get = async (tokenAccess) => {
	try {
		const headers = { Authorization: `Bearer ${tokenAccess}` }
		const body = { headers }
		const res = await axios.get(`${__endpoint}/`, body)
		const response = res.data?.results || [{}]
		return response
	} catch (error) {
		await handleCommonErrors(error)
	}
}

const find = async (tokenAccess, { wid }) => {
	try {
		const headers = { Authorization: `Bearer ${tokenAccess}` }
		const body = { headers }
		const res = await axios.get(`${__endpoint}/?id=${wid}`, body)

		const response = res.data?.results || [{}]
		const results = response[0]
		const financierConfigs = results.financier_configs || []
		let contactEmail = ""
		financierConfigs.forEach((x) => {
			if (x.type_configurations.id === 1) contactEmail = x.config_value
		})

		const fields = {
			host: results.host,
			name_company: results.display_name,
			id_company: results.id,
			active: results.active,
			no_max_financier: results.max_financier_user === null ? 0 : results.max_financier_user,
			id_folio: results.code_folio === null ? "" : results.code_folio.toString().toUpperCase(),
			rfc_company: results.rfc,
			principal_email: results.email_principal,
			contact_email: contactEmail,
			manage_new_cars: results.manage_new_cars,
			manage_semi_new_cars: results.manage_semi_new_cars,
		}
		return fields
	} catch (error) {
		await handleCommonErrors(error)
	}
}

const create = async (tokenAccess, data) => {
	try {
		const headers = { headers: { Authorization: "Bearer " + tokenAccess } }
		const body = { ...data }
		const response = await axios.post(`${__endpoint}/financier/`, body, headers)
		return response
	} catch (error) {
		const response = await handleCommonErrors(error)
		throw response.data
	}
}

const update = async (tokenAccess, wid, data) => {
	try {
		const headers = { headers: { Authorization: "Bearer " + tokenAccess } }
		const body = { ...data }
		const response = await axios.put(`${__endpoint}/${wid}/`, body, headers)
		return response
	} catch (error) {
		const response = await handleCommonErrors(error)
		throw response.data
	}
}

export const WhitelabelModel = { __endpoint, __endpointLender, get, find, create, update, Lender }
