import React, { useRef, useState } from "react"
import { Button, Grid, Checkbox, IconButton, CircularProgress } from "@mui/material"
import AttachFileIcon from "@mui/icons-material/AttachFile"
import "./style.scss"
import { Colors } from "./Colors"
import FormControlLabel from "@mui/material/FormControlLabel"
import { useSelector } from "react-redux"
import { AlertaConfirmacion } from "../../../admin/Login/AlertaConfirmacion"
import { HeaderAuth } from "components/financiero/Home/HeaderAuth/HeaderAuth"
import ArrowBack from "@mui/icons-material/ArrowBack"
// INTERNACIONALIZATION
import { useTranslation } from "react-i18next"
import { SwitchColors } from "./SwitchColors"
import { useNavigate } from "react-router-dom"

import { GeneralSettingsModel } from "models/GeneralSettingsModel"

export const Branding = () => {
	const navigate = useNavigate()
	const { t: t_general } = useTranslation("branding")
	const { t } = useTranslation()
	const { tokenAccess } = useSelector((state) => state.auth)
	const fileInputPrincipal = useRef()
	const fileLogotipo = useRef()
	const fileInputFavIcon = useRef()
	const [nameLogotipo, setNameLogotipo] = useState("Seleccione un archivo")
	const [namePrincipal, setNamePrincipal] = useState("Seleccione un archivo")
	const [nameFavIcon, setNameFavIcon] = useState("Seleccione un archivo")
	const [sinImagen, setSinImagen] = useState(true)
	const [errorResponse] = useState("")
	const [{ openC, messageC, emojiC, textButton }, setOpenC] = useState({
		openC: false,
		messageC: "",
		emojiC: "",
		textButton: "",
	})
	const [circleGrid, setVisibleCircle] = useState(false)

	const updAlertaConfirma = (messageC, emojiC, textButton) => {
		setOpenC({
			openC: true,
			messageC,
			emojiC,
			textButton,
		})
	}

	const handleCloseC = () => {
		setOpenC({
			open: false,
			message: "",
			emoji: "",
		})
		fileInputPrincipal.current.value = ""
		setNameLogotipo(t_general("input.label.logotipo"))
		fileLogotipo.current.value = ""
		setNamePrincipal(t_general("input.label.principal"))
		fileInputFavIcon.current.value = ""
		setNameFavIcon(t_general("input.label.favIcon"))
	}

	const handleAcceptC = () => {
		setOpenC({
			openC: false,
			messageC: "",
			emojiC: "",
			textButton: "",
		})
		navigate("")
	}

	const handleInputLogo = () => {
		setNameLogotipo(fileLogotipo.current.files[0].name)
	}

	const handleInputPrincipal = () => {
		setNamePrincipal(fileInputPrincipal.current.files[0].name)
	}

	const handleSinImagen = () => {
		setSinImagen(!sinImagen)
	}

	const handleInputFavIcon = () => {
		setNameFavIcon(fileInputFavIcon.current.files[0].name)
	}

	const onSubmit = (event) => {
		setVisibleCircle(true)
		event.preventDefault()

		async function fetchCreateBranding() {
			const fileLogo = fileLogotipo.current.files[0]
			const filePrincipal = fileInputPrincipal.current.files[0]
			const fileFavIcon = fileInputFavIcon.current.files[0]

			const formData = new FormData()
			formData.append("secondary_image", fileLogo)
			formData.append("principal_image", filePrincipal)
			formData.append("icon_image", fileFavIcon)
			formData.append("principal_color", document.getElementById("primary_color").value)
			formData.append("secondary_color", document.getElementById("secondary_color_text").value)
			formData.append("icon_color", document.getElementById("color_menu").value)
			formData.append("selection_color", document.getElementById("primary_color").value)
			formData.append("header_color", document.getElementById("primary_color").value)
			formData.append("line_color", document.getElementById("color_line").value)
			formData.append("point_line_color", document.getElementById("color_point_line").value)
			formData.append("button_text_color", document.getElementById("color_text_button").value)
			formData.append("confirmation_btn_color", document.getElementById("button_color_accept").value)
			formData.append("alert_btn_color", document.getElementById("button_color_warning").value)
			formData.append("primary_text_color", document.getElementById("primary_color_text").value)
			formData.append("secondary_text_color", document.getElementById("secondary_color_text").value)
			formData.append("landing_bg_color", document.getElementById("color_landing_page").value)
			formData.append("bar_border_color", document.getElementById("border_color_bar").value)
			formData.append("primary_btn_color", document.getElementById("button_primary_color").value)
			formData.append("info_btn_color", document.getElementById("button_color_info").value)
			formData.append("cancel_btn_color", document.getElementById("button_color_cancel").value)
			formData.append("menu_text_color", document.getElementById("menu_text_color").value)

			try {
				await GeneralSettingsModel.Branding.create(tokenAccess, formData)
				updAlertaConfirma(t_general("post_created"), "✅", t("admin.addEdit.whitelabel_update_message"))
			} catch {
				setVisibleCircle(false)
			}
		}

		fetchCreateBranding()
	}

	return (
		<>
			<HeaderAuth />
			<br />
			<Grid container spacing={1}>
				<Grid item xs={1} md={1} sm={1}></Grid>
				<Grid item xs={10} md={10} sm={10}>
					<IconButton color="primary" aria-label="edit" size="large" onClick={() => navigate(-1)}>
						<ArrowBack fontSize="inherit" />
					</IconButton>
				</Grid>
				<Grid item xs={1} md={1} sm={1}></Grid>
			</Grid>
			<Grid container spacing={1}>
				<Grid item xs={1} md={1} sm={1}></Grid>
				<Grid item xs={12} md={3} sm={3} lg={3}>
					<div className="branding__containerInput">
						<h3>{t_general("h3.logotipo.description")}</h3>
						<input
							accept="image/*"
							id="logotipo"
							type="file"
							ref={fileLogotipo}
							onChange={handleInputLogo}
							style={{ display: "none" }}
						/>
						<label htmlFor="logotipo">
							<Button variant="contained" className="branding__button" component="span">
								<p>{nameLogotipo}</p>
								<AttachFileIcon className="icon" />
							</Button>
						</label>
					</div>
				</Grid>
				<Grid item xs={12} md={4} sm={4} lg={4} style={{ textAlign: "-webkit-center" }}>
					<div className="branding__containerInput">
						<h3>{t_general("h3.branding.description")} </h3>
						<input
							accept="image/*"
							id="principal"
							type="file"
							ref={fileInputPrincipal}
							style={{ display: "none" }}
							onChange={handleInputPrincipal}
						/>
						<label htmlFor="principal">
							<Button variant="contained" className="branding__button" component="span">
								<p>{namePrincipal}</p>
								<AttachFileIcon className="icon" />
							</Button>
							<FormControlLabel
								value="end"
								control={<Checkbox color="primary" />}
								label={<span style={{ fontSize: "14px" }}>{t_general("switch.without_image")}</span>}
								labelPlacement="end"
								onChange={handleSinImagen}
							/>
						</label>
					</div>
				</Grid>
				<Grid item xs={12} md={3} sm={3} lg={3} style={{ textAlign: "-webkit-right" }}>
					<div className="branding__containerInput">
						<h3
							style={{
								textAlign: "center",
							}}>
							{t_general("h3.icono.description")}
						</h3>
						<input
							accept=".ico"
							id="favIcon"
							type="file"
							ref={fileInputFavIcon}
							style={{ display: "none" }}
							onChange={handleInputFavIcon}
						/>
						<label htmlFor="favIcon">
							<Button variant="contained" className="branding__button" component="span">
								<p>{nameFavIcon}</p>
								<AttachFileIcon className="icon" />
							</Button>
						</label>
					</div>
				</Grid>
				<Grid item xs={1} md={1} sm={1}></Grid>
			</Grid>
			<Grid container spacing={1}>
				<Grid item xs={1} md={1} sm={1}></Grid>
				<Grid item xs={2} md={2} sm={2}>
					<div className="branding__containerInput">
						<h3>{t_general("h3.color_line")}</h3>
						<Colors id_element="color_line" />
					</div>
				</Grid>
				<Grid item xs={2} md={2} sm={2}>
					<div className="branding__containerInput">
						<h3>{t_general("h3.color_point_line")}</h3>
						<Colors id_element="color_point_line" />
					</div>
				</Grid>
				<Grid item xs={2} md={2} sm={2}>
					<div className="branding__containerInput">
						<h3>{t_general("h3.color_menu")}</h3>
						<Colors id_element="color_menu" />
					</div>
				</Grid>
				<Grid item xs={2} md={2} sm={2}>
					<div className="branding__containerInput">
						<h3>{t_general("h3.primary_color")}</h3>
						<Colors id_element="primary_color" />
					</div>
				</Grid>
				<Grid item xs={2} md={2} sm={2}>
					<div className="branding__containerInput">
						<h3>{t_general("h3.button_text_color")}</h3>
						<Colors id_element="color_text_button" />
					</div>
				</Grid>
				<Grid item xs={1} md={1} sm={1}></Grid>
			</Grid>
			<br />
			<br />
			<Grid container spacing={1}>
				<Grid item xs={1} md={1} sm={1}></Grid>
				<Grid item xs={2} md={2} sm={2}>
					<div className="branding__containerInput">
						<h3>{t_general("h3.button_primary_text")}</h3>
						<Colors id_element="primary_color_text" />
					</div>
				</Grid>
				<Grid item xs={2} md={2} sm={2}>
					<div className="branding__containerInput">
						<h3>{t_general("h3.button_secondary_text")}</h3>
						<Colors id_element="secondary_color_text" />
					</div>
				</Grid>
				<Grid item xs={2} md={2} sm={2}>
					<div className="branding__containerInput">
						<h3>{t_general("h3.color_landing")}</h3>
						<Colors id_element="color_landing_page" />
					</div>
				</Grid>
				<Grid item xs={2} md={2} sm={2}>
					<div className="branding__containerInput">
						<h3>{t_general("h3.border_color_bar")}</h3>
						<Colors id_element="border_color_bar" />
					</div>
				</Grid>
				<Grid item xs={2} md={2} sm={2}>
					<div className="branding__containerInput">
						<h3>{t_general("h3.button_color_primary")}</h3>
						<Colors id_element="button_primary_color" />
					</div>
				</Grid>
				<Grid item xs={1} md={1} sm={1}></Grid>
			</Grid>
			<br />
			<br />
			<Grid container spacing={1}>
				<Grid item xs={1} md={1} sm={1}></Grid>
				<Grid item xs={2} md={2} sm={2}>
					<div className="branding__containerInput">
						<h3>{t_general("h3.button_accept")}</h3>
						<Colors id_element="button_color_accept" />
					</div>
				</Grid>
				<Grid item xs={2} md={2} sm={2}>
					<div className="branding__containerInput">
						<h3>{t_general("h3.button_warning")}</h3>
						<Colors id_element="button_color_info" />
					</div>
				</Grid>
				<Grid item xs={2} md={2} sm={2}>
					<div className="branding__containerInput">
						<h3>{t_general("h3.button_alert")}</h3>
						<Colors id_element="button_color_warning" />
					</div>
				</Grid>
				<Grid item xs={2} md={2} sm={2}>
					<div className="branding__containerInput">
						<h3>{t_general("h3.button_cancel")}</h3>
						<Colors id_element="button_color_cancel" />
					</div>
				</Grid>
				<Grid item xs={2} md={2} sm={2}>
					<div className="branding__containerInput">
						<h3>{t_general("h3.color_text_menu")}</h3>
						<SwitchColors firstColor="#FFFFFF" secondColor="#000000" idEl="menu_text_color" />
					</div>
				</Grid>
			</Grid>
			<br />
			<br />
			<Grid container spacing={1}>
				<Grid item xs={1} md={1} sm={1}>
					<label>{errorResponse}</label>
				</Grid>
				<Grid item xs={10} md={10} sm={10} style={{ textAlign: "right" }}>
					{circleGrid ? (
						<CircularProgress />
					) : (
						<Button
							className="branding__save_btn"
							variant="contained"
							color="primary"
							type="submit"
							style={{
								width: "100%",
								padding: "10px 0",
								maxWidth: "250px",
								borderRadius: "40px",
								textTransform: "capitalize",
							}}
							onClick={onSubmit}>
							{t_general("button.save")}
						</Button>
					)}
				</Grid>
				<Grid item xs={1} md={1} sm={1}></Grid>
			</Grid>
			<AlertaConfirmacion
				openC={openC}
				messageC={messageC}
				emojiC={emojiC}
				textButton={textButton}
				handleCloseC={handleCloseC}
				handleAcceptC={handleAcceptC}
			/>
		</>
	)
}
