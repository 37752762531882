import { Modal } from "@arbolfinanciero/kikoya-lib-hub/controls/Modal"
import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls/Button"

import { useModal } from "@arbolfinanciero/kikoya-lib-hub/hooks/useModal"

import { useAssociatedGroupsContext } from "./AssociatedGroupsContext"
import { AssociateNewGroupsModal } from "./AssociateNewGroupsModal"

export const AssociateNewGroupsButton = () => {
	const modal = useModal()
	const { t } = useAssociatedGroupsContext()

	return (
		<>
			<Button variant="contained" color="primary" onClick={modal.open}>
				{t("button.new")}
			</Button>
			<Modal open={modal.isOpen}>
				<AssociateNewGroupsModal closeModal={modal.close} />
			</Modal>
		</>
	)
}
