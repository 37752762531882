import classes from "./styles/products.module.scss"

import { CustomTextField } from "@arbolfinanciero/kikoya-lib-hub/controls"
import { handleChangeInteger } from "./ProductsFormUtils"

const ProductLabel = ({ children }) => <p className={classes.products__form__label}>{children}</p>

export const ProductCluster = ({ children, label }) => (
	<div className={classes.products__form__cluster}>
		<ProductLabel>{label}</ProductLabel>
		{children}
	</div>
)

export const ProductTerms = ({ index, value, errors = [], handleChange, handleDelete, DefaultDeleteItemComponent }) => {
	const handleChangeTextField = (e) => {
		handleChange({ term: e.target.value, payment_term_config: 4 })
	}

	return (
		<div className={classes.products__form__terms}>
			<CustomTextField
				value={value.term}
				onChange={handleChangeInteger(handleChangeTextField)}
				className={classes.products__form__terms__textfield}
				errorText={errors[index]}
				error={errors[index]}
			/>
			<p className={classes.products__form__terms__label}>Meses</p>
			<DefaultDeleteItemComponent handleDelete={handleDelete} />
		</div>
	)
}
