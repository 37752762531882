import classes from "./login.module.scss"

import { useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { Text } from "@arbolfinanciero/kikoya-lib-hub/controls/Text"
import { Grid } from "@arbolfinanciero/kikoya-lib-hub/controls/Grid"
import { Loading } from "@arbolfinanciero/kikoya-lib-hub/controls/Loading"
import { TextField } from "@arbolfinanciero/kikoya-lib-hub/controls/TextField"
import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls/Button"
import { IconButton } from "@arbolfinanciero/kikoya-lib-hub/controls/IconButton"

import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"

const LoginFormInput = ({ form, name, ...props }) => {
	const { t } = useTranslation("auth", {keyPrefix: "login"})
	const handleChange = (e) => form.change(e.target.name, e.target.value)
	const handleBlur = (e) => form.validateFormat(e.target.name)

	return (
		<TextField
			name={name}
			label={t(`label.${name}`)}
			className={classes.input}
			aria-label={t(`input.${name}.arialabel`)}
			value={form.values[name]}
			onChange={handleChange}
			onBlur={handleBlur}
			error={form.errors[name] ? true : false}
			errorText={form.errors[name]}
			role="textbox"
			{...props}
		/>
	)
}

export const LoginForm = ({ onSubmit, form, loading }) => {
	const { t } = useTranslation("auth", {keyPrefix: "login"})

	const [showPassword, setShowPassword] = useState(false)

	const handleShowPassword = () => setShowPassword(!showPassword)

	return (
		<Grid component="form" className={classes.form} onSubmit={onSubmit} justifyItems="center" gap="1em">
			<Text tag="h1" className={classes.title}>
				{t("title")}
			</Text>
			<Grid gap="1em" justifyItems="center" w100>
				<LoginFormInput name="email" form={form} />
				<LoginFormInput
					name="password"
					form={form}
					type={showPassword ? "text" : "password"}
					EndIcon={() => (
						<IconButton onClick={handleShowPassword}>
							{showPassword ? <Visibility /> : <VisibilityOff />}
						</IconButton>
					)}
				/>
				<Link to="/auth/admin/login/2/1">
					{t("link.recover_password")} <b>{t("link.recover")}</b>
				</Link>
				<Loading loading={loading.isVisible}>
					<Button className={classes.button} type="submit" variant="contained" color="primary">
						{t("button.submit")}
					</Button>
				</Loading>
			</Grid>
		</Grid>
	)
}
