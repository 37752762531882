import classes from "./chart_donut.module.scss"

import theme from "theme"
import PropTypes from "prop-types"

import { useRef, useMemo, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { PieChart, Pie, Cell } from "recharts"
import { ChartDonutLabel } from "./ChartDonutLabel"
import { useDataLengthBySide } from "./useDataLengthBySide"
import { useSize } from "./useSize"
import { useResponsive } from "./useResponsive"
import { filterColor } from "./filterColor"

const ANGLE_GAP = 90

const ChartDonutContent = ({ className = "", keyName, keyValue, labelFormatter, donutData, data }) => {
	const $container = useRef()
	const { t } = useTranslation("charts")

	const dataLengthBySide = useDataLengthBySide({ donutData, data, keyName, keyValue })
	const size = useSize($container, dataLengthBySide)

	useResponsive($container, dataLengthBySide)

	const customFilterColor = (index) => {
		let total = dataLengthBySide.left
		if (index + 1 > dataLengthBySide.left) {
			index = index - dataLengthBySide.left
			total = dataLengthBySide.right
		}
		return filterColor(index, total)
	}

	const oneSide = dataLengthBySide.right === 0 || dataLengthBySide.left === 0
	const classNames = [classes.donut, className]
	return (
		<div className={classNames.join(" ")} ref={$container}>
			{donutData.length === 0 ? (
				<h3 className={classes.donut__text}>{t("no_data")}</h3>
			) : (
				<PieChart width={size.width} height={size.height}>
					<Pie
						data={donutData}
						isAnimationActive={false}
						innerRadius={60}
						cx={oneSide ? size.width - size.height / 2 - 5 : "50%"}
						outerRadius={100}
						paddingAngle={2}
						startAngle={ANGLE_GAP}
						endAngle={360 + ANGLE_GAP}
						label={<ChartDonutLabel dataLengthBySide={dataLengthBySide} labelFormatter={labelFormatter} />}
						fill={theme.palette.primary.main}
						labelLine={false}
						dataKey={keyValue}
						nameKey={keyName}>
						{donutData.map((_, index) => (
							<Cell key={index} style={{ filter: customFilterColor(index) }} />
						))}
					</Pie>
				</PieChart>
			)}
		</div>
	)
}

const ChartDonut = ({ data = [], keyValue, ...props }) => {
	const donutData = useMemo(() => data.filter((datum) => datum[keyValue] > 0), [data, keyValue])
	const [update, setUpdate] = useState(false)

	useEffect(() => {
		setUpdate(true)
	}, [donutData])

	useEffect(() => {
		if (update) setUpdate(false)
	}, [update])

	if (update) return null

	return <ChartDonutContent {...props} donutData={donutData} keyValue={keyValue} data={data} />
}

ChartDonut.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object),
	keyValue: PropTypes.string.isRequired,
	keyName: PropTypes.string.isRequired,
	className: PropTypes.string,
	/** Method to format labels. Must use useCallback */
	labelFormatter: PropTypes.func,
}

export { ChartDonut }
