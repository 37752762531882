import React from "react"
import { makeStyles } from "@mui/styles"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import { useSelector } from "react-redux"
import "./style.scss"
import { useNavigate } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		height: "80px",
	},
	toolbar: {
		height: "100%",
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
}))

export const HeaderPrincipal = () => {
	const navigate = useNavigate()
	const { logotipo } = useSelector((state) => state.branding)
	const classes = useStyles()
	const redirect = () => {
		navigate("financier/Layout")
	}
	return (
		<AppBar position="sticky" className={classes.root}>
			<Toolbar className={classes.toolbar}>
				<div className="header__container_logo">
					<img className="logo" src={logotipo} alt="logo" onClick={redirect}></img>
				</div>
			</Toolbar>
		</AppBar>
	)
}
