import { useState, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"

import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks/useShowHide"
import { useForm as useFormHook } from "@arbolfinanciero/kikoya-lib-hub/hooks/useForm"

import { MerchantModel } from "models"

import { useAlertMessages } from "AlertMessageContext"
import { useSuburb } from "./useSuburb"

const regExpWords = /^[a-zA-ZÀ-ú0-9-,._/ ]+$/
const refRFC = /^[a-zA-Z0-9]+$/
const regExpNumbers = /^[0-9]*$/
const INVENTORY_INFORMATION_OPTIONS = { NEW: "new", SEMI_NEW: "semi_new" }

export const useInventoryOptions = () => {
	const { t } = useTranslation("admin", { keyPrefix: "branches" })
	return [
		{ text: t("form.options.inventory.new"), value: INVENTORY_INFORMATION_OPTIONS.NEW },
		{ text: t("form.options.inventory.semi_new"), value: INVENTORY_INFORMATION_OPTIONS.SEMI_NEW },
	]
}

export const useForm = () => {
	const { gid } = useParams()
	const { t: t_general } = useTranslation("models", { keyPrefix: "general" })
	const { t } = useTranslation("admin", { keyPrefix: "branches" })
	const { tokenAccess } = useSelector((state) => state.auth)
	const [readOnly, setReadOnly] = useState(false)
	const messages = useAlertMessages()

	const { show: showLoading, hide: hideLoading, isVisible: isLoading } = useShowHide(false)

	const customRegexError = t("form.error.char")
	const form = useFormHook({
		inputs: {
			branchId: { initialValue: null },
			cityId: { initialValue: null },
			clave: { initialValue: "", regex: regExpWords, required: true, min: 3, max: 20, customRegexError },
			distributor: { initialValue: "", regex: regExpWords, required: true, min: 3, max: 50, customRegexError },
			representation: { initialValue: "" },
			business: { initialValue: "", regex: regExpWords, required: true, min: 3, max: 100, customRegexError },
			rfc: { initialValue: "", regex: refRFC, required: true, min: 12, max: 12, customRegexError },
			inventory: { initialValue: [] },
			street: { initialValue: "", regex: regExpWords, required: true, min: 1, max: 50, customRegexError },
			internal: { initialValue: "", regex: regExpWords, required: false, min: 1, max: 20, customRegexError },
			external: { initialValue: "", regex: regExpWords, required: true, min: 1, max: 20, customRegexError },
			cp: { initialValue: "", regex: regExpNumbers, required: true, min: 5, max: 5, customRegexError },
			owner: { initialValue: "", regex: regExpWords, required: false, min: 3, max: 50, customRegexError },
			lada: { initialValue: "", regex: regExpNumbers, required: false, min: 1, max: 20, customRegexError },
			phone: { initialValue: "", regex: regExpNumbers, required: false, min: 8, max: 10, customRegexError },
			manager: { initialValue: "", regex: regExpWords, required: false, min: 8, max: 50, customRegexError },
			admin: { initialValue: "", regex: regExpWords, required: false, min: 8, max: 50, customRegexError },
			sales: { initialValue: "", regex: regExpWords, required: false, min: 8, max: 50, customRegexError },
			postsale: { initialValue: "", regex: regExpWords, required: false, min: 8, max: 50, customRegexError },
			state: { initialValue: "" },
			city: { initialValue: "" },
		},
	})
	const isNew = useMemo(() => form.values.branchId === null, [form.values.branchId])
	const { isLoading: isLoadingSuburb } = useSuburb(form)

	const submit = async () => {
		try {
			showLoading()
			const data = {
				address: {
					street: form.values.street,
					external_number: form.values.external,
					internal_number: form.values.internal || null,
					zip_code: form.values.cp,
					city_id: form.values.cityId,
				},
				representation_type_id: form.values.representation || null,
				extra_data: {
					owner: form.values.owner || null,
					dial_code: form.values.lada || null,
					phone_number: form.values.phone || null,
					general_manager: form.values.manager || null,
					administrative_manager: form.values.admin || null,
					sales_manager: form.values.sales || null,
					after_sales_manager: form.values.postsale || null,
				},
				identifier: form.values.clave,
				distributor: form.values.distributor,
				business_name: form.values.business,
				rfc: form.values.rfc,
				manage_new_cars: form.values.inventory.includes(INVENTORY_INFORMATION_OPTIONS.NEW),
				manage_semi_new_cars: form.values.inventory.includes(INVENTORY_INFORMATION_OPTIONS.SEMI_NEW),
			}
			if (isNew) await MerchantModel.Branch.create(tokenAccess, gid, data)
			else await MerchantModel.Branch.update(tokenAccess, gid, form.values.branchId, data)
			messages.successful.change([t_general("success.message")])
			return true
		} catch (error) {
			messages.errors.change(Object.values(error))
		}
		hideLoading()
	}

	const setData = async (id) => {
		try {
			showLoading()
			const { manage_new_cars, manage_semi_new_cars, ...data } = await MerchantModel.Branch.getById(
				tokenAccess,
				gid,
				id
			)
			const inventory = []
			if (manage_new_cars) inventory.push(INVENTORY_INFORMATION_OPTIONS.NEW)
			if (manage_semi_new_cars) inventory.push(INVENTORY_INFORMATION_OPTIONS.SEMI_NEW)

			const branch = {
				branchId: data.pk,
				cityId: data.address.city,
				clave: data.identifier,

				distributor: data.distributor,
				representation: data.representation_type?.pk ?? "",
				business: data.business_name,
				rfc: data.rfc,
				inventory,

				street: data.address.street,
				internal: data.address.internal_number ?? "",
				external: data.address.external_number,
				cp: data.address.zip_code,
				owner: data.extra_data.owner ?? "",
				lada: data.extra_data.dial_code ?? "",
				phone: data.extra_data.phone_number ?? "",
				manager: data.extra_data.general_manager ?? "",
				admin: data.extra_data.administrative_manager ?? "",
				sales: data.extra_data.sales_manager ?? "",
				postsale: data.extra_data.after_sales_manager ?? "",
				state: data.address.city.state.description,
				city: data.address.city.description,
			}
			form.set(branch)
		} catch (error) {
			messages.errors.change(Object.values(error))
		}
		hideLoading()
	}

	const startEditing = (id) => {
		setData(id)
		setReadOnly(false)
	}

	const openReadOnly = (id) => {
		setData(id)
		setReadOnly(true)
	}

	const reset = () => {
		form.reset()
		setReadOnly(false)
		hideLoading()
	}

	return { ...form, startEditing, openReadOnly, isLoading, isLoadingSuburb, readOnly, reset, submit, isNew }
}
