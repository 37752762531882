import classes from "./styles/products.module.scss"

import { useContext, useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"

import { Button, CustomTextField, TextArea, ItemsInput, Loading } from "@arbolfinanciero/kikoya-lib-hub/controls"
import { Percent as PercentIcon, AttachMoney as CurrencyIcon, ArrowBack as ArrowBackIcon } from "@mui/icons-material"

import { ProductsContext } from "./ProductsContext"
import { ProductCluster, ProductTerms } from "./ProductsFormComponent"
import { handleChangeNumeric, createFormValidations } from "./ProductsFormUtils"

import { ProductModel } from "models"
import { useForm, useShowHide } from "hooks"

const INITIAL_PRODUCT = {
	name: "",
	description: "",
	total_approved: "",
	hitch_approved: "",
	cat: "",
	initial_payment: "",
	interest_rate: "",
	iva: 16,
	product_terms: [],
}

const DEFAULT_PRODUCT_TERM = { term: "", payment_term_config: 4 }

export const ProductsForm = ({ initial_data = INITIAL_PRODUCT }) => {
	const { t } = useTranslation("admin", { keyPrefix: "products" })
	const loading = useShowHide()
	const showLoading = loading.show
	const hideLoading = loading.hide

	const { page, tokenAccess, lender_id } = useContext(ProductsContext)
	const [data, handleChange, setData] = useForm(initial_data)
	const [errors, setErrors] = useState({})

	const handleGoBack = () => page.setList()

	const handleChangeItems = (value) => {
		setData({ ...data, product_terms: value })
	}

	const fetchProduct = useCallback(async () => {
		showLoading()
		const response = await ProductModel.find(tokenAccess, page.id)
		setData({
			...response,
			hitch_approved: response.hitch_approved * 100,
			cat: response.cat * 100,
			initial_payment: response.initial_payment * 100,
			interest_rate: response.interest_rate * 100,
			iva: response.iva * 100,
		})
		hideLoading()
	}, [tokenAccess, page.id, setData, showLoading, hideLoading])

	useEffect(() => {
		if (!page.is.new) fetchProduct()
	}, [fetchProduct, page.is.new])

	const validateInputs = () => {
		let newErrors = {}
		const validations = createFormValidations({ errors: newErrors, data, t })
		validations.string({ field: "name", min: 3, max: 50 })
		validations.string({ field: "description", min: 10, max: 500 })
		validations.numeric({ field: "total_approved", min: 1, max: 10_000_000 })
		validations.numeric({ field: "hitch_approved", min: 0, max: 100 })
		validations.numeric({ field: "cat", min: 0, max: 200 })
		validations.numeric({ field: "initial_payment", min: 0, max: 100 })
		validations.numeric({ field: "interest_rate", min: 0, max: 100 })
		validations.numeric({ field: "iva", min: 0, max: 100 })

		if (data.product_terms.length === 0) newErrors.product_terms = t("error.at_least_one")

		data.product_terms.forEach((item, index) => {
			validations.product_terms_items({ value: item.term, min: 1, index })
		})

		setErrors(newErrors)
		return newErrors
	}

	const handleSubmit = async () => {
		const errors = validateInputs()
		if (Object.keys(errors).length > 0) return null

		const body = {
			...data,
			hitch_approved: data.hitch_approved / 100,
			cat: data.cat / 100,
			initial_payment: data.initial_payment / 100,
			interest_rate: data.interest_rate / 100,
			iva: data.iva / 100,
			lender: lender_id,
		}
		if (page.is.edit) await ProductModel.put(tokenAccess, body)
		else await ProductModel.post(tokenAccess, body)
		handleGoBack()
	}

	return (
		<div className={classes.products__form}>
			<button className={classes.products__form__go_back_button} onClick={handleGoBack}>
				<ArrowBackIcon /> {t("edit.button.go_back")}
			</button>
			<div className={classes.products__form__content}>
				<h1 className={classes.products__form__title}>
					{page.is.edit ? t("edit.title.edit") : t("edit.title.new")}
				</h1>
				<Loading loading={loading.isVisible}>
					<div className={classes.products__form__inputs}>
						<ProductCluster label={t("edit.input.name")}>
							<CustomTextField
								name="name"
								value={data.name}
								onChange={handleChange}
								errorText={errors.name}
								error={errors.name}
							/>
						</ProductCluster>

						<ProductCluster label={t("edit.input.description")}>
							<TextArea
								name="description"
								value={data.description}
								onChange={handleChange}
								className={classes.products__form__textarea}
								helperText={errors.description}
								error={errors.description}
							/>
						</ProductCluster>

						<div className={classes.products__form__grouped_inputs}>
							<ProductCluster label={t("edit.input.total_approved")}>
								<CustomTextField
									name="total_approved"
									value={data.total_approved}
									onChange={handleChangeNumeric(handleChange)}
									StartIcon={CurrencyIcon}
									iconVariant="contained"
									errorText={errors.total_approved}
									error={errors.total_approved}
								/>
							</ProductCluster>
							<ProductCluster label={t("edit.input.hitch_approved")}>
								<CustomTextField
									name="hitch_approved"
									value={data.hitch_approved}
									onChange={handleChangeNumeric(handleChange)}
									EndIcon={PercentIcon}
									iconVariant="contained"
									errorText={errors.hitch_approved}
									error={errors.hitch_approved}
								/>
							</ProductCluster>
							<ProductCluster label={t("edit.input.cat")}>
								<CustomTextField
									name="cat"
									value={data.cat}
									onChange={handleChangeNumeric(handleChange)}
									EndIcon={PercentIcon}
									iconVariant="contained"
									errorText={errors.cat}
									error={errors.cat}
								/>
							</ProductCluster>
							<ProductCluster label={t("edit.input.initial_payment")}>
								<CustomTextField
									name="initial_payment"
									value={data.initial_payment}
									onChange={handleChangeNumeric(handleChange)}
									EndIcon={PercentIcon}
									iconVariant="contained"
									errorText={errors.initial_payment}
									error={errors.initial_payment}
								/>
							</ProductCluster>
							<ProductCluster label={t("edit.input.interest_rate")}>
								<CustomTextField
									name="interest_rate"
									value={data.interest_rate}
									onChange={handleChangeNumeric(handleChange)}
									EndIcon={PercentIcon}
									iconVariant="contained"
									errorText={errors.interest_rate}
									error={errors.interest_rate}
								/>
							</ProductCluster>
							<ProductCluster label={t("edit.input.iva")}>
								<CustomTextField
									name="iva"
									value={data.iva}
									onChange={handleChangeNumeric(handleChange)}
									EndIcon={PercentIcon}
									iconVariant="contained"
									errorText={errors.iva}
									error={errors.iva}
									disabled
								/>
							</ProductCluster>
						</div>
						<ProductCluster label={t("edit.input.terms")}>
							<ItemsInput
								value={data.product_terms}
								onChange={handleChangeItems}
								ItemComponent={ProductTerms}
								itemComponentProps={{ errors: errors.product_terms_items }}
								addLabel={t("edit.button.add_term")}
								defaultValue={DEFAULT_PRODUCT_TERM}
								helperText={errors.product_terms}
								error={errors.product_terms}
							/>
						</ProductCluster>
						<Button variant="contained" className={classes.products__form__submit} onClick={handleSubmit}>
							{t("edit.button.save")}
						</Button>
					</div>
				</Loading>
			</div>
		</div>
	)
}
