import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks/useShowHide"

import { MerchantModel } from "models/MerchantModel"
import { useAlertMessages } from "AlertMessageContext"

export const useStatisticsData = ({ t, range }) => {
	const { tokenAccess } = useSelector((state) => state.auth)
	const messages = useAlertMessages()
	const changeErrors = messages.errors.change

	const [data, setData] = useState({})
	const { show: showLoading, hide: hideLoading, isVisible: isLoading } = useShowHide()

	const validateCustomDateRange = range.validateCustomDateRange

	useEffect(() => {
		const fetchData = async () => {
			showLoading()
			try {
				const body = { date_filter: range.value }
				const { isValid, startDate, endDate } = validateCustomDateRange()
				if (!isValid) return hideLoading()

				if (startDate) body.start_date = startDate
				if (endDate) body.end_date = endDate
				const response = await MerchantModel.Folio.getStatistics(tokenAccess, body)
				setData(response)
			} catch (error) {
				changeErrors([t("error.message")], { title: t("error.title"), sx: { maxWidth: "20em" } })
			}
			hideLoading()
		}
		fetchData()
	}, [t, tokenAccess, showLoading, hideLoading, changeErrors, range.value, validateCustomDateRange])

	return { data, isLoading }
}
