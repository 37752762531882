import classes from "./dashboard_offers.module.scss"

import { useMemo, useCallback } from "react"

import { ChartDonut } from "charts/ChartDonut"

import { useDashboardOffersContext } from "./DashboardOffersContext"

export const DashboardOffers__ChartDonut = () => {
	const { t, statistics } = useDashboardOffersContext()

	const data = useMemo(() => {
		if (!statistics.data?.offers_by_status) return []

		return Object.entries(statistics.data?.offers_by_status).map(([status, count]) => ({ status, count }))
	}, [statistics.data])

	const labelFormatter = useCallback((status) => t(`status.${status.toLowerCase()}`), [t])

	return (
		<ChartDonut
			className={classes.donut}
			data={data}
			keyName="status"
			keyValue="count"
			labelFormatter={labelFormatter}
		/>
	)
}
