import classes from "./associated_groups.module.scss"

import { useTranslation } from "react-i18next"

import { Text } from "@arbolfinanciero/kikoya-lib-hub/controls/Text"
import { Loading } from "@arbolfinanciero/kikoya-lib-hub/controls/Loading"

import { useAssociatedGroups } from "./useAssociatedGroups"
import { useAssociatedBranches } from "./useAssociatedBranches"
import { AssociatedGroupsProvider } from "./AssociatedGroupsContext"
import { AssociateNewGroupsButton } from "./AssociateNewGroupsButton"
import { AssociatedGroupsTable } from "./AssociatedGroupsTable"

export const AssociatedGroups = ({ wid }) => {
	const { t } = useTranslation("admin", { keyPrefix: "whitelabel.associated_groups" })
	const { associatedGroups, nonAssociatedGroups, isLoading: isLoadingGroups, loadGroups } = useAssociatedGroups(wid)
	const {
		associatedBranches,
		nonAssociatedBranches,
		isLoading: isLoadingBranches,
		loadBranches,
	} = useAssociatedBranches(wid)

	const reloadData = () => {
		loadBranches()
		loadGroups()
	}

	const state = {
		t,
		wid,
		associatedGroups,
		nonAssociatedGroups,
		associatedBranches,
		nonAssociatedBranches,
		reloadData,
	}

	return (
		<AssociatedGroupsProvider state={state}>
			<section className={classes.container}>
				<div className={classes.header}>
					<Text tag="h3">{t("title")}</Text>
					<AssociateNewGroupsButton />
				</div>
				<Loading
					loading={isLoadingBranches || isLoadingGroups}
					data-testid="admin--whitelabel--groups__loading">
					<AssociatedGroupsTable />
				</Loading>
			</section>
		</AssociatedGroupsProvider>
	)
}
