import { useEffect, useCallback } from "react"
import { useSelector } from "react-redux"

import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks/useShowHide"
import { useList } from "@arbolfinanciero/kikoya-lib-hub/hooks/useList"

import { useAlertMessages } from "AlertMessageContext"

import { BranchModel } from "models/BranchModel"

export const useAssociatedBranches = (wid) => {
	const { tokenAccess } = useSelector((state) => state.auth)
	const messages = useAlertMessages()
	const changeErrors = messages.errors.change

	const { value: associatedBranches, change: changeAssociatedBranches } = useList()
	const { value: nonAssociatedBranches, change: changeNonAssociatedBranches } = useList()
	const { show: showLoading, hide: hideLoading, isVisible: isLoading } = useShowHide(true)

	const loadBranches = useCallback(async () => {
		showLoading()
		try {
			const [resultAssociated = [], resultNonAssociated  = []] = await Promise.all([
				BranchModel.Associations.get(tokenAccess, wid, { associated: true }),
				BranchModel.Associations.get(tokenAccess, wid, { associated: false }),
			])
			const _associatedBranches = {}
			const _nonAssociatedBranches = {}
			resultAssociated.forEach((branch) => {
				const restOfBranches = _associatedBranches[branch.group_id] || []
				_associatedBranches[branch.group_id] = [branch, ...restOfBranches]
			})
			resultNonAssociated.forEach((branch) => {
				const restOfBranches = _nonAssociatedBranches[branch.group_id] || []
				_nonAssociatedBranches[branch.group_id] = [branch, ...restOfBranches]
			})
			changeAssociatedBranches(_associatedBranches)
			changeNonAssociatedBranches(_nonAssociatedBranches)
		} catch (error) {
			if (error.non_field_errors) changeErrors(error.non_field_errors)
		}
		hideLoading()
	}, [
		wid,
		showLoading,
		hideLoading,
		tokenAccess,
		changeErrors,
		changeAssociatedBranches,
		changeNonAssociatedBranches,
	])

	useEffect(() => {
		loadBranches()
	}, [loadBranches])

	return { associatedBranches, nonAssociatedBranches, isLoading, loadBranches }
}
