import { useState } from "react"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import validator from "validator"

import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
// import FormControlLabel from "@mui/material/FormControlLabel"
// import Checkbox from "@mui/material/Checkbox"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"

import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"

import HeaderPrincipal from "../Home/Header"
import { Alerta } from "../Alerta"

import { login } from "redux-kikoya/auth"
import { useForm } from "hooks"
import { LoginModel } from "models/LoginModel"
import { USER_TYPES } from "enums"

export const Login = () => {
	const dispatch = useDispatch()
	const [user] = useState({})
	const [{ open, message, emoji }, setOpen] = useState({
		open: false,
		message: "",
		emoji: "",
	})
	const [errors, setErrors] = useState({})
	const [{ email, password, noCloseSession }, handleChange] = useForm({
		email: "",
		password: "",
		noCloseSession: false,
	})
	const [loading, setLoading] = useState(false)

	const handleClose = () => {
		setOpen({
			open: false,
			message: "",
			emoji: "",
		})
		dispatch(login(user))
	}

	const { t: t_models } = useTranslation("models", { keyPrefix: "general" })
	const { t: t_login } = useTranslation("login")
	const { t: t_general } = useTranslation()

	const submit = async () => {
		try {
			const data = await LoginModel.ApiKey.create(USER_TYPES.APPLICANT, email, password)
			const userHasAccess = data.user_type.id === USER_TYPES.APPLICANT
			if (!userHasAccess) return { email: t_login("error.submit_invalid_permissions.user") }

			const tokenData = await LoginModel.Token.create(data.api_key)
			const userExpired = tokenData.detail === "This user has expired"
			if (userExpired) return { email: t_login("error.submit_expired") }

			const userValid = tokenData.status === 200
			if (!userValid) return { email: t_login("error.submit_invalid") }

			dispatch(
				login({
					api_key: data.api_key,
					userName: tokenData.data.user.email,
					email: tokenData.data.user.email,
					tokenAccess: tokenData.data.access,
					tokenRefresh: tokenData.data.refresh,
					name: tokenData.data.user.name,
					userType: tokenData.data.user.user_type.id,
					noCloseSession: noCloseSession,
					url: "/user/Layout",
					id: tokenData.data.user.id,
				})
			)
		} catch (error) {
			const messages = Array.isArray(error.non_field_errors)
				? error.non_field_errors
				: [t_models("error.problem")]
			return { password: messages.join(", ") }
		}
		return {}
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		setLoading(true)
		let errors = validForm()
		if (Object.keys(errors).length === 0) errors = await submit()
		setLoading(false)
		setErrors(errors)
	}

	const validForm = () => {
		let errors = {}
		if (!validator.isEmail(email)) errors.email = t_login("error.email_format")
		if (validator.isEmpty(email)) errors.email = t_general("general.error.validator")
		if (validator.isEmpty(password)) errors.password = t_general("general.error.validator")
		return errors
	}

	const [showNewPassword, setShowNewPassword] = useState(false)

	const handleToogleLastPassword = () => {
		setShowNewPassword(!showNewPassword)
	}

	const handleMouseToogleLastPassword = (event) => {
		event.preventDefault()
	}

	const handleInputPress = (e) => {
		let error = ""
		if (e.target.name === "email") {
			if (!validator.isEmail(e.target.value)) {
				error = t_login("error.email_format")
			}
		}

		if (validator.isEmpty(e.target.value)) {
			error = t_general("general.error.validator")
		}

		setErrors({ ...errors, [e.target.name]: error })
	}

	return (
		<>
			<HeaderPrincipal />
			<div className="login__container">
				<div className="login__form" style={{ textAlign: "center" }}>
					<form onSubmit={handleSubmit}>
						<h1 className="login">{t_login("title")}</h1>
						<div>
							<div>
								<Breadcrumbs
									style={{
										paddingLeft: "25%",
									}}>
									<Typography color="textPrimary">
										{t_general("general.login.type_auth_user")}
									</Typography>
									<Link
										style={{ display: "block", margin: "8px 0" }}
										to="/auth/financier/login"
										className="forgotPass">
										<b>{t_general("general.login.type_auth_financier")}</b>
									</Link>
								</Breadcrumbs>
							</div>
							<div>
								<TextField
									className="login__input"
									label={t_login("label.email")}
									name="email"
									inputProps={{ role: "textbox", "aria-label": "email" }}
									value={email}
									onChange={handleChange}
									onKeyUp={handleInputPress}
									error={errors.email ? true : false}
									helperText={errors.email && errors.email}
									variant="outlined"
								/>
							</div>
							<div>
								<TextField
									id="standard-last-password"
									className="login__input"
									label={t_login("label.password")}
									name="password"
									inputProps={{ role: "textbox", "aria-label": "password" }}
									type={showNewPassword ? "text" : "password"}
									onChange={handleChange}
									onKeyUp={handleInputPress}
									error={errors.password ? true : false}
									helperText={errors.password && errors.password}
									variant="outlined"
									value={password}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="Mostrar contraseña actual"
													onClick={handleToogleLastPassword}
													onMouseDown={handleMouseToogleLastPassword}>
													{showNewPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</div>
						</div>
						<div className="containerRecoveryLogin">
							{/* <FormControlLabel
								value="end"
								control={
									<Checkbox
										color="primary"
										name="noCloseSession"
										value={noCloseSession}
										onChange={handleChange}
									/>
								}
								label={<span style={{ fontSize: "13px" }}>{t_login("checkbox_persist_session")}</span>}
								labelPlacement="end"
							/> */}
							<Link className="forgotPass" to="/auth/user/login/2/3">
								{t_login("link.recover_password")} <b>{t_login("link.recover")}</b>
							</Link>
						</div>
						<div style={{ textAlign: "center" }}>
							{loading ? (
								<CircularProgress style={{ width: "30px", height: "30px" }} />
							) : (
								<Button
									variant="contained"
									style={{
										width: "50%",
										padding: "15px 0",
										maxWidth: "400px",
										borderRadius: "40px",
									}}
									color="primary"
									type="submit"
									role="button"
									aria-label="submit"
									disabled={
										validator.isEmpty(password) ||
										validator.isEmpty(email) ||
										!validator.isEmail(email)
									}>
									{t_login("button.submit")}
								</Button>
							)}
						</div>
						<Link
							style={{ display: "block", margin: "8px 0" }}
							to="/auth/user/login/3"
							className="forgotPass"></Link>
					</form>
				</div>
				<div className="capaLogoImagen"></div>
				<Alerta open={open} message={message} emoji={emoji} handleClose={handleClose} />
			</div>
		</>
	)
}
