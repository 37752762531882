import "../solicitante_styles.scss"
import { useSelector } from "react-redux"

import { Offers as KikoyaOffers } from "@arbolfinanciero/kikoya-lib-hub/components/Offers"
import { getBaseURL } from "helpers"

const getFeedbackConfig = () => {
	const {
		REACT_APP_NOTIFICATION_CENTER_ENDPOINT,
		REACT_APP_NOTIFICATION_CENTER_API_KEY,
		REACT_APP_NOTIFICATION_CENTER_DOMAIN,
		REACT_APP_NOTIFICATION_CENTER_SUBDOMAIN,
	} = process.env

	return {
		endpoint: REACT_APP_NOTIFICATION_CENTER_ENDPOINT,
		api_key: REACT_APP_NOTIFICATION_CENTER_API_KEY,
		domain: REACT_APP_NOTIFICATION_CENTER_DOMAIN,
		subdomain: REACT_APP_NOTIFICATION_CENTER_SUBDOMAIN,
	}
}

export const Offers = () => {
	const { id: user_id, userType: user_type_id, tokenAccess, email, name } = useSelector((store) => store.auth)

	return (
		<KikoyaOffers
			endpoint={getBaseURL()}
			applicantId={user_id}
			sessionData={{ user_id, user_type_id, tokenAccess, name, email }}
			realTimeEnabled
			feedbackConfig={getFeedbackConfig()}
		/>
	)
}
