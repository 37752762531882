import { Route, Navigate, Routes } from "react-router-dom"

import AuthUser from "components/user/Auth"
import { ChangePass } from "components/user/RecoveryPass/ChangePass"
import { Privacidad } from "components/user/Privacidad/Privacidad"

export const AuthUserRouter = () => (
	<Routes>
		<Route path="/login/:step?/:user_type_id?" element={<AuthUser />} />
		<Route path="/change_password/:hash" element={<ChangePass />} />
		<Route path="/privacy/:type" element={<Privacidad />} />
		<Route path="*" element={<Navigate to="/auth/user/login" replace />} />
	</Routes>
)
