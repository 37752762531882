import { Flex } from "@arbolfinanciero/kikoya-lib-hub/controls/Flex"
import { Skeleton } from "@arbolfinanciero/kikoya-lib-hub/controls/Skeleton"

export const DashboardChartSkeleton = ({ children, show = false }) => {
	if (!show) return children

	const sx = { maxWidth: "35em", maxHeight: "25em" }
	const dataTestId = "financier--dashboard__statistics--skeleton"
	return (
		<Flex h100 w100 gap="1em" alignItems="flex-end" sx={sx} data-testid={dataTestId}>
			{["100%", "60%", "70%", "80%", "90%", "60%", "40%"].map((height, index) => (
				<Skeleton key={index} show sx={{ flexGrow: 1 }} height={height} />
			))}
		</Flex>
	)
}
