import { Routes, Navigate, Route } from "react-router-dom"

import { Layout as FinancierLayout } from "components/financiero/Layout"
import { Profile } from "components/financiero/Profile"
import { AddEdit as FinancieroForm } from "components/financiero/Configuracion/FinancierUser/AddEdit"
import { ChangePassword as ChangePassFinancier } from "components/user/Login/ChangePassword"
import { FinancierUser } from "components/financiero/Configuracion/FinancierUser/FinancierUser"
import { Configuracion as FinancierSettings } from "components/financiero/Configuracion/Configuracion"
import { Terminos as TermsAndConditions } from "components/financiero/Configuracion/Terminos/Terminos"
import { Cookie } from "components/financiero/Configuracion/Cookies/Cookie"
import { Privacy } from "components/financiero/Configuracion/Privacidad/Privacy"
import { OffersFinancier } from "components/Ecosystem/Financier/Carrousel/OffersFinancier"
import { DashboardOffers } from "components/financiero/Dashboard/DashboardOffers"
import { DashboardClients } from "components/financiero/Dashboard/DashboardClients"
import { Branding } from "components/financiero/Configuracion/Branding/Branding"

export const FinancierRouter = () => (
	<Routes>
		<Route path="/Layout" element={<FinancierLayout />} />
		<Route path="/profile/user/:user_id" element={<Profile />} />
		<Route path="/Financiero/create" element={<FinancieroForm />} />
		<Route path="/Financiero/edit/:id" element={<FinancieroForm />} />
		<Route path="/User" element={<FinancierUser />} />
		<Route path="/ChangePassword" element={<ChangePassFinancier />} />
		<Route path="/Settings" element={<FinancierSettings />} />
		<Route path="/Terms" element={<TermsAndConditions />} />
		<Route path="/Privacy" element={<Privacy />} />
		<Route path="/Cookies" element={<Cookie />} />
		<Route path="/Branding" element={<Branding />} />
		<Route path="/Offers/:user_id" element={<OffersFinancier />} />
		<Route path="/dashboard/applications" element={<DashboardOffers />} />
		<Route path="/dashboard/clients" element={<DashboardClients />} />
		<Route path="*" element={<Navigate to="/financier/Layout" replace />} />
	</Routes>
)
