import classes from "./styles/products.module.scss"

import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { Edit as EditIcon, FileCopy as FileCopyIcon } from "@mui/icons-material"
import { ProductsContext } from "./ProductsContext"

const currencyFormat = (num) => "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")

const ProductLabel = ({ children }) => <p className={classes.products__list__item__label}>{children}</p>

const ProductCluster = ({ children }) => <div className={classes.products__list__item__cluster}>{children}</div>

const ProductText = ({ children, large, small, bold }) => {
	const classNames = [classes.products__list__item__text]
	if (bold) classNames.push(classes["products__list__item__text--bold"])
	if (large) classNames.push(classes["products__list__item__text--large"])
	else if (small) classNames.push(classes["products__list__item__text--small"])
	else classNames.push(classes["products__list__item__text--medium"])
	return <p className={classNames.join(" ")}>{children}</p>
}

export const ProductsListItem = ({ item }) => {
	const { t } = useTranslation("admin", { keyPrefix: "products" })
	const { page } = useContext(ProductsContext)

	const handleClone = () => page.setClone(item.id)
	const handleEdit = () => page.setEdit(item.id)

	const productTerms = item.product_terms.map((product_term) => product_term.term)
	return (
		<div className={classes.products__list__item}>
			<div className={classes.products__list__item__main}>
				<ProductCluster>
					<ProductLabel>{t("show.item.name")}</ProductLabel>
					<ProductText large>{item.name}</ProductText>
				</ProductCluster>
				<ProductText small>{item.description}</ProductText>
			</div>

			<div className={classes.products__list__item__secondary}>
				<ProductCluster>
					<ProductLabel>{t("show.item.id")}</ProductLabel>
					<ProductText large>{item.id}</ProductText>
				</ProductCluster>
			</div>

			<div className={classes.products__list__item__details}>
				<ProductCluster>
					<ProductLabel>{t("show.item.total_approved")}</ProductLabel>
					<ProductText bold>{currencyFormat(item.total_approved)}</ProductText>
				</ProductCluster>
				<ProductCluster>
					<ProductLabel>{t("show.item.hitch_approved")}</ProductLabel>
					<ProductText bold>{Math.round(item.hitch_approved * 100)}%</ProductText>
				</ProductCluster>
				<ProductCluster>
					<ProductLabel>{t("show.item.cat")}</ProductLabel>
					<ProductText bold>{Math.round(item.cat * 100)}%</ProductText>
				</ProductCluster>
				<ProductCluster>
					<ProductLabel>{t("show.item.initial_payment")}</ProductLabel>
					<ProductText bold>{Math.round(item.initial_payment * 100)}%</ProductText>
				</ProductCluster>
				<ProductCluster>
					<ProductLabel>{t("show.item.interest_rate")}</ProductLabel>
					<ProductText bold>{Math.round(item.interest_rate * 100)}%</ProductText>
				</ProductCluster>
				<ProductCluster>
					<ProductLabel>{t("show.item.term")}</ProductLabel>
					<ProductText bold>
						{productTerms.length > 1
							? `${Math.min(...productTerms)} - ${Math.max(...productTerms)}`
							: productTerms[0]}
						&nbsp;
						{t("show.item.terms.month", { count: Math.max(...productTerms) })}
					</ProductText>
				</ProductCluster>
			</div>

			<div className={classes.products__list__item__buttons}>
				<button className={classes.products__list__item__button} onClick={handleClone}>
					{t("show.item.button.clone")} <FileCopyIcon />
				</button>
				<button className={classes.products__list__item__button} onClick={handleEdit}>
					{t("show.item.button.edit")} <EditIcon />
				</button>
			</div>
		</div>
	)
}
