import { useId } from "react"
import { useTranslation } from "react-i18next"

import { TextField } from "@arbolfinanciero/kikoya-lib-hub/controls/TextField"
import { Flex } from "@arbolfinanciero/kikoya-lib-hub/controls/Flex"
import { Text } from "@arbolfinanciero/kikoya-lib-hub/controls/Text"
import { ConfirmMessage } from "@arbolfinanciero/kikoya-lib-hub/controls/ConfirmMessage"

import { useModal } from "@arbolfinanciero/kikoya-lib-hub/hooks/useModal"

import { IOSSwitch } from "./IOSSwitch"

export const CustomTextField = ({ form, name, ...props }) => {
	const handleChange = (e) => form.change(e.target.name, e.target.value)
	const handleBlur = (e) => form.validateFormat(e.target.name)

	return (
		<TextField
			name={name}
			value={form.values[name]}
			onChange={handleChange}
			onBlur={handleBlur}
			error={form.errors[name] ? true : false}
			errorText={form.errors[name]}
			{...props}
		/>
	)
}

export const CustomCheckActivation = ({ form }) => {
	const { t: t_general } = useTranslation()
	const checkId = useId()
	const disableActivationConfirmModal = useModal()
	const enableActivationConfirmModal = useModal()

	const handleChangeCheck = (event) => {
		if (event.target.checked === false) disableActivationConfirmModal.open()
		else enableActivationConfirmModal.open()
	}
	const handleConfirmMessage = () => {
		form.change("check_activation", !form.values.check_activation)
		disableActivationConfirmModal.close()
		enableActivationConfirmModal.close()
	}

	return (
		<>
			<Flex alignItems="center">
				<IOSSwitch checked={form.values.check_activation} onChange={handleChangeCheck} id={checkId} />
				<Text tag="label" htmlFor={checkId}>
					{t_general("financierUser.update.check_active")}
				</Text>
			</Flex>
			<ConfirmMessage
				modal={disableActivationConfirmModal}
				confirmButtonText={t_general("financierUser.update.option_disabled")}
				onConfirm={handleConfirmMessage}>
				{t_general("financierUser.update.alert_enabled")}
			</ConfirmMessage>
			<ConfirmMessage
				modal={enableActivationConfirmModal}
				confirmButtonText={t_general("financierUser.update.option_enabled")}
				onConfirm={handleConfirmMessage}>
				{t_general("financierUser.update.alert_disabled")}
			</ConfirmMessage>
		</>
	)
}

export const CustomCheckApplyOffer = ({ form }) => {
	const { t: t_general } = useTranslation()
	const checkId = useId()
	const handleChangeCheck = (event) => form.change("check_apply_offer", event.target.checked)

	return (
		<Flex alignItems="center">
			<IOSSwitch checked={form.values.check_apply_offer} onChange={handleChangeCheck} id={checkId} />
			<Text tag="label" htmlFor={checkId}>
				{t_general("financierUser.create.apply_offers")}
			</Text>
		</Flex>
	)
}
