import { useEffect, useState, useCallback, createContext, useContext } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks/useShowHide"
import { AddressModel } from "models/AddressModel"
import { MerchantModel } from "models/MerchantModel"

import { useAlertMessages } from "AlertMessageContext"
import { useGroupsForm } from "./useGroupsForm"

export const GroupsContext = createContext()

export const useGroupsContext = () => useContext(GroupsContext)

export const GroupsProvider = ({ children }) => {
	const { tokenAccess } = useSelector((state) => state.auth)
	const { t } = useTranslation("admin", { keyPrefix: "groups" })
	const messages = useAlertMessages()

	const [groups, setGroups] = useState([])
	const [states, setStates] = useState([])
	const loading = useShowHide()
	const loadingForm = useShowHide()
	const form = useGroupsForm({ loadingForm, statesList: states })

	const { show: showLoading, hide: hideLoading } = loading
	const { change: changeErrors } = messages.errors

	const loadGroups = useCallback(async () => {
		showLoading()
		try {
			const response = await MerchantModel.Group.getAll(tokenAccess)
			setGroups(response)
		} catch (error) {
			changeErrors(Object.values(error))
		}
		hideLoading()
	}, [tokenAccess, showLoading, hideLoading, changeErrors])

	const loadStates = useCallback(async () => {
		showLoading()
		try {
			const response = await AddressModel.States.get(tokenAccess)
			setStates(response?.results)
		} catch (error) {
			changeErrors(Object.values(error))
		}
		hideLoading()
	}, [tokenAccess, showLoading, hideLoading, changeErrors])

	useEffect(() => {
		loadGroups()
		loadStates()
	}, [loadGroups, loadStates])

	const state = { t, groups, loadGroups, states, form, loading, loadingForm }

	return <GroupsContext.Provider value={state}>{children}</GroupsContext.Provider>
}
