import classes from "./profile.module.scss"

import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks"

import { BadgeOutlined as Identification, ArticleOutlined as Paper } from "@mui/icons-material"

import { useAlertMessages } from "AlertMessageContext"
import { FoliosModel } from "models"

export const useProfileData = () => {
	const { user_id } = useParams()
	const { tokenAccess } = useSelector((state) => state.auth)
	const [data, setData] = useState({})
	const { hide: hideLoading, show: showLoading, isVisible: loading } = useShowHide()

	const {
		errors: { change: changeErrors },
	} = useAlertMessages()

	useEffect(() => {
		const fetchData = async () => {
			showLoading()
			try {
				const response = await FoliosModel.getByUser(tokenAccess, user_id)
				setData(response)
			} catch (error) {
				changeErrors(Object.values(error))
			}
			hideLoading()
		}
		fetchData()
	}, [tokenAccess, user_id, changeErrors, showLoading, hideLoading])

	const name = data.user?.name || ""
	const paternalName = data.user?.paternal_surname || ""
	const maternalName = data.user?.maternal_surname || ""
	const fullName = [name, paternalName, maternalName].filter((v) => v).join(" ")
	const mobilePhone = data.user?.mobile_phone || ""
	const email = data.user?.email || ""
	const state = data.address?.state || ""
	const addressArray = [
		data.address?.street,
		data.address?.internal_number,
		data.address?.external_number,
		data.address?.suburb,
		data.address?.city_council,
	]
	const address = addressArray.filter((v) => v).join(", ")
	const zipCode = data.address?.zip_code
	return {
		whitelabelName: data.whitelabel,
		name,
		paternalName,
		maternalName,
		fullName,
		mobilePhone,
		state,
		email,
		address,
		zipCode,
		status: data.status_module_id,
		loading,
	}
}

export const useProfileTab = () => {
	const { t } = useTranslation("financier", { keyPrefix: "profile" })
	const [currentTab, setCurrentTab] = useState("profile")
	const tabOptions = [
		{
			value: "profile",
			content: (
				<span className={classes.tab}>
					<Identification /> {t("tab.profile")}
				</span>
			),
		},
		{
			value: "financing",
			content: (
				<span className={classes.tab}>
					<Paper /> {t("tab.financing")}
				</span>
			),
		},
	]
	return { current: currentTab, change: setCurrentTab, options: tabOptions }
}
