import { useState, useEffect, useCallback } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import theme from "theme"
import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls/Button"
import { Loading } from "@arbolfinanciero/kikoya-lib-hub/controls/Loading"

import { ReplaceLenders } from "../replace/ReplaceLenders"
import { LendersTable } from "./LendersTable"

import { useModal, useShowHide } from "hooks"

import { WhitelabelModel, LenderModel } from "models"

export const Lenders = ({ wid }) => {
	const { t } = useTranslation("admin", { keyPrefix: "whitelabel.lender" })

	const { tokenAccess } = useSelector((store) => store.auth)
	const [whitelabelLenders, setWhitelabelLenders] = useState([])
	const [lenders, setLenders] = useState([])

	const loading = useShowHide()
	const showLoading = loading.show
	const hideLoading = loading.hide
	const modal = useModal()

	const handleCloseModal = () => {
		modal.close()
		loadData()
	}

	const loadData = useCallback(async () => {
		showLoading()
		if (wid) {
			const response = await WhitelabelModel.Lender.get(tokenAccess, wid)
			setWhitelabelLenders(response)
			const responseLenders = await LenderModel.get(tokenAccess)
			setLenders(responseLenders)
		}
		hideLoading()
	}, [showLoading, hideLoading, tokenAccess, wid])

	useEffect(() => {
		loadData()
	}, [loadData])

	const themeVariables = {
		"--color--main": theme.palette.primary.main,
	}
	return (
		<div className="lenders" style={themeVariables}>
			<h2 className="lenders__title">{t("title")}</h2>
			<Loading loading={loading.isVisible}>
				<Button variant="contained" color="primary" onClick={modal.open}>
					{t("button.edit")}
				</Button>
				<LendersTable rows={whitelabelLenders} loadData={loadData} />
			</Loading>
			{modal.isOpen && (
				<ReplaceLenders
					wid={wid}
					whitelabelLenders={whitelabelLenders}
					lenders={lenders}
					modal={{
						isOpen: modal.isOpen,
						close: handleCloseModal,
					}}
				/>
			)}
		</div>
	)
}
