import classes from "./whitelabel.module.scss"

import { useTranslation } from "react-i18next"
import { useParams, useNavigate } from "react-router-dom"

import IconButton from "@mui/material/IconButton"
import ArrowBack from "@mui/icons-material/ArrowBack"

import { Text } from "@arbolfinanciero/kikoya-lib-hub/controls/Text"

import { Form } from "./Form"
import { Lenders } from "./Lenders"
import { AssociatedGroups } from "./AssociatedGroups"
import { HeaderAuth } from "../HeaderAuth/HeaderAuth"

export const Whitelabel = () => {
	const navigate = useNavigate()
	const { t } = useTranslation("admin", { keyPrefix: "whitelabel.general" })
	const { id: wid } = useParams()
	const isNew = !wid

	const handleGoBack = () => navigate("/admin/Layout/0")

	return (
		<div className={classes.container}>
			<HeaderAuth />
			<div className={classes.content}>
				<div className={classes.header}>
					<IconButton color="primary" aria-label="edit" size="large" onClick={handleGoBack}>
						<ArrowBack fontSize="inherit" />
					</IconButton>
					<Text tag="h3">{isNew ? t("title.new") : t("title.edit")}</Text>
				</div>
				<div className={classes.cluster}>
					<Form wid={wid} />
				</div>
				{!isNew && (
					<div className={classes.cluster}>
						<Lenders wid={wid} />
					</div>
				)}
				{!isNew && (
					<div className={classes.cluster}>
						<AssociatedGroups wid={wid} />
					</div>
				)}
			</div>
		</div>
	)
}
