import validator from "validator"

const REGEX_ONLY_NUMBERS = /^[0-9.]*$/
const REGEX_ONLY_INTEGERS = /^[0-9]*$/

export const handleChangeNumeric = (handleChange) => (e) => {
	if (REGEX_ONLY_NUMBERS.test(e.target.value)) handleChange(e)
}

export const handleChangeInteger = (handleChange) => (e) => {
	if (REGEX_ONLY_INTEGERS.test(e.target.value)) handleChange(e)
}

export const createFormValidations = ({ errors, data, t }) => ({
	string: ({ field, min, max }) => {
		if (!validator.isLength(data[field], { min, max })) errors[field] = t("error.length", { min, max })
	},

	numeric: ({ field, min, max }) => {
		if (validator.isEmpty(String(data[field]))) errors[field] = t("error.required")
		else if (!validator.isNumeric(String(data[field]))) errors[field] = t("error.incorrect_format")
		else if (data[field] < min || data[field] > max) errors[field] = t("error.minmax", { min, max })
	},

	product_terms_items: ({ value, min, index }) => {
		if (validator.isEmpty(String(value))) {
			if (!errors.product_terms_items) errors.product_terms_items = []
			errors.product_terms_items[index] = t("error.required")

		} else if (!validator.isInt(String(value))) {
			if (!errors.product_terms_items) errors.product_terms_items = []
			errors.product_terms_items[index] = t("error.incorrect_format")

		} else if (value < 1) {
			if (!errors.product_terms_items) errors.product_terms_items = []
			errors.product_terms_items[index] = t("error.min", { min })
		}
	},
})
