import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { CacheManager } from "utils/CacheManager"
import { UserModel } from "models/UserModel"
import { useAlertMessages } from "AlertMessageContext"

export const useHandleSubmit = ({ admin, form, loading, isAddMode, isRoleApplyOffer }) => {
	const { id } = useParams()
	const navigate = useNavigate()
	const { tokenAccess } = useSelector((state) => state.auth)
	const { t: t_general } = useTranslation()
	const messages = useAlertMessages()

	const onSubmit = async () => {
		loading.show()
		const applyOffers = isRoleApplyOffer ? form.values.check_apply_offer : true
		if (isAddMode) {
			const data = {
				host: CacheManager.branding.get().host,
				name: form.values.name,
				first_name: form.values.first_name,
				last_name: form.values.last_name,
				email: form.values.email,
				phone_number: form.values.phone_number,
				username: form.values.username,
				role_group: form.values.role_group,
				can_apply_offers: applyOffers,
				password: form.values.password,
				confirm_password: form.values.confirm_password,
			}

			try {
				await UserModel.Financier.create(tokenAccess, data)
				messages.successful.change([t_general("financierUser.create.submit_200")])
				navigate(admin ? "/admin/Layout/1" : "/financier/User")
			} catch (error) {
				messages.errors.change([
					error.non_field_errors
						? t_general("financierUser.create.submit_error")
						: t_general("financierUser.create.submit_error_max_user"),
				])
			}
		} else {
			const data = {
				host: CacheManager.branding.get().host,
				name: form.values.name,
				paternal_surname: form.values.first_name,
				maternal_surname: form.values.last_name,
				role_group_users_id: form.values.role_group,
				email: form.values.email,
				mobile_phone: parseInt(form.values.phone_number),
				username: form.values.username,
				is_active: form.values.check_activation,
				can_apply_offers: applyOffers,
				password: form.values.password,
				confirm_password: form.values.confirm_password,
			}

			try {
				await UserModel.update(tokenAccess, id, data)
				messages.successful.change([t_general("financierUser.update.submit_201")])
				navigate(admin ? "/admin/Layout/1" : "/financier/User")
			} catch {
				messages.errors.change([t_general("financierUser.update.submit_error")])
			}
		}
		loading.hide()
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (form.validate()) await onSubmit()
	}

	return handleSubmit
}
