import { useState } from "react"

const CLONE = "clone"
const LIST = "list"
const NEW = "new"
const EDIT = "edit"

export const useProductsPage = () => {
	const [page, setPage] = useState(LIST)
	const [id, setId] = useState("")

	return {
		id,
		is: {
			list: page === LIST,
			new: page === NEW,
			clone: page === CLONE,
			edit: page === EDIT,
		},
		setList: () => {
			setPage(LIST)
			setId("")
		},
		setNew: () => {
			setPage(NEW)
			setId("")
		},
		setClone: (id) => {
			setPage(CLONE)
			setId(id)
		},
		setEdit: (id) => {
			setPage(EDIT)
			setId(id)
		},
	}
}
