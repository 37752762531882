import { useParams } from "react-router-dom"

import { Login } from "./Login"
import { RecoveryPassword } from "./RecoveryPassword"

export const Auth = () => {
	const { step } = useParams()

	if (step === "2") return <RecoveryPassword />
	return <Login />
}
