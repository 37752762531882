import "./modal_change_pass.scss"

import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import validator from "validator"

import { Modal, ModalContainer, ModalCloseButton } from "@arbolfinanciero/kikoya-lib-hub/controls"
import { TextField, Button, IconButton } from "@mui/material"
import { CheckCircle, ArrowBack, Error } from "@mui/icons-material"

import { useForm, useModal } from "hooks"

import { UserModel } from "models/UserModel"

export const ChangePass = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [errors, setErrors] = useState({})
	const { hash } = useParams()
	const modal = useModal()
	const [modalConfig, setModalConfig] = useState({ message: "", success: false, icon: CheckCircle })

	const [{ password, rePassword }, handleChange] = useForm({
		password: "",
		rePassword: "",
	})

	const [disableButton, setDisableButton] = useState(true)

	const closeModal = () => {
		modal.close()
		if (modalConfig.success) navigate("/auth/user/login")
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		const errors = validForm()

		async function change_recover_pass() {
			try {
				await UserModel.Password.changeRecovery({ hash_recover: hash, password })
				openModal({
					message: t("login.user.change_password_submit_200"),
					success: true,
					icon: CheckCircle,
				})
			} catch (error) {
				openModal({
					message: error?.message || t("login.user.change_password_submit_invalid"),
					success: false,
					icon: Error,
				})
			}
		}

		if (Object.keys(errors).length === 0) {
			change_recover_pass()
		} else {
			openModal({
				message: errors.password,
				success: false,
				icon: Error,
			})
		}
	}

	const openModal = ({ message, success, icon }) => {
		setModalConfig({ message, success, icon })
		modal.open()
	}

	const validForm = () => {
		setErrors({})

		let errors = {}
		let expreg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#.$($)$-$_])[A-Za-z\d$@$!%*?&#.$($)$-$_]{8,16}$/

		if (!expreg.test(password)) errors.password = t("general.change_password.regex_strong_password")
		if (!expreg.test(rePassword)) errors.password = t("general.change_password.regex_strong_password")

		if (!validator.isEmpty(password)) {
			if (password.length < 8) {
				errors.password = "Longitud no valida"
			}
		}

		if (!validator.isEmpty(rePassword)) {
			if (rePassword.length < 8) {
				errors.password = "Longitud no valida"
			}
		}

		if (!validator.isEmpty(password) && !validator.isEmpty(rePassword)) {
			if (password === rePassword) {
				setDisableButton(false)
			} else {
				errors.password = "Las contraseñas ingresadas no coinciden"
				setDisableButton(true)
			}
		}

		setErrors(errors)

		return errors
	}

	const handleBack = () => {
		navigate("..")
	}

	return (
		<>
			<div className="registro__container">
				<div className="container_back">
					<IconButton onClick={handleBack} color="primary">
						<ArrowBack />
					</IconButton>
				</div>
				<div className="registro__form">
					<form onSubmit={handleSubmit}>
						<h1 className="titulo">{t("login.user.change_password_h1")}</h1>
						<p className="informacion_parrafo">{t("login.user.change_password_p")}</p>
						<div className="registro__containerInput">
							<TextField
								className="registro__input"
								label={t("login.user.change_password_new_password")}
								name="password"
								type="password"
								onChange={handleChange}
								onKeyUp={validForm}
								variant="outlined"
							/>
						</div>
						<div className="registro__containerInput">
							<TextField
								className="registro__input"
								label={t("login.user.change_password_repassword")}
								name="rePassword"
								type="password"
								value={rePassword}
								onChange={handleChange}
								onKeyUp={validForm}
								variant="outlined"
							/>
						</div>
						<div>
							{errors.password && <p style={{ fontSize: "12px", color: "red" }}>{errors.password}</p>}
						</div>
						<Button
							variant="contained"
							color="primary"
							style={{
								width: "50%",
								padding: "15px 0",
								maxWidth: "400px",
								borderRadius: "40px",
							}}
							onClick={handleSubmit}
							disabled={disableButton}>
							{t("login.user.change_password_button")}
						</Button>
					</form>
				</div>
				<div className="capaLogo"></div>
				<Modal open={modal.isOpen}>
					<ModalContainer className="modal__change_pass">
						<ModalCloseButton onClick={closeModal} />
						<modalConfig.icon color="primary" className="modal__change_pass__icon" />
						<h3 className="modal__change_pass__text">{modalConfig.message}</h3>
					</ModalContainer>
				</Modal>
			</div>
		</>
	)
}
