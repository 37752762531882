import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks"
import { RepresentationTypeModel } from "models"

import { useAlertMessages } from "AlertMessageContext"

export const useRepresentationTypeData = () => {
	const { tokenAccess } = useSelector((state) => state.auth)
	const [data, setData] = useState([])
	const messages = useAlertMessages()

	const { show: showLoading, hide: hideLoading, isVisible: isLoading } = useShowHide(false)
	const { change: changeErrors } = messages.errors || {}

	const loadData = useCallback(async () => {
		try {
			showLoading()
			const response = await RepresentationTypeModel.getAll(tokenAccess)
			setData(response)
		} catch (error) {
			changeErrors(Object.values(error))
		}
		hideLoading()
	}, [showLoading, tokenAccess, hideLoading, changeErrors])

	useEffect(() => {
		loadData()
	}, [loadData])

	return { isLoading, data, loadData }
}
