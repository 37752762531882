import { createContext, useMemo, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks/useShowHide"
import { useForm } from "@arbolfinanciero/kikoya-lib-hub/hooks/useForm"

import { useValidations } from "./useValidations"

import { WhitelabelModel } from "models/WhitelabelModel"
import { useAlertMessages } from "AlertMessageContext"

export const FormContext = createContext()

const INVENTORY_INFORMATION_OPTIONS = { NEW: "new", SEMI_NEW: "semi_new" }

export const FormProvider = ({ children, wid }) => {
	const navigate = useNavigate()
	const { t } = useTranslation("admin", { keyPrefix: "whitelabel.form" })
	const { tokenAccess } = useSelector((state) => state.auth)

	const isAddMode = useMemo(() => !wid, [wid])
	const messages = useAlertMessages()
	const validations = useValidations()
	const loading = useShowHide()
	const { show: showLoading, hide: hideLoading } = loading

	const form = useForm({
		inputs: {
			host: {
				initialValue: "",
				required: true,
				min: 3,
				regex: /^[a-zA-Z0-9]+(?:[-_.]{1}[a-zA-Z0-9]+)*$/,
				customRegexError: t("error.invalid"),
				onValidateFormat: validations.host,
			},
			name_company: {
				initialValue: "",
				required: true,
				regex: /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/,
				customRegexError: t("error.only_text"),
				min: 3,
			},
			no_max_financier: { initialValue: "", required: true, onlyNumbers: true, maxValue: 1000 },
			principal_email: { initialValue: "", required: true, onValidateFormat: validations.principal_email },
			rfc_company: { initialValue: "", required: true, min: 12 },
			contact_email: { initialValue: "", required: true, onValidateFormat: validations.contact_email },
			id_folio: { initialValue: "", required: true, min: 3, onValidateFormat: validations.id_folio },
			id_company: { initialValue: "" },
			inventory_information: { initialValue: [] },
		},
	})
	const setForm = form.set

	useEffect(() => {
		const fetchData = async () => {
			showLoading()
			try {
				const response = await WhitelabelModel.find(tokenAccess, { wid })
				const { manage_new_cars, manage_semi_new_cars, ...data } = response
				data.inventory_information = []
				if (manage_new_cars) data.inventory_information.push(INVENTORY_INFORMATION_OPTIONS.NEW)
				if (manage_semi_new_cars) data.inventory_information.push(INVENTORY_INFORMATION_OPTIONS.SEMI_NEW)
				setForm(data)
			} catch (error) {
				console.log(error)
			}
			hideLoading()
		}
		if (!isAddMode) fetchData()
	}, [isAddMode, wid, setForm, tokenAccess, showLoading, hideLoading])

	const submit = async () => {
		loading.show()
		try {
			const data = {
				host: form.values.host,
				display_name: form.values.name_company,
				max_financier_user: form.values.no_max_financier,
				name: form.values.name_company,
				email_principal: form.values.principal_email,
				rfc: form.values.rfc_company,
				contact_email: form.values.contact_email,
				code_folio: form.values.id_folio,
				manage_new_cars: form.values.inventory_information.includes(INVENTORY_INFORMATION_OPTIONS.NEW),
				manage_semi_new_cars: form.values.inventory_information.includes(
					INVENTORY_INFORMATION_OPTIONS.SEMI_NEW
				),
			}
			if (isAddMode) await WhitelabelModel.create(tokenAccess, data)
			else await WhitelabelModel.update(tokenAccess, wid, data)
			setTimeout(() => navigate("/admin/Layout"), 1000)
			messages.successful.change([t("message.success")])
		} catch (error) {
			if (error.code) {
				const errorKeys = {
					host: "host",
					name: "name_company",
					email_principal: "principal_email",
					rfc: "rfc_company",
					code_folio: "id_folio",
				}
				messages.errors.change([`${t(`inputs.${errorKeys[error.code]}`)}: ${error.message}`])
			} else {
				const _errors = Object.values(error)
				messages.errors.change(_errors)
			}
			loading.hide()
		}
	}

	const state = {
		isAddMode,
		loading,
		form,
		submit,
		inventoryInformationOptions: Object.values(INVENTORY_INFORMATION_OPTIONS).map((name) => ({
			value: name,
			text: t(`inputs.options.inventory_information.${name}`),
		})),
	}
	return <FormContext.Provider value={state}>{children}</FormContext.Provider>
}
