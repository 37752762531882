import classes from "./dashboard_clients.module.scss"

import { Grid } from "@arbolfinanciero/kikoya-lib-hub/controls/Grid"
import { Flex } from "@arbolfinanciero/kikoya-lib-hub/controls/Flex"

import { ChartArea } from "charts/ChartArea"

import { useDashboardClientsContext } from "./DashboardClientsContext"
import { DashboardClients__ChartMenu } from "./DashboardClients__ChartMenu"

import { DashboardChart } from "../_DashboardChart"

import { DashboardChartSkeleton } from "../_DashboardChartSkeleton"

export const DashboardClients__Chart = () => {
	const { t, range, statistics } = useDashboardClientsContext()

	return (
		<DashboardChart range={range}>
			<DashboardChartSkeleton show={statistics.isLoading}>
				<Grid
					className={classes.scroll}
					justifyItems="center"
					alignItems="center"
					gridTemplateRows="auto 1fr"
					gap="5px"
					h100
					w100>
					<Flex w100 sx={{ paddingRight: "1em" }}>
						<DashboardClients__ChartMenu />
					</Flex>
					<ChartArea
						className={classes.area}
						data={statistics.clients}
						keyName="created_at"
						keyValue="total"
						showLabelOnEmptyData
						aria-label={t("chart.area.arialabel")}
					/>
				</Grid>
			</DashboardChartSkeleton>
		</DashboardChart>
	)
}
