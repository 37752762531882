import { useState, useCallback } from "react"

export const useForm = (initialState = {}) => {
	const [values, setValues] = useState(initialState)

	const handleChange = useCallback(
		({ target }) => {
			if (target.type === "checkbox") {
				setValues((values) => ({ ...values, [target.name]: target.checked }))
			} else {
				setValues((values) => ({ ...values, [target.name]: target.value }))
			}
		},
		[setValues]
	)

	const reset = () => setValues(initialState)

	const changeValue = useCallback((value) => setValues(value), [setValues])

	const updateValue = useCallback(
		(field, value) => setValues((values) => ({ ...values, [field]: value })),
		[setValues]
	)

	return [values, handleChange, changeValue, reset, updateValue]
}
