import React, { useState, useEffect } from "react"
import "./style.scss"
import { useSelector } from "react-redux"
import { Grid, IconButton } from "@mui/material"
import { AlertaConfirmacion } from "../../../admin/Login/AlertaConfirmacion"
import { HeaderAuth } from "../../Home/HeaderAuth/HeaderAuth"
import ArrowBack from "@mui/icons-material/ArrowBack"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { TextEditor } from "@arbolfinanciero/kikoya-lib-hub/controls"

import { GeneralSettingsModel } from "models/GeneralSettingsModel"

export const Cookie = () => {
	const { tokenAccess } = useSelector((state) => state.auth)
	const navigate = useNavigate()

	const toolbar = {
		options: ["inline", "blockType", "link", "history", "list", "remove"],
		inline: { options: ["bold", "italic", "underline", "strikethrough"] },
		blockType: { options: ["Normal", "H1"] },
		list: { options: ["unordered", "ordered"] },
	}
	const [terms, setTerms] = useState({
		cookies: "",
	})

	const [{ openC, messageC, emojiC, textButton }, setOpenC] = useState({
		openC: false,
		messageC: "",
		emojiC: "",
		textButton: "",
	})

	// INTERNACIONALIZATION
	const { t: t_cookie } = useTranslation("cookies")

	const handleCloseC = () => {
		setOpenC({
			openC: false,
			messageC: "",
			emojiC: "",
			textButton: "",
		})
	}

	const handleAcceptC = () => {
		setOpenC({
			openC: false,
			messageC: "",
			emojiC: "",
			textButton: "",
		})

		navigate("/financier/Settings")
	}

	const updAlertaConfirma = (messageC, emojiC, textButton) => {
		setOpenC({
			openC: true,
			messageC,
			emojiC,
			textButton,
		})
	}

	async function fetchSaveConfig(type, config_value) {
		let validate_json = JSON.parse(config_value)
		if (validate_json.blocks[0].text === "") {
			updAlertaConfirma(t_cookie("general.error.validator"), "⚠️", t_cookie("message_button"))
		} else {
			try {
				await GeneralSettingsModel.update(tokenAccess, { config_value, type })
				updAlertaConfirma(t_cookie("submit_update_add"), "✅", t_cookie("message_button"))
			} catch {
				updAlertaConfirma(t_cookie("submit_error"), "⚠️", t_cookie("message_button"))
			}
		}
	}

	const handleCookies = (newValue) => {
		setTerms({ cookies: newValue })
	}

	const handleOnSaveCookies = () => {
		fetchSaveConfig("cookies", terms.cookies)
	}

	useEffect(() => {
		async function fetchWhiteLabelConfig() {
			try {
				const white = await GeneralSettingsModel.get(tokenAccess)
				setTerms({
					cookies: white.filter((w) => w.type_configurations.id === 13)[0].config_value,
				})
			} catch (error) {
				console.error(error)
			}
		}
		fetchWhiteLabelConfig()
	}, [tokenAccess])

	return (
		<>
			<HeaderAuth />
			<br />
			<Grid container spacing={1}>
				<Grid item xs={1} md={1} sm={1}></Grid>
				<Grid item xs={10} md={10} sm={10}>
					<IconButton color="primary" aria-label="edit" size="large" onClick={() => navigate(-1)}>
						<ArrowBack fontSize="inherit" />
					</IconButton>
				</Grid>
			</Grid>
			<Grid container spacing={1}>
				<Grid item xs={1} md={1} sm={1}></Grid>
				<Grid item xs={10} md={10} sm={10}>
					<div className="terminos__form">
						<div className="terminos_contInput">
							<p className="terminos__labelInput">{t_cookie("p_terms")}</p>
							<TextEditor
								wrapperClassName="terminos__input"
								toolbar={toolbar}
								value={terms.cookies}
								onChange={handleCookies}
								onSave={handleOnSaveCookies}
							/>
						</div>
					</div>
				</Grid>
				<Grid item xs={1} md={1} sm={1}></Grid>
			</Grid>
			<AlertaConfirmacion
				openC={openC}
				messageC={messageC}
				emojiC={emojiC}
				textButton={textButton}
				handleCloseC={handleCloseC}
				handleAcceptC={handleAcceptC}
			/>
		</>
	)
}
