import classes from "./form.module.scss"

import { useContext } from "react"
import { useTranslation } from "react-i18next"

import { TextField } from "@arbolfinanciero/kikoya-lib-hub/controls/TextField"
import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls/Button"
import { Text } from "@arbolfinanciero/kikoya-lib-hub/controls/Text"
import { Loading } from "@arbolfinanciero/kikoya-lib-hub/controls/Loading"
import { SelectList } from "@arbolfinanciero/kikoya-lib-hub/controls/SelectList"

import { FormContext, FormProvider } from "./FormContext"

const FormContent = () => {
	const { t } = useTranslation("admin", { keyPrefix: "whitelabel.form" })
	const { isAddMode, loading, form, submit } = useContext(FormContext)
	const { inventoryInformationOptions } = useContext(FormContext)

	const onSubmit = (e) => {
		e.preventDefault()
		if (form.validate()) submit()
	}

	const handleChange = (e) => form.change(e.target.name, e.target.value)
	const handleBlur = (e) => form.validateFormat(e.target.name)

	const createInputProps = (name, props = {}) => {
		const { ...restProps } = props
		return {
			label: t(`inputs.${name}`),
			name,
			value: form.values[name],
			error: form.errors[name] ? true : false,
			errorText: form.errors[name],
			"aria-label": t(`arialabel.inputs.${name}`),
			...restProps,
		}
	}
	const inputs = [
		createInputProps("host"),
		createInputProps("id_company", { disabled: true }),
		createInputProps("name_company"),
		createInputProps("principal_email"),
		createInputProps("contact_email"),
		createInputProps("rfc_company", { style: { textTransform: "uppercase" } }),
		createInputProps("no_max_financier"),
		createInputProps("id_folio"),
	]

	return (
		<form className={classes.form} onSubmit={onSubmit} onReset={form.reset}>
			<Loading loading={loading.isVisible} data-testid="admin--whitelabel--form__loading">
				<Text tag="h4" className={classes.form__title}>
					{t("label.add_data")}
				</Text>
				<div className={classes.form__inputs}>
					{inputs.map((e, i) => (
						<TextField key={i} onChange={handleChange} onBlur={handleBlur} type="text" {...e} />
					))}
				</div>
				<Text tag="h3">{t("label.inventory_information")}</Text>
				<SelectList
					name="inventory_information"
					aria-label={t("arialabel.inputs.inventory_information")}
					value={form.values.inventory_information}
					onChange={(value) => form.change("inventory_information", value)}
					options={inventoryInformationOptions}
				/>
				<Button
					className={classes.form__button}
					type="submit"
					color="primary"
					variant="contained"
					disabled={!form.isValid || loading.isVisible}>
					{isAddMode ? t("button.save") : t("button.update")}
				</Button>
			</Loading>
		</form>
	)
}

export const Form = ({ wid }) => {
	return (
		<FormProvider wid={wid}>
			<FormContent />
		</FormProvider>
	)
}
