import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { Modal, ModalCloseButton, ModalContainer } from "@arbolfinanciero/kikoya-lib-hub/controls/Modal"
import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls/Button"
import { TextField } from "@arbolfinanciero/kikoya-lib-hub/controls/TextField"
import { Loading } from "@arbolfinanciero/kikoya-lib-hub/controls/Loading"
import { useForm } from "@arbolfinanciero/kikoya-lib-hub/hooks/useForm"

import { useShowHide } from "hooks"
import { WhitelabelModel } from "models"
import { useAlertMessages } from "AlertMessageContext"

export const EditLenders = ({ modal, lender, handleSubmit }) => {
	const { t } = useTranslation("admin", { keyPrefix: "whitelabel.lender" })
	const { tokenAccess } = useSelector((store) => store.auth)
	const messages = useAlertMessages()

	const form = useForm({inputs: {
		product_id: {initialValue: lender.product_id || "", onlyNumbers: true, min: 3, max: 25, required: true}
	}})
	const loading = useShowHide()

	const onChange = (e) => form.change("product_id", e.target.value)
	const onBlur = () => form.validateFormat("product_id")

	const onSubmit = async (e) => {
		e.preventDefault()
		if (form.validate()) {
			loading.show()
			try {
				await WhitelabelModel.Lender.put(tokenAccess, {
					id: lender.id,
					product_id: form.values.product_id,
				})
				modal.close()
				handleSubmit()
			} catch (errors) {
				loading.hide()
				messages.errors.change(errors.non_field_errors || [])
			}
		}
	}

	return (
		<Modal open={modal.isOpen} onClose={modal.close}>
			<ModalContainer tag="form" onSubmit={onSubmit}>
				<ModalCloseButton onClick={modal.close} />
				<h2 className="lenders__edit__title">{t("edit.title")}</h2>
				<Loading loading={loading.isVisible}>
					<TextField
						name="product_id"
						value={form.values.product_id}
						onChange={onChange}
						onBlur={onBlur}
						label={t("edit.input.product_id")}
						error={form.errors.product_id ? true : false}
						errorText={form.errors.product_id}
					/>
					<Button variant="contained" color="primary" type="submit">
						{t("button.save")}
					</Button>
				</Loading>
			</ModalContainer>
		</Modal>
	)
}
