import { useState } from "react"
import { useTranslation } from "react-i18next"

import { Link } from "react-router-dom"
import { Button, Typography, Menu, MenuItem, ListItemIcon } from "@mui/material"

import { FeedOutlined, ManageAccountsOutlined, SettingsOutlined, ExpandMore } from "@mui/icons-material"
import { useStyles } from "./useStyles"

export const AdminButton = () => {
	const { t: t_general } = useTranslation()
	const classes = useStyles()

	const [anchorEl, setAnchorEl] = useState()
	const menu = {
		anchorEl,
		open: (e) => setAnchorEl(e.currentTarget),
		close: () => setAnchorEl(),
		isOpen: Boolean(anchorEl),
	}

	return (
		<div>
			<Button aria-controls="simple-menu" aria-haspopup="true" onClick={menu.open}>
				<Typography variant="h6" className={classes.title} style={{ color: "var(--app-colorMenu)" }}>
					{t_general("login.user.menu.admin")} <ExpandMore />
				</Typography>
			</Button>
			<Menu id="simple-menu" anchorEl={menu.anchorEl} keepMounted open={menu.isOpen} onClose={menu.close}>
				<MenuItem className={classes.menuItem}>
					<Link to={"/financier/User"} className={classes.link}>
						{t_general("financierUser.layout.menu_users")}
						<ListItemIcon className={classes.menuIcon}>
							<ManageAccountsOutlined fontSize="small" />
						</ListItemIcon>
					</Link>
				</MenuItem>
				<MenuItem className={classes.menuItem}>
					<Link to={"/financier/Settings"} className={classes.link}>
						{t_general("financierUser.layout.menu_settings")}
						<ListItemIcon className={classes.menuIcon}>
							<SettingsOutlined fontSize="small" />
						</ListItemIcon>
					</Link>
				</MenuItem>
				<MenuItem className={classes.menuItem}>
					<Link to={"/financier/Layout"} className={classes.link}>
						{t_general("financierUser.layout.menu_folio")}
						<ListItemIcon className={classes.menuIcon}>
							<FeedOutlined fontSize="small" />
						</ListItemIcon>
					</Link>
				</MenuItem>
			</Menu>
		</div>
	)
}
