import { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"

import makeStyles from "@mui/styles/makeStyles"
import EditIcon from "@mui/icons-material/Edit"

import { CustomDataGrid } from "@arbolfinanciero/kikoya-lib-hub/controls/CustomDataGrid"
import { Loading } from "@arbolfinanciero/kikoya-lib-hub/controls/Loading"
import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks/useShowHide"

import { UserModel } from "models/UserModel"

const useStyles = makeStyles(() => ({
	container: {
		display: "grid",
		gridTemplateRows: "auto 1fr",
		gap: "1em",
		height: "100%",
		overflow: "hidden",
	},
	button: {
		background: "#FFFFFF",
		width: "100%",
		padding: "7px 0",
		maxWidth: "12em",
		borderRadius: "40px",
		borderRight: "2px solid var(--app-primaryColor)",
		borderLeft: "2px solid var(--app-primaryColor)",
		borderBottom: "2px solid var(--app-primaryColor)",
		borderTop: "2px solid var(--app-primaryColor)",
		textTransform: "capitalize",
		color: "black",
		justifySelf: "end",
	},
}))

const EditableUserButton = (params) => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const onClick = (e) => {
		e.stopPropagation()
		navigate("/admin/Financiero/edit/" + params.id)
	}

	return (
		<IconButton
			onClick={onClick}
			color="primary"
			aria-label={t("financierUser.index.col_actions_edit_button_place")}
			size="small">
			<EditIcon fontSize="small" />
		</IconButton>
	)
}

export const TabUsers = () => {
	const { t } = useTranslation()
	const { tokenAccess } = useSelector((state) => state.auth)
	const [user, setUser] = useState([])
	const { hide: hideLoading, show: showLoading, isVisible: isLoading } = useShowHide()
	const styles = useStyles()

	const columnUsers = [
		{ name: "id", text: t("financierUser.index.col_id"), size: "2fr" },
		{ name: "name", text: t("financierUser.index.col_name"), size: "2fr" },
		{ name: "firstName", text: t("financierUser.index.col_first_name"), size: "2fr" },
		{ name: "secondName", text: t("financierUser.index.col_second_name"), size: "2fr" },
		{ name: "email", text: t("financierUser.index.col_email"), size: "2fr" },
		{ name: "phone", text: t("financierUser.index.col_mobile_phone"), size: "2fr" },
		{ name: "role", text: t("financierUser.index.col_role"), size: "2fr" },
		{
			name: "actions",
			text: t("financierUser.index.col_actions"),
			size: "2fr",
			render: EditableUserButton,
		},
	]

	const getTablesIndex = useCallback(async () => {
		showLoading()
		try {
			const users = await UserModel.Admin.get(tokenAccess)
			const arrayUsers = users.map((u) => ({
				id: u.pk,
				name: u.name,
				firstName: u.paternal_surname,
				secondName: u.maternal_surname,
				email: u.email,
				phone: u.mobile_phone,
				role: u.role_group_users.description,
				actions: "",
			}))
			setUser(arrayUsers)
		} catch (error) {
			console.error(error)
		}
		hideLoading()
	}, [tokenAccess, showLoading, hideLoading])

	useEffect(() => {
		try {
			getTablesIndex()
		} catch (error) {
			console.log(error)
		}
	}, [getTablesIndex])

	return (
		<Loading loading={isLoading}>
			<Grid className={styles.container} data-testid="admin--users">
				<Button
					variant="contained"
					className={styles.button}
					type="submit"
					component={Link}
					to="/admin/users/create">
					{t("admin.tab.button_add_user")}
				</Button>
				<CustomDataGrid data={user} columns={columnUsers} height="100%" />
			</Grid>
		</Loading>
	)
}
