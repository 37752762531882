import React from "react"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls"
import { useStyles } from "../../../hooks/useStyle"

export const AlertaConfirmacion = ({ openC, handleCloseC, messageC, emojiC, textButton, handleAcceptC }) => {
	const classes = useStyles()

	return (
		<Dialog open={openC} onClose={handleCloseC}>
			<DialogContent className="alertaContainerConfirm">
				<span className="emoji">{emojiC}</span>
				{messageC}
			</DialogContent>
			<DialogContent className="alertaContainerConfirm">
				<Button
					variant="contained"
					type="button"
					className={classes.primary}
					onClick={handleAcceptC}
					color="primary">
					{textButton}
				</Button>
			</DialogContent>
		</Dialog>
	)
}
