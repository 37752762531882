import "./style.scss"

import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, Link } from "react-router-dom"

import { logout } from "redux-kikoya/auth"
import { AppBar, Menu, MenuItem, Button, IconButton, ListItemIcon } from "@mui/material"

import {
	ArrowBack,
	PersonAdd as PersonAddIcon,
	Dehaze,
	PersonOutlineOutlined as PersonOutlineOutlinedIcon,
	ArrowDropDownOutlined as ArrowDropDownOutlinedIcon,
	LockOpen,
	WorkOutline,
	ExitToApp,
} from "@mui/icons-material"

const useMenu = () => {
	const [anchorEl, setAnchorEl] = useState()
	const open = (event) => setAnchorEl(event.currentTarget)
	const close = () => setAnchorEl()

	return { anchorEl, open, close }
}

const useMobileMenu = () => {
	const [active, setActive] = useState(false)

	const toggle = () => setActive(!active)

	return { active, toggle }
}

const useLogout = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const handleLogout = () => {
		dispatch(logout())
		navigate("auth/admin/login")
	}
	return handleLogout
}

const MobileMenu = ({ mobileMenu }) => {
	let className = "mobile_menu__button"
	if (mobileMenu.active) className += " mobile_menu__button--active"
	return <span className={className} onClick={mobileMenu.toggle}></span>
}

export const HeaderAction = (props) => {
	const { userName } = useSelector((state) => state.auth)
	const navigate = useNavigate()

	const menu = useMenu()
	const mobileMenu = useMobileMenu()
	const handleLogout = useLogout()

	return (
		<>
			<AppBar position="sticky">
				<MobileMenu mobileMenu={mobileMenu} />
				<div className={`header__menu_principal ${mobileMenu.active ? "visible" : ""}`}>
					<div className="header__logo">
						<img className="logo" src="/imgs/logo.png" alt="logo"></img>
					</div>
					<div className="header__buttons">
						<Button
							className="header__button header__button--gap"
							startIcon={
								props.idComponent === 1 ? (
									<WorkOutline />
								) : props.idComponent === 2 ? (
									<LockOpen />
								) : props.idComponent === 3 ? (
									<PersonAddIcon />
								) : (
									<Dehaze />
								)
							}>
							{props.nameComponent}
						</Button>
						<Button
							aria-controls="simple-menu"
							aria-haspopup="true"
							onClick={menu.open}
							className="header__button"
							endIcon={<ArrowDropDownOutlinedIcon style={{ fontSize: "30px" }} />}
							startIcon={<PersonOutlineOutlinedIcon />}>
							{userName}
						</Button>
						<Menu
							id="simple-menu"
							anchorEl={menu.anchorEl}
							keepMounted
							getContentAnchorEl={null}
							anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
							transformOrigin={{ vertical: "top", horizontal: "center" }}
							open={Boolean(menu.anchorEl)}
							onClose={menu.close}>
							<MenuItem>
								<Link
									to={"/admin/ChangePassword"}
									style={{
										textDecoration: "none",
										color: "#000000DE",
									}}>
									<ListItemIcon>
										<LockOpen fontSize="small" color="primary" />
									</ListItemIcon>
                                    Cambiar Contraseña
								</Link>
							</MenuItem>
							<MenuItem onClick={handleLogout}>
								<ListItemIcon>
									<ExitToApp fontSize="small" color="primary" />
								</ListItemIcon>
                                Cerrar Sesión
							</MenuItem>
						</Menu>
					</div>
				</div>
			</AppBar>
			<div className='module_header_action__container'>
				<label htmlFor="icon-button-file">
					<IconButton
						className="module_header_action__arrow"
						onClick={() => navigate(-1)}
						color="primary"
						aria-label="upload picture"
						component="span">
						<ArrowBack />
					</IconButton>
				</label>
				<h2>{props.nameActionComponent}</h2>
				<h3>Información general</h3>
			</div>
		</>
	)
}
