import { useRef } from "react"
import { useTranslation } from "react-i18next"

import { Flex } from "@arbolfinanciero/kikoya-lib-hub/controls/Flex"
import { Menu } from "@arbolfinanciero/kikoya-lib-hub/controls/Menu"
import { IconButton } from "@arbolfinanciero/kikoya-lib-hub/controls/IconButton"
import { DownloadProgressBar } from "@arbolfinanciero/kikoya-lib-hub/controls/DownloadProgressBar"

import { useModal } from "@arbolfinanciero/kikoya-lib-hub/hooks/useModal"

import MenuIcon from "@mui/icons-material/MoreHoriz"
import DownloadingIcon from "@mui/icons-material/Downloading"

const ExportButton = () => {
	const { t } = useTranslation("financier", { keyPrefix: "dashboard" })
	return (
		<Flex justifyContent="space-between" w100>
			{t("export.button")} <DownloadingIcon />
		</Flex>
	)
}

export const DashboardChartMenu = ({ downloadController, fetchExportData }) => {
	const { t } = useTranslation("financier", { keyPrefix: "dashboard" })

	const $button = useRef()
	const menu = useModal()

	const fetchData = async () => {
		menu.close()
		if (fetchExportData) await fetchExportData()
	}

	const options = [{ children: <ExportButton />, onClick: fetchData }]

	return (
		<Flex justifyContent="flex-end" w100>
			<DownloadProgressBar
				show={downloadController.isLoading}
				onCancel={downloadController.cancel}
				cancelButtonProps={{ "aria-label": t("export.cancel_button_arialabel") }}
			/>
			{!downloadController.isLoading && (
				<IconButton ref={$button} onClick={menu.open} aria-label={t("chart.menu.button_arialabel")}>
					<MenuIcon />
				</IconButton>
			)}
			<Menu
				open={menu.isOpen}
				onClose={menu.close}
				options={options}
				anchorEl={$button.current}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				slotProps={{ paper: { sx: { minWidth: "12em" } } }}
			/>
		</Flex>
	)
}
