import classes from "./chart_bar.module.scss"

import PropTypes from "prop-types"
import { BarChart, XAxis, YAxis, ResponsiveContainer, CartesianGrid, Tooltip as TooltipRecharts } from "recharts"

import { Tooltip } from "@arbolfinanciero/kikoya-lib-hub/controls/Tooltip"
import { Text } from "@arbolfinanciero/kikoya-lib-hub/controls/Text"

const HEIGHT = 100
const BAR_SIZE = 40
const WIDTH = 40

const XAxisLabel = ({ x, y, payload, XAxisHeight, nameXFormatter, barSize }) => {
	const width = barSize + 10
	const text = nameXFormatter ? nameXFormatter(payload.value) : payload.value
	return (
		<foreignObject x={x - width / 2} y={y + 5} width={width} height={XAxisHeight}>
			<Tooltip title={text}>
				<Text
					xmlns="http://www.w3.org/1999/xhtml"
					className={classes.bar__label}
					style={{ maxHeight: XAxisHeight }}>
					{text}
				</Text>
			</Tooltip>
		</foreignObject>
	)
}

const ChartBarBase = ({
	className,
	data,
	keyName,
	barSize = BAR_SIZE,
	XAxisHeight = HEIGHT,
	YAxisWidth = WIDTH,
	CustomTooltip,
	nameXFormatter,
	nameYFormatter,
	valueFormatter,
	children,
	...props
}) => {
	const tooltipFormatter = (value, name) => {
		const formattedValue = valueFormatter ? valueFormatter(value) : value
		const formattedName = nameYFormatter ? nameYFormatter(name) : name
		return [formattedValue, formattedName]
	}
	const tooltipLabelFormatter = (name) => (nameXFormatter ? nameXFormatter(name) : name)

	return (
		<figure {...props} className={[className, classes.bar].join(" ")}>
			<ResponsiveContainer>
				<BarChart width={700} height={400} data={data} barSize={barSize}>
					<XAxis
						dataKey={keyName}
						interval={0}
						tickLine
						tickCount={data.length}
						tick={<XAxisLabel {...{ barSize, nameXFormatter, XAxisHeight }} />}
						height={XAxisHeight}
					/>
					<YAxis axisLine={false} tickLine={false} width={YAxisWidth} />
					<CartesianGrid strokeDasharray="1 1" />
					<TooltipRecharts
						cursor={{ fill: "rgba(0,0,0,0.2)" }}
						formatter={tooltipFormatter}
						labelFormatter={tooltipLabelFormatter}
						content={CustomTooltip}
					/>
					{children}
				</BarChart>
			</ResponsiveContainer>
		</figure>
	)
}

ChartBarBase.propTypes = {
	className: PropTypes.string,
	data: PropTypes.arrayOf(PropTypes.object),
	keyName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	barSize: PropTypes.number,
	XAxisHeight: PropTypes.number,
	YAxisWidth: PropTypes.number,
	CustomTooltip: PropTypes.node,
	nameXFormatter: PropTypes.func,
	nameYFormatter: PropTypes.func,
	valueFormatter: PropTypes.func,
	children: PropTypes.node,
}

export { ChartBarBase }
