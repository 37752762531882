
import { useEffect, useCallback } from "react"
import { useSelector } from "react-redux"

import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks/useShowHide"
import { useList } from "@arbolfinanciero/kikoya-lib-hub/hooks/useList"

import { useAlertMessages } from "AlertMessageContext"

import { MerchantModel } from "models/MerchantModel"

export const useAssociatedGroups = (wid) => {
	const { tokenAccess } = useSelector((state) => state.auth)
	const messages = useAlertMessages()
	const changeErrors = messages.errors.change

	const { value: associatedGroups, change: changeAssociatedGroups } = useList()
	const { value: nonAssociatedGroups, change: changeNonAssociatedGroups } = useList()
	const { show: showLoading, hide: hideLoading, isVisible: isLoading } = useShowHide(true)

	const loadGroups = useCallback(async () => {
		showLoading()
		try {
			const response = await MerchantModel.GroupAssociation.get(tokenAccess, wid)
			const { associated_groups, non_associated_groups } = response
			changeAssociatedGroups(associated_groups)
			changeNonAssociatedGroups(non_associated_groups)
		} catch (error) {
			if (error.non_field_errors) changeErrors(error.non_field_errors)
		}
		hideLoading()
	}, [showLoading, hideLoading, tokenAccess, wid, changeErrors, changeNonAssociatedGroups, changeAssociatedGroups])

	useEffect(() => {
		loadGroups()
	}, [loadGroups])

	return { associatedGroups, nonAssociatedGroups, isLoading, loadGroups }
}
