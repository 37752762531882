import classes from "./dashboard_summary.module.scss"

import { useTranslation } from "react-i18next"

import { Text } from "@arbolfinanciero/kikoya-lib-hub/controls/Text"
import { Grid } from "@arbolfinanciero/kikoya-lib-hub/controls/Grid"
import { Skeleton } from "@arbolfinanciero/kikoya-lib-hub/controls/Skeleton"

import ChartIcon from "@mui/icons-material/InsertChartOutlinedTwoTone"

import { DashboardSummaryCard } from "./DashboardSummaryCard"

export const DashboardSummaryCard__MonthlyClients = ({ value = 0, isLoading, withoutRedirectLink = false }) => {
	const { t } = useTranslation("financier", { keyPrefix: "dashboard" })
	return (
		<DashboardSummaryCard
			variant="monthly"
			Icon={ChartIcon}
			redirectTo="/financier/dashboard/clients?range=month_to_date"
			redirectToAriaLabel={t("summary_card.link.range_month_to_date.arialabel")}
			withoutRedirectLink={withoutRedirectLink}
			isLoading={isLoading}>
			<Grid gap="0.3em">
				<Skeleton show={isLoading} data-testid="financier--dashboard__summary--skeleton" dark>
					<Text className={classes.card__number}>{value}</Text>
				</Skeleton>
				<Skeleton show={isLoading} data-testid="financier--dashboard__summary--skeleton" dark>
					<Text>{t("summary_card.clients_last_month")}</Text>
				</Skeleton>
			</Grid>
		</DashboardSummaryCard>
	)
}
