import classes from "./dashboard_offers.module.scss"

import { useMemo } from "react"

import { Flex } from "@arbolfinanciero/kikoya-lib-hub/controls/Flex"
import { Text } from "@arbolfinanciero/kikoya-lib-hub/controls/Text"
import { Skeleton } from "@arbolfinanciero/kikoya-lib-hub/controls/Skeleton"

import { useDashboardOffersContext } from "./DashboardOffersContext"

import { DashboardTable } from "../_DashboardTable"

const DashboardOffers_TableTotal = () => {
	const { t, statistics } = useDashboardOffersContext()
	return (
		<Flex gap="0.8em" justifyContent="center">
			<Skeleton show={statistics.isLoading} data-testid="financier--dashboard__statistics--skeleton">
				<Text className={classes.table__total}>{t("table.total")}:</Text>
			</Skeleton>
			<Skeleton show={statistics.isLoading} data-testid="financier--dashboard__statistics--skeleton">
				<Text className={classes.table__total}>{statistics.data?.total || 0}</Text>
			</Skeleton>
		</Flex>
	)
}

export const DashboardOffers__Table = () => {
	const { t, statistics } = useDashboardOffersContext()

	const data = useMemo(() => {
		const dataObject = statistics.data?.offers_by_status || {}
		return Object.entries(dataObject).map(([status, value]) => ({
			status: t(`status.${status.toLowerCase()}`),
			value,
		}))
	}, [statistics, t])

	const columns = [
		{ name: "status" },
		{ name: "value", style: { body: { verticalAlign: "top", textAlign: "right" } } },
	]

	return (
		<DashboardTable
			data={data}
			columns={columns}
			maxTableWidth={256}
			isLoading={statistics.isLoading}
			footer={<DashboardOffers_TableTotal />}
		/>
	)
}
