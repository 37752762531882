import classes from "./associated_groups.module.scss"

import { useTranslation } from "react-i18next"

import { ModalContainer, ModalCloseButton } from "@arbolfinanciero/kikoya-lib-hub/controls/Modal"
import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls/Button"
import { Text } from "@arbolfinanciero/kikoya-lib-hub/controls/Text"
import { Loading } from "@arbolfinanciero/kikoya-lib-hub/controls/Loading"

import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks/useShowHide"

import { useAlertMessages } from "AlertMessageContext"

import { useAssociatedGroupsContext } from "./AssociatedGroupsContext"

export const AssociateNewModal = ({ onSubmit, title, children, closeModal }) => {
	const { t: t_general } = useTranslation("models", { keyPrefix: "general" })
	const messages = useAlertMessages()
	const { t, reloadData } = useAssociatedGroupsContext()

	const { show: showLoading, hide: hideLoading, isVisible: isLoading } = useShowHide()

	const handleSubmit = async () => {
		showLoading()
		try {
			const isValid = await onSubmit()
			if (isValid) {
				messages.successful.change([t_general("success.message")])
				reloadData()
				closeModal()
			}
		} catch (error) {
			if (error.non_field_errors) messages.errors.change(error.non_field_errors)
		}
		hideLoading()
	}

	return (
		<ModalContainer className={classes.modal_associate_new}>
			<ModalCloseButton onClick={closeModal} aria-label={t("modal_associate_new.button.close_arialabel")} />
			<Text tag="h3">{title}</Text>
			<Loading loading={isLoading}>
				{children}
				<div className={classes.modal_associate_new__buttons}>
					<Button variant="outlined" color="primary" onClick={closeModal}>
						{t("modal_associate_new.button.cancel")}
					</Button>
					<Button variant="contained" color="primary" onClick={handleSubmit}>
						{t("modal_associate_new.button.save")}
					</Button>
				</div>
			</Loading>
		</ModalContainer>
	)
}
