// STYLES
import "./style.scss"
import "../../../../../node_modules/font-awesome/css/font-awesome.min.css"
// REACT
import React, { useState, useEffect } from "react"
// SERVICES
import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls/Button"
import { TextField } from "@arbolfinanciero/kikoya-lib-hub/controls/TextField_deprecated"
import IconButton from "@mui/material/IconButton"
import ArrowBack from "@mui/icons-material/ArrowBack"
import { HeaderAuth } from "../../Home/HeaderAuth/HeaderAuth"
import { TabFinancierUser } from "./TabFinancierUser"
// INTERNACIONALIZATION
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

export const FinancierUser = () => {
	const navigate = useNavigate()
	const [visibleGrid, setVisibleGrid] = useState("")

	const styleGrid = {
		display: visibleGrid,
	}

	useEffect(() => {
		try {
			setVisibleGrid("none")
		} catch (error) {
			console.log(error)
		}
	}, [])

	const handleAdd = () => {
		navigate("/financier/Financiero/create")
	}

	// INTERNACIONALIZATION
	const { t } = useTranslation()

	return (
		<div className="financier__whitelabel__form">
			<HeaderAuth />
			<div className="whitelabel__form">
				<div id="grid_user_financiers" style={styleGrid} className="financier__whitelabel__form__container">
					<form className="financier__whitelabel__form__toolbar">
						<IconButton color="primary" aria-label="edit" size="large" onClick={() => navigate(-1)}>
							<ArrowBack fontSize="inherit" />
						</IconButton>
						<div className="financier__whitelabel__form__toolbar__buttons">
							<Button variant="contained" color="primary" type="submit" disabled>
								{t("financierUser.index.button_upload")}
							</Button>
							<TextField
								label={t("financierUser.index.fileInput_place")}
								name="uploadCsv"
								variant="outlined"
								disabled
								size="small"
								className="financier__whitelabel__form__toolbar__textfield"
							/>
							<Button variant="outlined" color="primary" type="submit" disabled>
								{t("financierUser.index.button_csv")}
							</Button>
							<Button variant="contained" color="primary" onClick={handleAdd}>
								{t("financierUser.index.button_add")}
							</Button>
							<TextField
								label={t("financierUser.index.filter")}
								name="email"
								variant="outlined"
								size="small"
								className="financier__whitelabel__form__toolbar__textfield"
							/>
						</div>
					</form>
					<TabFinancierUser setVisible_Grid={setVisibleGrid} />
				</div>
			</div>
		</div>
	)
}
