import classes from "./associated_groups.module.scss"

import { useMemo } from "react"
import { useSelector } from "react-redux"

import { SelectList } from "@arbolfinanciero/kikoya-lib-hub/controls/SelectList"

import { useForm } from "@arbolfinanciero/kikoya-lib-hub/hooks/useForm"

import { useAssociatedGroupsContext } from "./AssociatedGroupsContext"
import { AssociateNewModal } from "./AssociateNewModal"

import { MerchantModel } from "models/MerchantModel"

const useGroupsForm = () => {
	const { nonAssociatedGroups } = useAssociatedGroupsContext()

	const form = useForm({
		inputs: {
			groups: { initialValue: [], required: true },
		},
	})
	const options = useMemo(() => {
		return nonAssociatedGroups.map(({ pk, business_name, name }) => ({
			value: pk,
			text: business_name,
			chipText: name,
		}))
	}, [nonAssociatedGroups])

	return { form, options }
}

export const AssociateNewGroupsModal = ({ closeModal }) => {
	const { tokenAccess } = useSelector((state) => state.auth)
	const { wid, t } = useAssociatedGroupsContext()

	const { form, options } = useGroupsForm()

	const onSubmit = async () => {
		if (!form.validate()) return null
		await MerchantModel.GroupAssociation.associate(tokenAccess, wid, form.values.groups)
		return true
	}

	const handleChange = (value) => form.change("groups", value)
	const handleBlur = () => form.validateFormat("groups")

	return (
		<AssociateNewModal title={t("modal_associate_new_groups.title")} onSubmit={onSubmit} closeModal={closeModal}>
			<SelectList
				name="groups"
				className={classes.modal_associate_new__select}
				value={form.values.groups}
				onChange={handleChange}
				onBlur={handleBlur}
				options={options}
				error={form.errors.groups ? true : false}
				helperText={form.errors.groups}
				aria-label={t("inputs.groups_arialabel")}
			/>
		</AssociateNewModal>
	)
}
