import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

const languages = ["en", "es"]

const prepareResources = () => {
	const resources = {}
	try {
		const ctx = require.context("locales", true, /\w+\.json$/)
		const fileRegex = /^(?<context>\w+)\/(?<lng>\w+)\/(?<path>[\s\S]+)\.json$/
		ctx.keys().forEach((file) => {
			const regexMatch = file.match(fileRegex)
			if (!regexMatch) return null
			const { lng, path } = regexMatch.groups

			const pathArray = path.split("/")
			resources[lng] = resources[lng] || {}
			let resourcesLng = resources[lng]

			pathArray.reduce((lastResourcesLng, pathSlot, index) => {
				if (index === pathArray.length - 1) lastResourcesLng[pathSlot] = ctx(file)
				else lastResourcesLng[pathSlot] = lastResourcesLng[pathSlot] || {}
				resourcesLng = { ...resourcesLng, ...lastResourcesLng }
				return lastResourcesLng[pathSlot]
			}, resourcesLng)
		})
		return resources
	} catch {
		return resources
	}
}

const resources = prepareResources()

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		lng: "en",
		fallbackLng: "es", // use et if detected lng is not available
		saveMissing: true, // send not translated keys to endpoint
		debug: process.env.REACT_APP_DEBUG === "true",
		whitelist: languages,
		react: {
			useSuspense: false,
		},
		resources,
		interpolation: {
			escapeValue: false,
		},
	})

export default i18n
