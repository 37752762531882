import { Routes, Route, Navigate } from "react-router-dom"

import { OfferSSO } from "components/OffersSubdomain/OfferSSO"

export const OffersSubdomainRouter = () => {
	return (
		<Routes>
			<Route path="/:token" element={<OfferSSO />} />
			<Route path="*" element={<Navigate to="/no_token" replace />} />
		</Routes>
	)
}
