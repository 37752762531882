import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"

export const Alerta = ({ open, handleClose, message, emoji }) => {
	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogContent className="alertaContainer">
				<span className="emoji">{emoji}</span>
				{message}
			</DialogContent>
		</Dialog>
	)
}
