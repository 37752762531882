import { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { Link } from "react-router-dom"
import { Toolbar, IconButton, Menu, MenuItem, ListItemIcon } from "@mui/material"

import { AccountCircleOutlined, ExitToApp } from "@mui/icons-material"
import { useStyles } from "./useStyles"

import { logout } from "redux-kikoya/auth"

const AccountMenu = ({ menu }) => {
	const { t: t_general } = useTranslation()
	const classes = useStyles()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const handleLogout = () => {
		dispatch(logout())
		navigate("auth/financier/login")
	}

	return (
		<Menu
			id="menu-appbar"
			keepMounted
			anchorEl={menu.anchorEl}
			open={menu.isOpen}
			onClose={menu.close}>
			<MenuItem>
				<Link to={"/financier/ChangePassword"} className={classes.link}>
					{t_general("login.user.profile_change_password")}
				</Link>
			</MenuItem>
			<MenuItem onClick={handleLogout}  className={classes.menuItem}>
				{t_general("login.user.profile_close_session")}
				<ListItemIcon className={classes.menuIcon}>
					<ExitToApp fontSize="small"/>
				</ListItemIcon>
			</MenuItem>
		</Menu>
	)
}

export const AccountButton = () => {
	const [anchorEl, setAnchorEl] = useState()
	const menu = {
		anchorEl,
		open: (e) => setAnchorEl(e.currentTarget),
		close: () => setAnchorEl(null),
		isOpen: Boolean(anchorEl),
	}

	return (
		<Toolbar>
			<IconButton
				aria-label="account of current user"
				aria-controls="menu-appbar"
				aria-haspopup="true"
				onClick={menu.open}
				color="inherit">
				<AccountCircleOutlined style={{ color: "var(--app-colorMenu)" }}/>
			</IconButton>
			<AccountMenu menu={menu} />
		</Toolbar>
	)
}
