import { useState } from "react"
import { useTranslation } from "react-i18next"

import makeStyles from "@mui/styles/makeStyles"

import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"

import { TabWhitelabels } from "./TabWhitelabels"
import { TabUsers } from "./TabUsers"
import { Groups } from "../Groups"
import { TabErrors } from "./TabErrors"

const createTabProps = (index) => ({
	id: `simple-tab-${index}`,
	"aria-controls": `simple-tabpanel-${index}`,
	role: "tab",
})

const useStyles = makeStyles((theme) => ({
	root: {
		display: "grid",
		gridTemplateRows: "auto 1fr",
		height: "100%",
		gap: "1em",
		padding: "2em",
		backgroundColor: theme.palette.background.paper,
		overflow: "hidden",
	},
	tab_panel: {
		height: "100%",
	},
}))

export const TabAdminLayout = ({ tab = 0 }) => {
	const { t } = useTranslation()
	const [value, setValue] = useState(parseInt(tab))
	const classes = useStyles()

	const handleChange = (e, newValue) => setValue(newValue)

	return (
		<div className={classes.root}>
			<Tabs
				value={value}
				onChange={handleChange}
				aria-label="Usuarios Financieros"
				indicatorColor="primary"
				textColor="inherit">
				<Tab label={t("admin.index.tab_whitelabel")} {...createTabProps(0)} />
				<Tab label={t("admin.index.tab_user")} {...createTabProps(1)} />
				<Tab label={t("admin.index.tab_groups")} {...createTabProps(2)} />
				<Tab label={t("admin.index.tab_errors")} {...createTabProps(3)} />
			</Tabs>
			{value === 0 && <TabWhitelabels />}
			{value === 1 && <TabUsers />}
			{value === 2 && <Groups />}
			{value === 3 && <TabErrors />}
		</div>
	)
}
