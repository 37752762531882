import { useMemo } from "react"

import { CustomDataGrid } from "@arbolfinanciero/kikoya-lib-hub/controls/CustomDataGrid"
import { IconButton } from "@arbolfinanciero/kikoya-lib-hub/controls/IconButton"

import RemoveIcon from "@mui/icons-material/RemoveCircleOutline"

import { useAssociatedGroupsContext } from "./AssociatedGroupsContext"

export const DeleteAssociatedBranchesModalTable = ({ deletedBranches, groupId }) => {
	const { t, associatedBranches } = useAssociatedGroupsContext()

	const currentAssociatedBranches = useMemo(() => {
		const _associatedBranches = associatedBranches[groupId] || []
		return _associatedBranches.filter(({ pk }) => !deletedBranches.values.includes(pk))
	}, [associatedBranches, groupId, deletedBranches.values])

	const columns = [
		{ name: "identifier", text: t("modal_delete_associated_branches.columns.identifier") },
		{ name: "business_name", text: t("modal_delete_associated_branches.columns.business_name") },
		{ name: "distributor", text: t("modal_delete_associated_branches.columns.distributor") },
		{
			name: "actions",
			size: "4em",
			render: (data) => (
				<IconButton
					onClick={() => deletedBranches.delete(data.pk)}
					aria-label={t("modal_delete_associated_branches.button.remove_branch_arialabel")}>
					<RemoveIcon />
				</IconButton>
			),
		},
	]
	return (
		<CustomDataGrid
			data={currentAssociatedBranches}
			columns={columns}
			rowsByPage={10}
			defaultOrderBy="identifier"
		/>
	)
}
