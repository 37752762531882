import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"

import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks"
import { MerchantModel } from "models"

import { useAlertMessages } from "AlertMessageContext"

export const useBranches = () => {
	const { t } = useTranslation("models", { keyPrefix: "general" })
	const { tokenAccess } = useSelector((state) => state.auth)
	const { gid } = useParams()
	const [data, setData] = useState([])
	const [groupName, setGroupName] = useState("")
	const messages = useAlertMessages()

	const { show: showLoading, hide: hideLoading, isVisible: isLoading } = useShowHide(true)
	const { change: changeErrors } = messages.errors || {}

	const loadData = useCallback(async () => {
		try {
			showLoading()
			const response = await MerchantModel.Branch.get(tokenAccess, gid)
			setData(response)
		} catch (error) {
			changeErrors(Object.values(error))
		}
		hideLoading()
	}, [showLoading, tokenAccess, gid, hideLoading, changeErrors])

	const loadGroupData = useCallback(async () => {
		try {
			showLoading()
			const response = await MerchantModel.Group.get(tokenAccess, gid)
			setGroupName(response.name)
		} catch (error) {
			changeErrors(Object.values(error))
		}
		hideLoading()
	}, [showLoading, hideLoading, gid, tokenAccess, changeErrors])

	const switchBranch = async (id) => {
		try {
			await MerchantModel.Branch.switch(tokenAccess, id)
			messages.successful.change([t("success.message")])
			return true
		} catch (error) {
			changeErrors(Object.values(error))
		}
	}

	useEffect(() => {
		loadData()
		loadGroupData()
	}, [loadData, loadGroupData])

	return {
		isLoading,
		data,
		loadData,
		groupName,
		switch: switchBranch,
	}
}
