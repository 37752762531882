import axios from "axios"
import { getBaseURL } from "helpers"
import { CacheManager } from "utils/CacheManager"

import { handleCommonErrors } from "models/helpers"

const __endpoint = getBaseURL() + "/api/v1/privacy"

const get = async ({ host = CacheManager.branding.get().host, type_config }) => {
	try {
		const response = await axios.get(`${__endpoint}/${host}/${type_config}/`)
		return response.data
	} catch (error) {
		await handleCommonErrors(error)
		throw error.response?.data
	}
}

export const PrivacyModel = {
	__endpoint,
	get,
}
