import classes from "./branches.module.scss"

import {
	CustomDataGrid,
	Modal,
	ModalContainer,
	ModalCloseButton,
	Text,
	Button,
	Loading,
	IconButton,
} from "@arbolfinanciero/kikoya-lib-hub/controls"
import { BrushOutlined, ToggleOffOutlined, ToggleOnOutlined, VisibilityOutlined } from "@mui/icons-material"

import { useBranchesContext } from "./BranchesContext"
import { BranchesForm } from "./BranchesForm"
import { useModal, useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks"

const BranchesSwitchModal = ({ modal, pk, is_active }) => {
	const { t, branches } = useBranchesContext()
	const loading = useShowHide()

	const handleConfirm = async () => {
		loading.show()
		if (await branches.switch(pk)) {
			branches.loadData()
			modal.close()
		}
		loading.hide()
	}

	return (
		<Modal open={modal.isOpen}>
			<ModalContainer className={classes.switch_modal}>
				<ModalCloseButton onClick={modal.close} />
				<Loading loading={loading.isVisible}>
					<Text tag="h3" className={classes.switch_modal__text}>
						{t(`switch_modal.label.${is_active ? "deactivate" : "activate"}`)}
					</Text>
					<div className={classes.switch_modal__buttons}>
						<Button onClick={modal.close} color="primary" variant="outlined">
							{t("switch_modal.button.cancel")}
						</Button>
						<Button onClick={handleConfirm} color="primary" variant="contained">
							{t("switch_modal.button.accept")}
						</Button>
					</div>
				</Loading>
			</ModalContainer>
		</Modal>
	)
}

const BranchesFieldActions = ({ pk, is_active }) => {
	const { form } = useBranchesContext()
	const modalSwitch = useModal()
	const modalForm = useModal()

	const handleEdit = () => {
		form.startEditing(pk)
		modalForm.open()
	}
	const handleSee = () => {
		form.openReadOnly(pk)
		modalForm.open()
	}

	return (
		<div className={classes.data_grid__actions}>
			<IconButton className={classes.data_grid__icon} onClick={handleEdit}>
				<BrushOutlined />
			</IconButton>
			<IconButton className={classes.data_grid__icon} onClick={handleSee}>
				<VisibilityOutlined />
			</IconButton>
			<IconButton className={classes.data_grid__icon} onClick={modalSwitch.open}>
				{!is_active ? <ToggleOffOutlined /> : <ToggleOnOutlined />}
			</IconButton>
			<BranchesSwitchModal modal={modalSwitch} pk={pk} is_active={is_active} />
			<BranchesForm modal={modalForm} />
		</div>
	)
}

export const BranchesDataGrid = ({ data }) => {
	const { t } = useBranchesContext()
	const columns = [
		{ name: "identifier", text: t("column.identifier") },
		{ name: "business_name", text: t("column.business_name") },
		{ name: "distributor", text: t("column.distributor") },
		{ name: "actions", text: t("column.actions"), render: BranchesFieldActions },
	]

	return (
		<CustomDataGrid
			className={classes.data_grid}
			data={data}
			columns={columns}
			defaultOrderBy="identifier"
			height="100%"
		/>
	)
}
