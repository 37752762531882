import { useForm } from "@arbolfinanciero/kikoya-lib-hub/hooks/useForm"

export const useTabErrorsFilter = (initialFilterData) => {
	const inputs = {}
	Object.entries(initialFilterData).forEach(([key, value]) => {
		inputs[key] = {
			initialValue: value,
			onlyNumbers: true,
		}
	})
	const form = useForm({ inputs })
	return form
}
