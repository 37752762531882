import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import validator from "validator"

import { Grid } from "@arbolfinanciero/kikoya-lib-hub/controls/Grid"

import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks/useShowHide"
import { useForm } from "@arbolfinanciero/kikoya-lib-hub/hooks/useForm"

import { USER_TYPES } from "enums"
import { login } from "redux-kikoya/auth"
import { LoginModel } from "models/LoginModel"
import { useAlertMessages } from "AlertMessageContext"

import { LoginForm } from "./LoginForm"
import { Header } from "../Header"

export const Login = () => {
	const { t } = useTranslation("auth", { keyPrefix: "login" })
	const { t: t_models } = useTranslation("models", { keyPrefix: "general" })
	const dispatch = useDispatch()
	const loading = useShowHide()
	const messages = useAlertMessages()

	const validateEmail = (email) => {
		if (email && !validator.isEmail(email)) return t("error.email_format")
		return null
	}

	const form = useForm({
		inputs: {
			email: { required: true, onValidateFormat: validateEmail },
			password: { required: true },
		},
	})

	const handleLogin = async (e) => {
		e.preventDefault()
		loading.show()
		if (!form.validate()) return loading.hide()
		try {
			const data = await LoginModel.ApiKey.create(USER_TYPES.ADMIN, form.values.email, form.values.password)
			loading.hide()

			const userHasAccess = data.user_type.id === USER_TYPES.ADMIN
			if (!userHasAccess) return messages.errors.change([t("error.submit_invalid_permissions.admin")])

			const tokenData = await LoginModel.Token.create(data.api_key)
			const userExpired = tokenData.detail === "This user has expired"
			if (userExpired) return messages.errors.change([t("error.submit_expired")])

			const userValid = tokenData.status === 200
			if (!userValid) return messages.errors.change([t("error.submit_invalid")])

			dispatch(
				login({
					api_key: data.api_key,
					userName: tokenData.data.user.username,
					email: tokenData.data.user.email,
					tokenAccess: tokenData.data.access,
					tokenRefresh: tokenData.data.refresh,
					name: tokenData.data.user.name,
					userType: tokenData.data.user.user_type.id,
					noCloseSession: false,
					role_group_id: tokenData.data.user.role_group_users.id,
					id: tokenData.data.user.id,
				})
			)
		} catch (error) {
			const errors = Array.isArray(error.non_field_errors) ? error.non_field_errors : [t_models("error.problem")]
			messages.errors.change(errors)
			loading.hide()
		}
	}

	return (
		<Grid gridTemplateRows="auto 1fr" alignItems="center" justifyItems="center" h100>
			<Header />
			<LoginForm onSubmit={handleLogin} form={form} loading={loading} />
		</Grid>
	)
}
