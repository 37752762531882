import classes from "./add_edit.module.scss"

import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import validator from "validator"

import { TextField } from "@arbolfinanciero/kikoya-lib-hub/controls/TextField"
import { IconButton } from "@arbolfinanciero/kikoya-lib-hub/controls/IconButton"
import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls/Button"
import { Flex } from "@arbolfinanciero/kikoya-lib-hub/controls/Flex"
import { Grid } from "@arbolfinanciero/kikoya-lib-hub/controls/Grid"
import { Text } from "@arbolfinanciero/kikoya-lib-hub/controls/Text"
import { Loading } from "@arbolfinanciero/kikoya-lib-hub/controls/Loading"

import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks/useShowHide"
import { useForm } from "@arbolfinanciero/kikoya-lib-hub/hooks/useForm"

import Paper from "@mui/material/Paper"
import ArrowBack from "@mui/icons-material/ArrowBack"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"

import { HeaderAuth } from "../../HeaderAuth/HeaderAuth"

import { CacheManager } from "utils/CacheManager"
import { UserModel } from "models/UserModel"
import { useAlertMessages } from "AlertMessageContext"

const regExpWords = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/
const regPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#.$($)$-$_])[A-Za-z\d$@$!%*?&#.$($)$-$_]{8,16}$/

const CustomTextField = ({ form, name, ...props }) => {
	const handleChange = (e) => form.change(e.target.name, e.target.value)
	const handleBlur = (e) => form.validateFormat(e.target.name)

	return (
		<TextField
			name={name}
			value={form.values[name]}
			onChange={handleChange}
			onBlur={handleBlur}
			error={form.errors[name] ? true : false}
			errorText={form.errors[name]}
			{...props}
		/>
	)
}

const AddEdit = () => {
	const navigate = useNavigate()
	const { tokenAccess } = useSelector((state) => state.auth)
	const { t: t_general } = useTranslation("admin_user")
	const messages = useAlertMessages()

	const passwordText = useShowHide(false)
	const passwordConfirmText = useShowHide(false)
	const loading = useShowHide(false)

	const customRegexError = t_general("invalid_characters")
	const form = useForm({
		inputs: {
			name: { initialValue: "", required: true, min: 3, max: 25, regex: regExpWords, customRegexError },
			first_name: { initialValue: "", required: true, min: 3, max: 25, regex: regExpWords, customRegexError },
			last_name: { initialValue: "", required: true, min: 3, max: 25, regex: regExpWords, customRegexError },
			email: {
				initialValue: "",
				required: true,
				max: 25,
				onValidateFormat: (email) => {
					if (!validator.isEmail(email)) return t_general("invalid_email")
				},
			},
			phone_number: {
				initialValue: "",
				required: true,
				onlyNumbers: true,
				min: 8,
				max: 10,
			},
			username: { initialValue: "", required: true, min: 3, max: 25, regex: regExpWords, customRegexError },
			password: {
				initialValue: "",
				required: true,
				regex: regPassword,
				customRegexError: t_general("admin.create.validate.password"),
			},
			confirm_password: {
				initialValue: "",
				required: true,
				regex: regPassword,
				customRegexError: t_general("admin.create.validate.password"),
				onValidateFormat: (confirm_password) => {
					if (confirm_password !== form.values.password)
						return t_general("admin.create.validate.confirm_password")
				},
			},
		},
	})

	const onSubmit = async (e) => {
		e.preventDefault()
		if (form.validate()) {
			loading.show()
			try {
				const data = {
					host: CacheManager.branding.get().host,
					name: form.values.name,
					first_name: form.values.first_name,
					last_name: form.values.last_name,
					email: form.values.email,
					phone_number: form.values.phone_number,
					username: form.values.username,
					password: form.values.password,
					confirm_password: form.values.confirm_password,
				}
				await UserModel.Admin.create(tokenAccess, data)
				messages.successful.change([t_general("post_created_201")])
				navigate("/admin/Layout/1")
			} catch (error) {
				messages.errors.change([error?.message || t_general("post_internal_500")])
			}
			loading.show()
		}
	}

	const handleMouse = (e) => e.preventDefault()

	return (
		<Grid justifyItems="center" w100 gridTemplateRows="auto 1fr" alignItems="start" gap="1em">
			<HeaderAuth />
			<Grid gap="1em" w100 sx={{ maxWidth: "60em" }}>
				<Flex alignItems="center">
					<IconButton color="primary" size="large" onClick={() => navigate("/admin/Layout/1")}>
						<ArrowBack fontSize="inherit" />
					</IconButton>
					<Text tag="h4">
						{t_general("label_add_user")} {t_general("label_user")}
					</Text>
				</Flex>
				<Paper className={classes.paper}>
					<Loading loading={loading.isVisible}>
						<Grid component="form" onSubmit={onSubmit} gap="1em" className={classes.form}>
							<Text tag="h5" className={classes.subtitle}>
								{t_general("he_label_user")}
							</Text>
							<Grid gap="1em" gridTemplateColumns="1fr 1fr 1fr" alignItems="start">
								<CustomTextField name="name" form={form} label={t_general("input_name")} />
								<CustomTextField name="first_name" form={form} label={t_general("input_last_name")} />
								<CustomTextField name="last_name" form={form} label={t_general("input_second_name")} />
								<CustomTextField name="email" form={form} label={t_general("input_email")} />
								<CustomTextField
									name="phone_number"
									form={form}
									label={t_general("input_phone_number")}
								/>
								<CustomTextField name="username" form={form} label={t_general("input_username")} />

								<CustomTextField
									form={form}
									name="password"
									type={passwordText.isVisible ? "text" : "password"}
									label={t_general("admin.create.password")}
									EndIcon={() => (
										<IconButton onClick={passwordText.toggle} onMouseDown={handleMouse}>
											{passwordText.isVisible ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									)}
								/>
								<CustomTextField
									form={form}
									name="confirm_password"
									type={passwordConfirmText.isVisible ? "text" : "password"}
									label={t_general("admin.create.confirm_password")}
									EndIcon={() => (
										<IconButton onClick={passwordConfirmText.toggle} onMouseDown={handleMouse}>
											{passwordConfirmText.isVisible ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									)}
								/>
							</Grid>
							<Button color="primary" variant="contained" type="submit" className={classes.button}>
								{t_general("label_add_user")}
							</Button>
						</Grid>
					</Loading>
				</Paper>
			</Grid>
		</Grid>
	)
}

export { AddEdit }
