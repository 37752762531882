import classes from "./chart_area.module.scss"

import { useTranslation } from "react-i18next"

import theme from "theme"
import PropTypes from "prop-types"
import {
	AreaChart,
	Area,
	XAxis,
	Brush,
	YAxis,
	ResponsiveContainer,
	CartesianGrid,
	Tooltip as TooltipRecharts,
} from "recharts"

import { Tooltip } from "@arbolfinanciero/kikoya-lib-hub/controls/Tooltip"
import { Text } from "@arbolfinanciero/kikoya-lib-hub/controls/Text"

const HEIGHT = 120
const X_OPTION_SIZE = 40
const WIDTH = 40

const XAxisLabel = ({ x, y, payload, XAxisHeight, nameXFormatter }) => {
	const width = X_OPTION_SIZE + 10
	const text = nameXFormatter ? nameXFormatter(payload.value) : payload.value
	return (
		<foreignObject x={x - width / 2} y={y + 5} width={width} height={XAxisHeight}>
			<Tooltip title={text}>
				<Text
					xmlns="http://www.w3.org/1999/xhtml"
					className={classes.area__label}
					style={{ maxHeight: XAxisHeight }}>
					{text}
				</Text>
			</Tooltip>
		</foreignObject>
	)
}

const ChartArea = ({
	className,
	data,
	keyName,
	keyValue,
	XAxisHeight = HEIGHT,
	YAxisWidth = WIDTH,
	CustomTooltip,
	nameXFormatter,
	nameYFormatter,
	valueFormatter,
	showLabelOnEmptyData = false,
	areaProps = {},
	...props
}) => {
	const { t } = useTranslation("charts")

	const tooltipFormatter = (value, name) => {
		const formattedValue = valueFormatter ? valueFormatter(value) : value
		const formattedName = nameYFormatter ? nameYFormatter(name) : name
		return [formattedValue, formattedName]
	}
	const tooltipLabelFormatter = (name) => (nameXFormatter ? nameXFormatter(name) : name)

	if (data.length === 0 && showLabelOnEmptyData) return <h3 className={classes.bar__label_no_data}>{t("no_data")}</h3>

	const fill = areaProps.fill || theme.palette.primary.main
	return (
		<figure {...props} className={[className, classes.area].join(" ")}>
			<ResponsiveContainer>
				<AreaChart width={700} height={400} data={data} margin={{ right: 20 }}>
					<XAxis
						dataKey={keyName}
						interval={0}
						tickLine
						tickCount={data.length}
						tick={<XAxisLabel {...{ nameXFormatter, XAxisHeight }} />}
						height={XAxisHeight}
					/>
					<YAxis axisLine={false} tickLine={false} width={YAxisWidth} />
					<CartesianGrid strokeDasharray="1 1" />
					<TooltipRecharts
						formatter={tooltipFormatter}
						labelFormatter={tooltipLabelFormatter}
						content={CustomTooltip}
					/>
					<Area type="monotone" dataKey={keyValue} fill={fill} {...areaProps} />
					<Brush dataKey={keyName} height={30} stroke={fill} />
				</AreaChart>
			</ResponsiveContainer>
		</figure>
	)
}

ChartArea.propTypes = {
	className: PropTypes.string,
	data: PropTypes.arrayOf(PropTypes.object),
	keyName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	keyValue: PropTypes.string.isRequired,
	XAxisHeight: PropTypes.number,
	YAxisWidth: PropTypes.number,
	CustomTooltip: PropTypes.node,
	nameXFormatter: PropTypes.func,
	nameYFormatter: PropTypes.func,
	valueFormatter: PropTypes.func,
	areaProps: PropTypes.object,
	showLabelOnEmptyData: PropTypes.bool,
}

export { ChartArea }
