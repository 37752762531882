const createCacheController = (keyWord, initialData) => ({
	get: () => {
		const data = localStorage.getItem(keyWord)
		if (data) return JSON.parse(data)
		return initialData
	},
	change: (newData) => localStorage.setItem(keyWord, JSON.stringify(newData)),
	remove: () => localStorage.removeItem(keyWord),
})

export const CacheManager = {
	branding: createCacheController("CACHE__BRANDING", {
		logotipo: "",
		landing: "",
		principal_button: "#fcbb13",
		secondary_button: "#fcbb13",
		icons: "",
		selection: "",
		name_financier: "",
		host: "",
		icon_image: "",
		header_color: "",
		language: "",
		color_text: "",
		color_menu: "",
	}),
	user: createCacheController("CACHE__USER"),
}
