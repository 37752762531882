import classes from "./add_edit_financier_form.module.scss"

import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { Loading } from "@arbolfinanciero/kikoya-lib-hub/controls/Loading"
import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls/Button"
import { Flex } from "@arbolfinanciero/kikoya-lib-hub/controls/Flex"
import { Grid } from "@arbolfinanciero/kikoya-lib-hub/controls/Grid"
import { Text } from "@arbolfinanciero/kikoya-lib-hub/controls/Text"
import { IconButton } from "@arbolfinanciero/kikoya-lib-hub/controls/IconButton"

import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks/useShowHide"

import Paper from "@mui/material/Paper"
import ArrowBack from "@mui/icons-material/ArrowBack"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"

import { CustomCheckActivation, CustomTextField, CustomCheckApplyOffer } from "./Inputs"
import { useUserForm } from "./useUserForm"
import { useHandleSubmit } from "./useHandleSubmit"
import { useFetchData } from "./useFetchData"

import { USER_TYPES } from "enums"

export const AddEditFinancierForm = ({ admin = false }) => {
	const navigate = useNavigate()
	const { id } = useParams()
	const { t: t_general } = useTranslation()

	const isAddMode = useMemo(() => !id, [id])

	const loading = useShowHide()
	const passwordText = useShowHide()
	const passwordConfirmText = useShowHide()

	const form = useUserForm({ isAddMode })

	const isRoleApplyOffer = useMemo(() => {
		if (isAddMode) return true

		const rolesApplyOffers = [USER_TYPES.FINANCIER, USER_TYPES.ADMIN]
		return rolesApplyOffers.includes(form.values.role_group)
	}, [isAddMode, form.values.role_group])

	const handleSubmit = useHandleSubmit({ admin, form, loading, isAddMode, isRoleApplyOffer })

	useFetchData({ isAddMode, form, loading })

	const handleGoBack = () => navigate(admin ? "/admin/Layout/1" : "/financier/User")
	const handleMouse = (e) => e.preventDefault()

	const fields = [
		{ name: "name", label: t_general("financierUser.create.name") },
		{ name: "first_name", label: t_general("financierUser.create.first_name") },
		{ name: "last_name", label: t_general("financierUser.create.second_name") },
		{ name: "email", label: t_general("financierUser.create.email") },
		{ name: "phone_number", label: t_general("financierUser.create.phone") },
		{ name: "username", label: t_general("financierUser.create.username") },
		{
			name: "password",
			label: t_general("financierUser.create.password"),
			type: passwordText.isVisible ? "text" : "password",
			EndIcon: () => (
				<IconButton onClick={passwordText.toggle} onMouseDown={handleMouse}>
					{passwordText.isVisible ? <VisibilityOff /> : <Visibility />}
				</IconButton>
			),
		},
		{
			name: "confirm_password",
			label: t_general("financierUser.create.confirm_password"),
			type: passwordConfirmText.isVisible ? "text" : "password",
			EndIcon: () => (
				<IconButton onClick={passwordConfirmText.toggle} onMouseDown={handleMouse}>
					{passwordConfirmText.isVisible ? <VisibilityOff /> : <Visibility />}
				</IconButton>
			),
		},
	]

	return (
		<Grid gap="1em" w100 className={classes.container}>
			<Flex alignItems="center" gap="1em">
				<IconButton color="primary" size="large" onClick={handleGoBack}>
					<ArrowBack fontSize="inherit" />
				</IconButton>
				<Text tag="h3">
					{isAddMode
						? t_general("financierUser.create.h5_add_financier")
						: t_general("financierUser.update.h5_update_financier")}
				</Text>
			</Flex>
			<Paper className={classes.paper}>
				<Loading loading={loading.isVisible}>
					<Grid component="form" className={classes.form} onSubmit={handleSubmit} gap="1em" w100>
						<Text tag="h4">{t_general("financierUser.create.form")}</Text>
						<Grid gridTemplateColumns="1fr 1fr 1fr" alignItems="start" gap="1em" w100>
							{fields.map((field, index) => (
								<CustomTextField key={index} form={form} {...field} />
							))}
						</Grid>
						{!isAddMode && <CustomCheckActivation form={form} />}
						{isRoleApplyOffer && !admin && <CustomCheckApplyOffer form={form} />}
						<Button variant="contained" color="primary" className={classes.button} type="submit">
							{!isAddMode
								? t_general("financierUser.create.button_update")
								: t_general("financierUser.create.button_add")}
						</Button>
					</Grid>
				</Loading>
			</Paper>
		</Grid>
	)
}
