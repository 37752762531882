import "./style.scss"
import { useState } from "react"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
// import FormControlLabel from "@mui/material/FormControlLabel"
// import Checkbox from "@mui/material/Checkbox"
import CircularProgress from "@mui/material/CircularProgress"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Typography from "@mui/material/Typography"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"

import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"

import validator from "validator"
import { login } from "redux-kikoya/auth"
import { LoginModel } from "models/LoginModel"
import { USER_TYPES } from "enums"

import { HeaderPrincipal } from "../Home/Header/HeaderPrincipal"
import { useForm } from "../../../hooks/useForm"

export const Login = () => {
	const dispatch = useDispatch()

	const [loading, setLoading] = useState(false)
	const [errors, setErrors] = useState({})
	const [{ email, password, noCloseSession }, handleChange] = useForm({
		email: "",
		password: "",
		noCloseSession: false,
	})

	const { t: t_models } = useTranslation("models", { keyPrefix: "general" })
	const { t: t_login } = useTranslation("login")
	const { t: t_general } = useTranslation()

	const [showNewPassword, setShowNewPassword] = useState(false)

	const handleToogleLastPassword = () => {
		setShowNewPassword(!showNewPassword)
	}

	const handleMouseToogleLastPassword = (event) => {
		event.preventDefault()
	}

	const submit = async () => {
		try {
			const data = await LoginModel.ApiKey.create(USER_TYPES.FINANCIER, email, password)
			const userHasAccess = data.user_type.id === USER_TYPES.FINANCIER || data.user_type.id === USER_TYPES.ADMIN
			if (!userHasAccess) return { email: t_login("error.submit_invalid_permissions.financier") }

			const tokenData = await LoginModel.Token.create(data.api_key)
			const userExpired = tokenData.detail === "This user has expired"
			if (userExpired) return { email: t_login("error.submit_expired") }

			const userValid = tokenData.status === 200
			if (!userValid) return { email: t_login("error.submit_invalid") }
			dispatch(
				login({
					api_key: data.api_key,
					userName: tokenData.data.user.username,
					email: tokenData.data.user.email,
					tokenAccess: tokenData.data.access,
					tokenRefresh: tokenData.data.refresh,
					name: tokenData.data.user.name,
					userType: tokenData.data.user.user_type.id,
					noCloseSession: noCloseSession,
					role_group_id: tokenData.data.user.role_group_users.id,
					id: tokenData.data.user.id,
				})
			)
		} catch (error) {
			const messages = Array.isArray(error.non_field_errors)
				? error.non_field_errors
				: [t_models("error.problem")]
			return { password: messages.join(", ") }
		}
		return {}
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		setLoading(true)
		let errors = _validateInputs()
		if (Object.keys(errors).length === 0) errors = await submit()
		setLoading(false)
		setErrors(errors)
	}

	const _validateInputs = () => {
		let errors = {}
		errors.email = _validateEmail(email)
		errors.password = _validatePassword(password)
		Object.keys(errors).forEach((key) => {
			if (!errors[key]) delete errors[key]
		})
		return errors
	}

	const _validateEmail = (value) => {
		if (validator.isEmpty(value)) return t_general("general.error.validator")
		if (!validator.isEmail(value)) return t_login("error.email_format")
	}

	const _validatePassword = (value) => {
		if (validator.isEmpty(value)) return t_general("general.error.validator")
	}

	const handleInputPress = (e) => {
		const { name, value } = e.target
		const validations = { email: _validateEmail, password: _validatePassword }
		if (validations[name]) {
			const error = validations[name](value)
			setErrors({ ...errors, [name]: error })
		}
	}

	return (
		<>
			<HeaderPrincipal />
			<div className="login__container">
				<div className="login__form">
					<form onSubmit={handleSubmit}>
						<h1 className="login">{t_login("title")}</h1>
						<div>
							<div>
								<Breadcrumbs style={{ paddingLeft: "25%" }}>
									<Typography color="textPrimary">
										{t_general("general.login.type_auth_financier")}
									</Typography>
									<Link
										style={{ display: "block", margin: "8px 0" }}
										to="/auth/user/login"
										className="forgotPass">
										<b>{t_general("general.login.type_auth_user")}</b>
									</Link>
								</Breadcrumbs>
							</div>
							<div>
								<TextField
									className="login__input"
									label={t_login("label.email")}
									name="email"
									inputProps={{ role: "textbox", "aria-label": "email" }}
									value={email}
									onChange={handleChange}
									onKeyUp={handleInputPress}
									error={errors.email ? true : false}
									helperText={errors.email && errors.email}
									variant="outlined"
								/>
							</div>
							<div>
								<TextField
									id="standard-last-password"
									className="login__input"
									label={t_login("label.password")}
									name="password"
									inputProps={{ role: "textbox", "aria-label": "password" }}
									type={showNewPassword ? "text" : "password"}
									value={password}
									onChange={handleChange}
									onKeyUp={handleInputPress}
									error={errors.password ? true : false}
									helperText={errors.password && errors.password}
									variant="outlined"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="Mostrar contraseña actual"
													onClick={handleToogleLastPassword}
													onMouseDown={handleMouseToogleLastPassword}>
													{showNewPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</div>
						</div>
						<div className="containerRecoveryLogin">
							{/* <FormControlLabel
								value="end"
								control={
									<Checkbox
										color="primary"
										name="noCloseSession"
										value={noCloseSession}
										onChange={handleChange}
									/>
								}
								label={<span style={{ fontSize: "13px" }}>{t_login("checkbox_persist_session")}</span>}
								labelPlacement="end"
							/> */}
							<Link className="forgotPass" to="/auth/user/login/2/2">
								{t_login("link.recover_password")} <b>{t_login("link.recover")}</b>
							</Link>
						</div>
						<div style={{ textAlign: "center" }}>
							{loading ? (
								<CircularProgress style={{ width: "30px", height: "30px" }} />
							) : (
								<Button
									variant="contained"
									style={{
										width: "50%",
										padding: "15px 0",
										maxWidth: "400px",
										borderRadius: "40px",
									}}
									color="primary"
									type="submit"
									role="button"
									aria-label="submit"
									disabled={
										validator.isEmpty(password) ||
										validator.isEmpty(email) ||
										!validator.isEmail(email)
									}>
									{t_login("button.submit")}
								</Button>
							)}
						</div>
					</form>
				</div>
				<div className="capaLogoImagen"></div>
			</div>
		</>
	)
}
