import { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { useForm } from "@arbolfinanciero/kikoya-lib-hub/hooks/useForm"

import { MerchantModel } from "models/MerchantModel"
import { useAlertMessages } from "AlertMessageContext"

const REGEX_TEXT = /^[a-zA-ZÀ-ú0-9-,._/ ]+$/
const INVENTORY_INFORMATION_OPTIONS = { NEW: "new", SEMI_NEW: "semi_new" }

export const useGroupsForm = ({ loadingForm, statesList }) => {
	const { t: t_general } = useTranslation("models", { keyPrefix: "general" })
	const { t } = useTranslation("admin", { keyPrefix: "groups" })
	const { tokenAccess } = useSelector((state) => state.auth)
	const messages = useAlertMessages()

	const customRegexError = t("error.invalid_characters")
	const form = useForm({
		inputs: {
			id: {},
			identifier: { required: true, min: 3, max: 20, regex: REGEX_TEXT, customRegexError },
			name: { required: true, min: 3, max: 50, regex: REGEX_TEXT, customRegexError },
			businessName: { required: true, min: 3, max: 100, regex: REGEX_TEXT, customRegexError },
			rfc: { required: true, min: 12, max: 12, regex: /^[a-zA-Z0-9]+$/, customRegexError },
			states: { initialValue: [] },
			inventory: { initialValue: [] },
		},
	})

	const [readOnly, setReadOnly] = useState(false)
	const isNew = useMemo(() => form.values.id === "", [form.values.id])

	const reset = () => {
		setReadOnly(false)
		form.reset()
	}

	const getGroup = async (_id) => {
		try {
			loadingForm.show()
			const { manage_new_cars, manage_semi_new_cars, ...response } = await MerchantModel.Group.get(
				tokenAccess,
				_id
			)
			const inventoryData = []
			if (manage_new_cars) inventoryData.push(INVENTORY_INFORMATION_OPTIONS.NEW)
			if (manage_semi_new_cars) inventoryData.push(INVENTORY_INFORMATION_OPTIONS.SEMI_NEW)

			form.set({
				id: _id,
				identifier: response?.identifier,
				name: response?.name,
				businessName: response?.business_name,
				rfc: response?.rfc,
				states: response?.states,
				inventory: inventoryData,
			})

			loadingForm.hide()
			return true
		} catch (error) {
			messages.errors.change(Object.values(error))
			loadingForm.hide()
			return false
		}
	}

	const startEditing = (_id) => {
		setReadOnly(false)
		return getGroup(_id)
	}

	const openReadOnly = (_id) => {
		setReadOnly(true)
		return getGroup(_id)
	}

	const submit = async () => {
		if (!form.validate()) return false

		loadingForm.show()
		try {
			const data = {
				id: form.values.id,
				identifier: form.values.identifier,
				name: form.values.name,
				business_name: form.values.businessName,
				rfc: form.values.rfc,
				states: form.values.states,
				manage_new_cars: form.values.inventory.includes(INVENTORY_INFORMATION_OPTIONS.NEW),
				manage_semi_new_cars: form.values.inventory.includes(INVENTORY_INFORMATION_OPTIONS.SEMI_NEW),
			}
			if (isNew) await MerchantModel.Group.create(tokenAccess, data)
			else await MerchantModel.Group.update(tokenAccess, data)
			messages.successful.change([t_general("success.message")])
			loadingForm.hide()
		} catch (error) {
			messages.errors.change(Object.values(error))
			loadingForm.hide()
			return false
		}
		return true
	}

	const selectOptions = {
		states: statesList.map(({ pk, description }) => ({ value: pk, text: description })),
		inventory: [
			{ value: INVENTORY_INFORMATION_OPTIONS.NEW, text: t("input.options.inventory.new") },
			{ value: INVENTORY_INFORMATION_OPTIONS.SEMI_NEW, text: t("input.options.inventory.semi_new") },
		],
	}

	return {
		isNew,
		selectOptions,
		readOnly,
		values: form.values,
		errors: form.errors,
		change: form.change,
		validateFormat: form.validateFormat,
		startEditing,
		openReadOnly,
		reset,
		submit,
	}
}
