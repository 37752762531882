import axios from "axios"
import { getBaseURL, extractUrl } from "helpers"

import { handleMaintenanceError } from "models/helpers"

const __endpoint = getBaseURL() + "/api/v1/landing"

const initialData = {
	logotipo: "https://portal-kikoya-staging.s3-us-west-1.amazonaws.com/images/kikoya_logo.png",
	landing: "url('https://portal-kikoya-staging.s3-us-west-1.amazonaws.com/images/Logo_Kikoya_Arbol.png')",
	principal_button: "#fcbb13",
	secondary_button: "#ffe142",
	icons: "#ffe142",
	selection: "#fcbb13",
	name_financier: "Kikoya",
	host: process.env.REACT_APP_KIKOYA_MERCHANT,
	icon_image: "https://portal-kikoya-staging.s3-us-west-1.amazonaws.com/images/default.ico",
	header_color: "#FFFFFF",
	language: "es",
	color_text: "#000000",
	color_menu: "#FFF",
}

const __transformBranding = (branding) => {
	const data = { ...initialData }
	data.host = branding.host
	data.name_financier = branding.display_name
	branding.configs.forEach((item) => {
		const id = item.type_configurations.id
		if (id === 3) data.logotipo = item.config_value
		if (id === 4) data.principal_button = item.config_value
		if (id === 10) data.landing = "url('" + item.config_value + "')"
		if (id === 15) data.secondary_button = item.config_value
		if (id === 16) data.icons = item.config_value
		if (id === 17) data.selection = item.config_value
		if (id === 18) data.icon_image = item.config_value
		if (id === 20) data.header_color = item.config_value
		if (id === 21) data.language = item.config_value
		if (id === 28) data.color_text = item.config_value
		if (id === 60) data.color_menu = item.config_value
	})
	return data
}

const get = async () => {
	try {
		const { host } = extractUrl()
		let response, currentHost
		try {
			response = await axios.get(`${__endpoint}/?host=${host}`)
			currentHost = host
		} catch {
			response = await axios.get(`${__endpoint}/?host=${process.env.REACT_APP_KIKOYA_MERCHANT}`)
			currentHost = process.env.REACT_APP_KIKOYA_MERCHANT
		}
		const data = __transformBranding({ ...response.data, host: currentHost })
		return data
	} catch (error) {
		handleMaintenanceError(error)
		throw error.response
	}
}

export const LandingModel = { __endpoint, get, __transformBranding }
