import { useParams } from "react-router-dom"
import makeStyles from "@mui/styles/makeStyles"

import { HeaderAuth } from "../HeaderAuth/HeaderAuth"
import { TabAdminLayout } from "./TabAdminLayout"

const useStyles = makeStyles(() => ({
	container: {
		display: "grid",
		gridTemplateRows: "auto 1fr",
		height: "100%"
	}
}))

export const Layout = () => {
	const { tab } = useParams()
	const styles = useStyles()

	return (
		<div className={styles.container}>
			<HeaderAuth />
			<TabAdminLayout tab={tab} />
		</div>
	)
}
