import { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls/Button"
import { Flex } from "@arbolfinanciero/kikoya-lib-hub/controls/Flex"
import { Grid } from "@arbolfinanciero/kikoya-lib-hub/controls/Grid"
import { TextField } from "@arbolfinanciero/kikoya-lib-hub/controls/TextField"
import { IconButton } from "@arbolfinanciero/kikoya-lib-hub/controls/IconButton"
import { useForm } from "@arbolfinanciero/kikoya-lib-hub/hooks/useForm"
import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks/useShowHide"

import { Visibility, VisibilityOff } from "@mui/icons-material"

import { UserModel } from "models/UserModel"
import { useAlertMessages } from "AlertMessageContext"

const CustomTextField = ({ form, name, label }) => {
	const [showPassword, setShowPassword] = useState(false)
	const handleChange = (e) => form.change(e.target.name, e.target.value)
	const handleBlur = (e) => form.validateFormat(e.target.name)
	const handleToggleShowPassword = (e) => {
		e.preventDefault()
		setShowPassword(!showPassword)
	}

	return (
		<TextField
			name={name}
			label={label}
			type={showPassword ? "text" : "password"}
			value={form.values[name]}
			onChange={handleChange}
			onBlur={handleBlur}
			error={form.errors[name] ? true : false}
			errorText={form.errors[name]}
			EndIcon={() => (
				<IconButton onClick={handleToggleShowPassword} onMouseDown={(e) => e.preventDefault()}>
					{showPassword ? <Visibility /> : <VisibilityOff />}
				</IconButton>
			)}
		/>
	)
}

export const Password = () => {
	const { tokenAccess } = useSelector((state) => state.auth)
	const navigate = useNavigate()
	const loading = useShowHide()
	const messages = useAlertMessages()

	const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#.$($)$-$_])[A-Za-z\d$@$!%*?&#.$($)$-$_]{8,16}$/
	const customRegexError = `
		Se requiere un formato de:
		1 mayúscula.
		1 minúscula,
		1 número y
		1 carácter especial con
		una longitud mínima de 8 y máxima de 16`

	const onValidateConfirmPassword = (confirmPassword) => {
		if (form.values.newPassword !== confirmPassword) return "Las contraseñas no son iguales."
	}

	const form = useForm({
		inputs: {
			lastPassword: { required: true, regex: regexPassword, max: 16, customRegexError },
			newPassword: { required: true, regex: regexPassword, max: 16, customRegexError },
			confirmNewPassword: {
				required: true,
				regex: regexPassword,
				max: 16,
				customRegexError,
				onValidate: onValidateConfirmPassword,
			},
		},
	})

	const onSubmit = async (e) => {
		e.preventDefault()
		loading.show()
		if (!form.validate()) return loading.hide()

		try {
			await UserModel.Password.reset(tokenAccess, {
				old_password: form.values.lastPassword,
				new_password: form.values.newPassword,
				confirm_new_password: form.values.confirmNewPassword,
			})
			messages.successful.change(["Se actualizó correctamente la contraseña."])
			navigate(-1)
		} catch (error) {
			messages.errors.change([
				error?.message ||
					error?.old_password ||
					error?.detail ||
					"Ocurrió un problema en la aplicación, por favor contacta al administrador",
			])
		}
		loading.hide()
	}

	return (
		<Grid
			component="form"
			onSubmit={onSubmit}
			sx={{ margin: 0, padding: 0, maxWidth: "60em" }}
			gap="1em"
			justifyItems="end">
			<Flex gap="1em" w100 justifyContent="center" alignItems="start">
				<CustomTextField name="lastPassword" label="Contraseña actual" form={form} />
				<CustomTextField name="newPassword" label="Nueva Contraseña" form={form} />
				<CustomTextField name="confirmNewPassword" label="Confirma Contraseña" form={form} />
			</Flex>
			<Button variant="contained" color="primary" type="submit">
				Actualizar
			</Button>
		</Grid>
	)
}
