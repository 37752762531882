import { useState } from "react"
import { useTranslation } from "react-i18next"

import { CustomDataGrid } from "@arbolfinanciero/kikoya-lib-hub/controls/CustomDataGrid"
import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls/Button"

import { EditLenders } from "../edit/EditLenders"

import { useModal } from "hooks"

const EditLenderButton = ({ onClick }) => {
	const { t } = useTranslation("admin", { keyPrefix: "whitelabel.lender" })
	return (
		<Button color="primary" onClick={onClick}>
			{t("button.edit")}
		</Button>
	)
}

export const LendersTable = ({ rows, loadData }) => {
	const { t } = useTranslation("admin", { keyPrefix: "whitelabel.lender" })
	const [selectedLender, setSelectedLender] = useState({})
	const modal = useModal()

	const handleEdit = (data) => {
		setSelectedLender(data)
		modal.open()
	}

	const columnId = { name: "lender_id", text: t("col.id") }
	const columnName = {
		name: "name",
		text: t("col.name"),
		render: ({ lender }) => lender.name,
	}
	const columnActions = {
		name: "actions",
		text: t("col.actions"),
		render: (data) => <EditLenderButton onClick={() => handleEdit(data)} />,
	}
	const columns = [columnName, columnId, columnActions]

	return (
		<div className="lenders__table">
			<CustomDataGrid data={rows} columns={columns} defaultOrderBy="lender_id" rowsByPage={10} />
			{modal.isOpen && <EditLenders modal={modal} lender={selectedLender} handleSubmit={loadData} />}
		</div>
	)
}
