import axios from "axios"
import { getBaseURL } from "helpers"
import { CacheManager } from "utils/CacheManager"
import { handleCommonErrors, handleCustomErrors, handleMaintenanceError } from "models/helpers/handleErrors"

const __endpoint = getBaseURL() + "/api/v1/login"
const model = "login"

const ApiKey = (() => {
	const resource = "/api_key"
	const create = async (user_type_id, email, password, host = CacheManager.branding.get().host) => {
		try {
			const bodyData = { email, password, host, user_type_id }
			const response = await axios.post(__endpoint + resource + "/", bodyData)
			return response.data
		} catch (error) {
			const response = await handleCommonErrors(error)
			await handleCustomErrors(response, model)
		}
	}
	return { resource, create }
})()

const Token = (() => {
	const resource = "/token"
	const create = async (api_key, host = CacheManager.branding.get().host) => {
		try {
			const bodyData = { api_key, host }
			return await axios.post(__endpoint + resource + "/", bodyData)
		} catch (error) {
			handleMaintenanceError(error)
			return error.response
		}
	}
	return { resource, create }
})()

export const LoginModel = { __endpoint, ApiKey, Token }
