import React from "react"
import "./style.scss"
import AccessTimeIcon from "@mui/icons-material/AccessTime"

export const Building = () => {
	return (
		<main className="building_container">
			<div className="building__tarjetasInfo">
				<div className="building__tarjetaItem">
					<AccessTimeIcon
						fontSize="large"
						color="primary"
						style={{
							opacity: "0.58"
						}}
					/>
					<p className="building__tarjetaTitulo">
						<label style={{
							opacity: "0.58"
						}}>
                            Está sección estará disponible proximamente
						</label>
					</p>
				</div>
			</div>
		</main>
	)
}
