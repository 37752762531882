export const getBaseURL = () => {
	return process.env.REACT_APP_BASE_URL
}

export const getEnvironment = () => {
	const env = process.env.REACT_APP_ENV
	const environments = {
		isProduction: env === "production",
		isStaging: env === "staging",
		isTest: env === "test",
		isDevelopment: env === "development",
		value: env,
	}
	return environments
}
