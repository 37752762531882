import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import * as Sentry from "@sentry/react"

import { AuthRouter } from "./AuthRouter"
import { AdminRouter } from "./AdminRouter"
import { FinancierRouter } from "./FinancierRouter"
import { UserRouter } from "./UserRouter"
import { NoSessionRouter } from "./NoSessionRouter"
import { OffersSubdomainRouter } from "./OffersSubdomainRouter"

import { ErrorView } from "controls"
import { ErrorBoundary } from "@arbolfinanciero/kikoya-lib-hub/controls/ErrorBoundary"
import { Loading } from "@arbolfinanciero/kikoya-lib-hub/controls/Loading"

import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks/useShowHide"

import { checkingLogin } from "redux-kikoya/auth"
import { setBranding } from "redux-kikoya/branding"
import { LandingModel } from "models"

import { SubdomainManager } from "utils/SubdomainManager"
import { extractUrl } from "helpers"
import { USER_TYPES } from "enums"

const useRouteValidations = () => {
	const { api_key, userType } = useSelector((state) => state.auth)
	return {
		isNoSession: !api_key,
		isUserSession: api_key && userType === USER_TYPES.APPLICANT,
		isFinancierSession: api_key && userType === USER_TYPES.FINANCIER,
		isAdminSession: api_key && userType === USER_TYPES.ADMIN,
	}
}

export const AppRouter = () => {
	const dispatch = useDispatch()
	const { subdomain } = extractUrl()
	const validations = useRouteValidations()
	const { hide: hideLoading, show: showLoading, isVisible: isLoading } = useShowHide(true)

	useEffect(() => {
		dispatch(checkingLogin())
		const fetchBranding = async () => {
			showLoading()
			try {
				const branding = await LandingModel.get()
				dispatch(setBranding(branding))
			} catch (error) {
				console.error(error)
			}
			hideLoading()
		}
		if (!SubdomainManager.is.offers(subdomain)) fetchBranding()
		else hideLoading()
	}, [dispatch, subdomain, showLoading, hideLoading])

	const captureError = (error) => Sentry.captureException(error)

	return (
		<Router>
			<Loading loading={isLoading}>
				<ErrorBoundary ErrorView={ErrorView} onError={captureError}>
					{SubdomainManager.is.offers(subdomain) ? (
						<Routes>
							<Route path="*" element={<OffersSubdomainRouter />} />
						</Routes>
					) : (
						<Routes>
							{validations.isUserSession && (
								<>
									<Route path="/user/*" element={<UserRouter />} />
									<Route path="*" element={<Navigate to="/user" replace />} />
								</>
							)}
							{validations.isFinancierSession && (
								<>
									<Route path="/financier/*" element={<FinancierRouter />} />
									<Route path="*" element={<Navigate to="/financier" replace />} />
								</>
							)}
							{validations.isAdminSession && (
								<>
									<Route path="/admin/*" element={<AdminRouter />} />
									<Route path="*" element={<Navigate to="/admin" replace />} />
								</>
							)}
							{validations.isNoSession && (
								<>
									<Route path="/auth/*" element={<AuthRouter />} />
									<Route path="*" element={<NoSessionRouter />} />
								</>
							)}
						</Routes>
					)}
				</ErrorBoundary>
			</Loading>
		</Router>
	)
}
