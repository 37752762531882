import { useCallback } from "react"

import { TabErrorsTable } from "./TabErrorsTable"

import { ErrorLogReplicaModel } from "models/ErrorLogReplicaModel"

const initialData = { invoice_id: "", invoice_lender_id: "", lender_id: "", whitelabel_id: "" }

export const TabErrorsInvoiceLender = () => {
	const loadData = useCallback(async (tokenAccess, data) => {
		try {
			const response = await ErrorLogReplicaModel.InvoiceLender.get(tokenAccess, data, { limit: 100 })
			return response.results
		} catch (error) {
			return []
		}
	}, [])

	return <TabErrorsTable loadData={loadData} initialData={initialData} />
}
