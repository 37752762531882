import { useCallback } from "react"
import { useTranslation } from "react-i18next"

import { addDays, differenceInDays, parse, format, subMonths, getMonth, getWeek, getYear, startOfWeek } from "date-fns"

export const useClientsStatisticsDates = ({ range }) => {
	const { t: t_months } = useTranslation("months")

	const createDatesArrayByDay = useCallback(
		({ clientsObject, startDate, days }) =>
			Array.from({ length: days }, (_, i) => {
				const created_at = format(addDays(startDate, i), range.formatDate)
				return {
					created_at,
					total: clientsObject[created_at] || 0,
				}
			}),
		[range.formatDate]
	)

	const createDatesArrayByWeek = useCallback(
		({ clientsObject, startDate, days }) => {
			const weeksObject = {}
			const weeksDatesObject = {}
			Array.from({ length: days }, (_, i) => {
				const createdAt = addDays(startDate, i)
				const createdAtFormated = format(createdAt, range.formatDate)
				const week = getWeek(createdAt)
				const year = getYear(createdAt)
				const key = `${year}---${week}`
				const currentWeekTotal = weeksObject[key] || 0
				const currentDateTotal = clientsObject[createdAtFormated] || 0
				weeksObject[key] = currentWeekTotal + currentDateTotal
				weeksDatesObject[key] = startOfWeek(createdAt)
			})
			return Object.entries(weeksObject).map(([key, total]) => {
				const weekStartDate = weeksDatesObject[key]
				const weekEndDate = addDays(weekStartDate, 6)
				const date = `${format(weekStartDate, range.formatDate)}\n${format(weekEndDate, range.formatDate)}`
				return { created_at: date, total }
			})
		},
		[range.formatDate]
	)

	const createDatesArrayByMonth = useCallback(
		({ clientsObject, startDate, days }) => {
			const monthsObject = {}
			Array.from({ length: days }, (_, i) => {
				const createdAt = addDays(startDate, i)
				const createdAtFormated = format(createdAt, range.formatDate)
				const month = getMonth(createdAt) + 1
				const year = getYear(createdAt)
				const key = `${year}---${month}`
				const currentMonthTotal = monthsObject[key] || 0
				const currentDateTotal = clientsObject[createdAtFormated] || 0
				monthsObject[key] = currentMonthTotal + currentDateTotal
			})
			return Object.entries(monthsObject).map(([key, total]) => {
				const [year, month] = key.split("---")
				return { created_at: `${t_months(`month.${month}`)} ${year}`, total }
			})
		},
		[range.formatDate, t_months]
	)

	const createDatesArray = useCallback(
		({ clientsObject, startDate, endDate }) => {
			const days = differenceInDays(endDate, startDate) + 1
			if (days <= 61) return createDatesArrayByDay({ clientsObject, startDate, days })
			if (days <= 95) return createDatesArrayByWeek({ clientsObject, startDate, days })
			return createDatesArrayByMonth({ clientsObject, startDate, days })
		},
		[createDatesArrayByDay, createDatesArrayByMonth, createDatesArrayByWeek]
	)

	const prepareClientsStatisticsDates = useCallback(
		({ clients, customStartDate, customEndDate }) => {
			const clientsObject = {}
			clients.forEach(({ created_at, total }) => (clientsObject[created_at] = total))

			if (range.is.today) return createDatesArray({ clientsObject, startDate: new Date(), endDate: new Date() })

			if (range.is.monthToDate)
				return createDatesArray({ clientsObject, startDate: subMonths(new Date(), 1), endDate: new Date() })

			if (range.is.all) {
				if (!clients[0]) return clients
				const startDate = parse(clients[0].created_at, range.formatDate, new Date())
				return createDatesArray({ clientsObject, startDate, endDate: new Date() })
			}

			if (range.is.custom) {
				const startDate = parse(customStartDate, range.formatDate, new Date())
				const endDate = parse(customEndDate, range.formatDate, new Date())
				return createDatesArray({ clientsObject, startDate, endDate })
			}

			return clients
		},
		[range.is, range.formatDate, createDatesArray]
	)
	return prepareClientsStatisticsDates
}
