import { Routes, Navigate, Route } from "react-router-dom"

import { Offer as NoSessionOffer } from "components/NoSession/Offer"

export const NoSessionRouter = () => (
	<Routes>
		<Route path="/offers/:token" element={<NoSessionOffer />} />
		<Route path="*" element={<Navigate to="/auth" replace />} />
	</Routes>
)
