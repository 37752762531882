import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { Menu } from "@arbolfinanciero/kikoya-lib-hub/controls/Menu"
import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks/useShowHide"

import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import { useStyles } from "./useStyles"

export const DashboardButton = () => {
	const $button = useRef()
	const { t: t_general } = useTranslation()
	const classes = useStyles()
	const navigate = useNavigate()
	const menu = useShowHide()

	const handleClickOffers = () => navigate("/financier/dashboard/applications")
	const handleClickClients = () => navigate("/financier/dashboard/clients")

	return (
		<>
			<Button ref={$button} onClick={menu.show}>
				<Typography variant="h6" className={classes.title} style={{ color: "var(--app-colorMenu)" }}>
					{t_general("login.user.menu.dashboard")} <ExpandMoreIcon />
				</Typography>
			</Button>
			<Menu
				open={menu.isVisible}
				anchorEl={$button.current}
				onClose={menu.hide}
				options={[
					{ children: "Solicitudes", onClick: handleClickOffers },
					{ children: "Clientes", onClick: handleClickClients },
				]}
			/>
		</>
	)
}
