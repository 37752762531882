import { createSlice } from "@reduxjs/toolkit"
import { CacheManager } from "utils/CacheManager"
import { reloadTheme } from "theme"

const _loadBranding = (branding) => {
	const { name_financier, icon_image } = branding

	document.title = name_financier + " - Market Place"

	const _favIcon = document.getElementById("favicon")
	if (_favIcon) _favIcon.href = icon_image
	reloadTheme()
}

const setBrandingAction = (_, action) => {
	document.documentElement.style.setProperty("--app-primaryColor", action.payload.principal_button)
	document.documentElement.style.setProperty("--app-landingImage", action.payload.landing)
	document.documentElement.style.setProperty("--app-iconColor", action.payload.icons)
	document.documentElement.style.setProperty("--app-headerColor", action.payload.header_color)
	document.documentElement.style.setProperty("--app-colorText", action.payload.color_text)
	document.documentElement.style.setProperty("--app-colorMenu", action.payload.color_menu)

	CacheManager.branding.change(action.payload)

	const { logotipo, landing, principal_button, secondary_button, icons, selection } = action.payload
	const {
		name_financier,
		host,
		icon_image,
		header_color = "#FFFFFF",
		language,
		color_text,
		color_menu,
	} = action.payload
	const branding = {
		logotipo,
		landing,
		principal_button,
		secondary_button,
		icons,
		selection,
		name_financier,
		host,
		icon_image,
		header_color,
		language,
		color_text,
		color_menu,
	}
	_loadBranding(branding)
	return branding
}

const initialState = CacheManager.branding.get()

const brandingSlice = createSlice({
	name: "branding",
	initialState,
	reducers: { setBranding: setBrandingAction },
})

export const { setBranding } = brandingSlice.actions
export default brandingSlice.reducer
