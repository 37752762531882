import classes from "./recovery_password.module.scss"

import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import validator from "validator"

import { Grid } from "@arbolfinanciero/kikoya-lib-hub/controls/Grid"
import { Flex } from "@arbolfinanciero/kikoya-lib-hub/controls/Flex"
import { Text } from "@arbolfinanciero/kikoya-lib-hub/controls/Text"
import { TextField } from "@arbolfinanciero/kikoya-lib-hub/controls/TextField"
import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls/Button"
import { IconButton } from "@arbolfinanciero/kikoya-lib-hub/controls/IconButton"
import { Loading } from "@arbolfinanciero/kikoya-lib-hub/controls/Loading"

import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks/useShowHide"
import { useForm } from "@arbolfinanciero/kikoya-lib-hub/hooks/useForm"

import ArrowBackIcon from "@mui/icons-material/ArrowBack"

import { UserModel } from "models/UserModel"
import { useAlertMessages } from "AlertMessageContext"

import { Header } from "../Header"

export const RecoveryPassword = () => {
	const { t } = useTranslation("auth", { keyPrefix: "recovery_password" })
	const { user_type_id } = useParams()
	const navigate = useNavigate()
	const messages = useAlertMessages()
	const loading = useShowHide()

	const validateEmail = (value) => {
		if (value && !validator.isEmail(value)) return t("error.email_format")
		return null
	}

	const form = useForm({ inputs: { email: { required: true, onValidateFormat: validateEmail } } })

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (!form.validate()) return null

		loading.show()
		try {
			await UserModel.Password.recovery({ email: form.values.email, user_type_id })
			messages.successful.change([t("label.success")])
		} catch (error) {
			const errors = Array.isArray(error?.non_field_errors) ? error?.non_field_errors : [t("label.error")]
			messages.errors.change(errors)
		}
		loading.hide()
	}

	const handleBack = () => navigate("/auth/admin/login/1")
	const handleChange = (e) => form.change(e.target.name, e.target.value)
	const handleBlur = (e) => form.validateFormat(e.target.name)

	return (
		<Grid gridTemplateRows="auto 1fr" alignItems="center" justifyItems="center" h100>
			<Header />
			<Grid component="form" className={classes.form} onSubmit={handleSubmit} gap="1em" justifyItems="center">
				<Flex alignItems="center" gap="0.5em">
					<IconButton className={classes.back_button} color="primary" onClick={handleBack}>
						<ArrowBackIcon sx={{ fontSize: "1em" }} />
					</IconButton>
					<Text tag="h1" className={classes.title}>
						{t("title")}
					</Text>
				</Flex>
				<Text className={classes.text}>{t("label.instructions")}</Text>
				<TextField
					name="email"
					label={t("label.email")}
					className={classes.input}
					value={form.values.email}
					onChange={handleChange}
					onBlur={handleBlur}
					error={form.errors.email ? true : false}
					errorText={form.errors.email}
					aria-label={t("input.email.arialabel")}
				/>
				<Loading loading={loading.isVisible}>
					<Button className={classes.button} type="submit" variant="contained" color="primary">
						{t("button.submit")}
					</Button>
				</Loading>
			</Grid>
		</Grid>
	)
}
