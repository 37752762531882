import classes from "./dashboard_clients.module.scss"

import { useMemo } from "react"

import { useDashboardClientsContext } from "./DashboardClientsContext"

import { DashboardTable } from "../_DashboardTable"

export const DashboardClients__Table = () => {
	const { t, statistics } = useDashboardClientsContext()

	const data = useMemo(
		() =>
			statistics.branches
				.map(({ branch, total }) => ({ branch: branch || t("table.no_branch"), total }))
				.reverse(),
		[t, statistics]
	)

	const columns = [
		{ name: "branch" },
		{ name: "total", style: { body: { verticalAlign: "top", textAlign: "right" } } },
	]

	return (
		<DashboardTable
			title={t("table.branches_title")}
			className={classes.table}
			data={data}
			columns={columns}
			maxTableWidth={400}
			isLoading={statistics.isLoading}
		/>
	)
}
