import axios from "axios"
import { getBaseURL } from "helpers"
import { handleCommonErrors } from "models/helpers"

const __endpoint = getBaseURL() + "/api/v1/error_log_replica"

const Lender = (() => {
	const resource = "lender"
	const get = async (token, { invoice_id, lender_id, whitelabel_id }, { limit }) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const body = { headers }
			const url = `${__endpoint}/${resource}/?invoice_id=${invoice_id}&lender_id=${lender_id}&whitelabel_id=${whitelabel_id}&limit=${limit}`
			const response = await axios.get(url, body)
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
		}
	}
	return { resource, get }
})()

const InvoiceLender = (() => {
	const resource = "invoice_lender"
	const get = async (token, { invoice_lender_id, invoice_id, lender_id, whitelabel_id }, { limit }) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const body = { headers }
			const url = `${__endpoint}/${resource}/?invoice_lender_id=${invoice_lender_id}&invoice_id=${invoice_id}&lender_id=${lender_id}&whitelabel_id=${whitelabel_id}&limit=${limit}`
			const response = await axios.get(url, body)
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
		}
	}
	return { resource, get }
})()

export const ErrorLogReplicaModel = { __endpoint, Lender, InvoiceLender }
