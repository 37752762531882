import axios from "axios"
import { getBaseURL } from "helpers"

import { handleCommonErrors } from "models/helpers"

const __endpoint = getBaseURL() + "/api/v1/general_settings"

const Branding = {
	create: async (tokenAccess, data) => {
		try {
			const headers = { Authorization: `Bearer ${tokenAccess}`, "content-type": "multipart/form-data" }
			const response = await axios.post(`${__endpoint}/branding/`, data, { headers })
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
			throw error.response.data
		}
	},
}

const get = async (tokenAccess) => {
	try {
		const headers = { Authorization: `Bearer ${tokenAccess}` }
		const body = { headers }
		const res = await axios.get(`${__endpoint}/`, body)
		const response = res.data
		return response
	} catch (error) {
		await handleCommonErrors(error)
		throw error.response.data
	}
}

const update = async (tokenAccess, { config_value, type }) => {
	try {
		const headers = { Authorization: `Bearer ${tokenAccess}` }
		const body = { config_value, type }
		const response = await axios.post(`${__endpoint}/`, body, { headers })
		return response.data
	} catch (error) {
		await handleCommonErrors(error)
		throw error.response.data
	}
}

export const GeneralSettingsModel = { __endpoint, get, update, Branding }
