import classes from "./groups.module.scss"

import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls/Button"
import { useModal } from "@arbolfinanciero/kikoya-lib-hub/hooks/useModal"

import { GroupsForm } from "./GroupsForm"
import { useGroupsContext } from "./GroupsContext"

export const GroupsNewButton = () => {
	const { t } = useGroupsContext()
	const modal = useModal()

	const handleClick = () => {
		modal.open()
	}

	return (
		<>
			<Button className={classes.new_button} color="primary" variant="contained" onClick={handleClick}>
				{t("button.new")}
			</Button>
			<GroupsForm modal={modal} />
		</>
	)
}
