import "./change_password.scss"

import { ModuleHeaderAction } from "../Home/ModuleHeaderAction/ModuleHeaderAction"
import { Password } from "../../PasswordChange/Password"

export const ChangePassword = () => {
	return (
		<div className = 'change_password'>
			<ModuleHeaderAction nameComponent="Contraseña" idComponent={5} nameActionComponent="Cambio de contraseña"></ModuleHeaderAction>
			<Password />
		</div>
	)
}
