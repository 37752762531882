import classes from "./dashboard_offers.module.scss"

import { useMemo, useCallback } from "react"

import { Grid } from "@arbolfinanciero/kikoya-lib-hub/controls/Grid"
import { Flex } from "@arbolfinanciero/kikoya-lib-hub/controls/Flex"

import { ChartMultipleBar } from "charts/ChartBar"

import { useDashboardOffersContext } from "./DashboardOffersContext"
import { DashboardOffers__ChartBarMenu } from "./DashboardOffers__ChartBarMenu"

import { DashboardChartSkeleton } from "../_DashboardChartSkeleton"

const useChartData = () => {
	const { statistics } = useDashboardOffersContext()
	const { data, lenders } = useMemo(() => {
		if (!statistics.data?.offers_by_lender) return { data: [], lenders: {} }

		const lenders = {}
		const statusData = {}
		statistics.data?.offers_by_lender.forEach(({ id, name, configs, status }) => {
			lenders[id] = { id, name, image: configs?.image, color: configs?.color }
			Object.entries(status).forEach(([statusName, count]) => {
				if (statusName === "total") return null

				const currentStatusData = statusData[statusName] || {}
				currentStatusData[id] = count
				statusData[statusName] = currentStatusData
			})
		})

		const data = Object.entries(statusData).map(([status, datum]) => ({ status, ...datum }))
		return { data, lenders }
	}, [statistics.data])

	return { data, lenders }
}

const DashboardOffers__ChartBarLegends = ({ lenders }) => (
	<Flex alignItems="center" component="ul" gap="1em" sx={{ margin: 0, padding: 0 }} wrap>
		{Object.values(lenders).map(({ name, image, color }, index) => (
			<li className={classes.legend} key={index}>
				<span className={classes.legend__color} style={{ background: color }} />
				{image && <img className={classes.legend__image} src={image} />}
				{!image && name}
			</li>
		))}
	</Flex>
)

export const DashboardOffers__ChartBar = () => {
	const { t, statistics } = useDashboardOffersContext()
	const { data, lenders } = useChartData()
	const values = useMemo(() => Object.values(lenders).map(({ id, color }) => ({ key: id, fill: color })), [lenders])

	const nameXFormatter = useCallback((status) => t(`status.${status.toLowerCase()}`), [t])
	const nameYFormatter = useCallback((lenderId) => lenders[lenderId].name, [lenders])

	return (
		<DashboardChartSkeleton show={statistics.isLoading}>
			<Grid gridTemplateRows="auto 1fr" justifyItems="center" h100 w100>
				<Grid className={classes.metadata} w100>
					<DashboardOffers__ChartBarLegends lenders={lenders} />
					<DashboardOffers__ChartBarMenu />
				</Grid>
				<Grid className={classes.scroll} justifyItems="center" alignItems="center" h100 w100>
					<ChartMultipleBar
						className={classes.bar}
						data={data}
						keyName="status"
						values={values}
						barSize={30}
						barProps={{ stackId: "status" }}
						nameXFormatter={nameXFormatter}
						nameYFormatter={nameYFormatter}
						aria-label={t("chart.bar.arialabel")}
						showLabelOnEmptyData
						hideLegend
					/>
				</Grid>
			</Grid>
		</DashboardChartSkeleton>
	)
}
