import { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { CustomDataGrid } from "@arbolfinanciero/kikoya-lib-hub/controls/CustomDataGrid"
import { Button } from "@arbolfinanciero/kikoya-lib-hub/controls/Button"
import { Loading } from "@arbolfinanciero/kikoya-lib-hub/controls/Loading"

import { TabErrorsTextField } from "./TabErrorsTextField"
import { useTabErrorsFilter } from "./useTabErrorsFilter"

import { useShowHide } from "hooks"

export const TabErrorsTable = ({ initialData, loadData }) => {
	const { t } = useTranslation("admin", { keyPrefix: "tab_errors" })

	const { tokenAccess } = useSelector((store) => store.auth)

	const [data, setData] = useState([])
	const filter = useTabErrorsFilter(initialData)
	const loading = useShowHide()
	const showLoading = loading.show
	const hideLoading = loading.hide

	const handleLoadData = useCallback(
		async (data = initialData) => {
			showLoading()
			setData(await loadData(tokenAccess, data))
			hideLoading()
		},
		[tokenAccess, showLoading, hideLoading, loadData, initialData]
	)

	const handleSubmit = (e) => {
		e.preventDefault()
		handleLoadData(filter.values)
	}

	useEffect(() => {
		handleLoadData()
	}, [handleLoadData])

	const filterKeys = Object.keys(initialData)
	const columns = [
		{ name: "id", text: "ID", size: "2fr" },
		{ name: "description", text: t("column.description"), size: `${14 - filterKeys.length}fr` },
		...filterKeys.map((name) => ({ name, text: t(`column.${name}`), size: "3fr" })),
	]

	return (
		<div className="admin__home__errors__table">
			<Loading loading={loading.isVisible}>
				<form className="admin__home__errors__table__filter" onSubmit={handleSubmit}>
					{filterKeys.map((name, index) => (
						<TabErrorsTextField name={name} filter={filter} key={index} />
					))}
					<Button type="submit" variant="contained" color="primary">
						{t("button.search")}
					</Button>
				</form>
				<CustomDataGrid columns={columns} data={data} mobileSecondaryColumn="invoice_id" height="100%" />
			</Loading>
		</div>
	)
}
