import { Switch } from "@mui/material"
import React, { useState } from "react"

export const SwitchColors = ({idEl, firstColor, secondColor}) => {

	const [isChecked, setChecked] = useState(true)

	const handleChecked = (event) =>{
		setChecked(event.target.checked)
	}
	return (
		<div>
			<div className="branding_container_colors">
				<div
					style={{ background: secondColor, border:`1px solid ${firstColor}` }}
					className="branding__color"
					onClick={() => {setChecked(false)}}></div>
				<Switch checked={isChecked}  onChange={handleChecked}></Switch>
				<div
					style={{ background: firstColor, border:`1px solid ${secondColor}` }}
					className="branding__color"
					onClick={() => {setChecked(true)}}></div>

			</div>
			<div className="branding_container_picker">
				<input
					style={{
						backgroundColor: isChecked ? firstColor : secondColor,
						color: !isChecked ? firstColor : secondColor,
						borderColor: !isChecked ? firstColor : secondColor
					}}
					value={isChecked ? firstColor : secondColor}
					className="branding__input"
					readOnly
					id={idEl}
				/>
			</div>

		</div>
	)
}
