import classes from "./financier.module.scss"

import { useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { IconButton } from "@arbolfinanciero/kikoya-lib-hub/controls"
import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks"
import { Menu, MenuItem } from "@mui/material"
import { MoreHoriz as More, InfoOutlined as Info } from "@mui/icons-material"

export const CustomInvoiceField = ({ no_folio, user_id }) => <a href={`/financier/Offers/${user_id}`}>{no_folio}</a>
export const CustomActionsField = ({ user_id }) => {
	const { t } = useTranslation("financier", { keyPrefix: "layout" })
	const navigate = useNavigate()
	const $moreButton = useRef()
	const menu = useShowHide()

	const onClickDetails = () => navigate(`/financier/profile/user/${user_id}`)

	return (
		<div className={classes.actions_field}>
			<IconButton onClick={menu.show} sx={{ padding: 0 }} ref={$moreButton}>
				<More />
			</IconButton>
			<Menu
				anchorEl={$moreButton.current}
				open={menu.isVisible}
				onClose={menu.hide}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}>
				<MenuItem onClick={onClickDetails} className={classes.menu__item}>
					<Info /> {t("invoice.menu.details")}
				</MenuItem>
			</Menu>
		</div>
	)
}
