import React, { useEffect } from "react"
import { Grid } from "@mui/material"
import HeaderPrincipal from "../Home/Header"

import { PrivacyModel } from "models/PrivacyModel"

function Privacidad() {
	const [privacidad, setPrivacidad] = React.useState("")
	const typeCookies = 13

	useEffect(() => {
		handleCookies()
	}, [])

	const handleCookies = async () => {
		try {
			const resp = await PrivacyModel.get({type_config: typeCookies})
			const jsonData = JSON.parse(resp.config_value)
			let text = ""
			if (jsonData.blocks.length > 0) {
				jsonData.blocks.map(item =>
					text += item.text
				)
				setPrivacidad(text)
			}
		} catch {
			setPrivacidad("")
		}
	}

	return (
		<>
			<HeaderPrincipal />
			<form className="form__edition">
				<Grid container spacing={2}>
					<Grid item xs={12} md={12} sm={12}>
						<h3
							style={{
								color: "#284056",
								textAlign: "justify"
							}}>{ privacidad }</h3>
					</Grid>
				</Grid>
			</form>
		</>
	)
}

export { Privacidad }
