import { select } from "d3"
import { useRef, useEffect, useMemo } from "react"

const BLACK = "#000"
const WHITE = "#FFF"

export const ChartDonutLabel = ({
	cx,
	cy,
	midAngle,
	innerRadius,
	outerRadius,
	percent,
	name,
	index,
	dataLengthBySide,
	labelFormatter,
}) => {
	const RADIAN = Math.PI / 180
	const sin = Math.sin(-RADIAN * midAngle)
	const cos = Math.cos(-RADIAN * midAngle)
	let isRightSide = false
	if (dataLengthBySide.angles) {
		const calculatedAngle = dataLengthBySide.angles.find((item) => item.name === name)
		if (calculatedAngle) isRightSide = calculatedAngle.angle > 180
	}
	const separation_by_item = 10
	const height_by_item = 35
	const size = height_by_item + separation_by_item
	// START POINT
	const midRadius = (outerRadius - innerRadius) / 2
	const sx = cx + (outerRadius + -midRadius) * cos
	const sy = cy + (outerRadius + -midRadius) * sin
	// CURVE POINT
	const mx = sx
	const total_left_height = dataLengthBySide.left * height_by_item + (dataLengthBySide.left - 1) * separation_by_item
	const my_left = cy - total_left_height / 2 + index * size
	const total_right_height =
		dataLengthBySide.right * height_by_item + (dataLengthBySide.right - 1) * separation_by_item
	const my_right = cy + total_right_height / 2 - (index + 1 - dataLengthBySide.left) * size + separation_by_item
	const my = isRightSide ? my_right : my_left
	// END POINT
	const lineSize = 200
	const ex = cx + (isRightSide ? 1 : -1) * (outerRadius / 2 + lineSize)
	const ey = my
	const textAnchor = isRightSide ? "end" : "start"

	const $text = useRef()

	const formattedName = useMemo(() => labelFormatter ? labelFormatter(name) : name, [name, labelFormatter])

	const wrap = () => {
		if ($text.current) {
			let self = select($text.current)
			let textLength = self.node().getComputedTextLength()
			let text = formattedName
			while (textLength > lineSize - (outerRadius - innerRadius) - 5 && text.length > 0) {
				text = text.slice(0, -1)
				self.text(text + "...")
				textLength = self.node().getComputedTextLength()
			}
		}
	}

	useEffect(wrap, [formattedName, outerRadius, innerRadius])

	return (
		<g>
			<circle cx={sx} cy={sy} r={3} fill={WHITE} stroke={BLACK} />
			<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={BLACK} fill="none" style={{ zIndex: 1 }} />

			<text x={ex} y={my} dy={18} textAnchor={textAnchor} fill="#333" fontSize="1.1em" ref={$text}>
				{formattedName}
			</text>
			<text textAnchor={textAnchor} x={ex} y={my} dy={32} fill="#999" fontSize="0.8em">
				{(percent * 100).toFixed(1)}%
			</text>
		</g>
	)
}
