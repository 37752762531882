import React from "react"
import { useParams } from "react-router-dom"
import Login from "../Login"
import { RecoveryPass } from "../RecoveryPass/RecoveryPass"
import { TerminosCondiciones } from "../Privacidad/TerminosCondiciones"
import { Privacidad } from "../Privacidad/Privacidad"

export const AuthUser = () => {
	const { step } = useParams()

	return (
		<div>
			{
				step === "2" ?
					<RecoveryPass />:
					step === "4" ?
						<TerminosCondiciones /> :
						step === "5" ?
							<Privacidad /> :
							<Login />
			}
		</div>
	)
}
