import classes from "./dashboard_clients.module.scss"

import { Flex } from "@arbolfinanciero/kikoya-lib-hub/controls/Flex"

import { useDashboardClientsContext } from "./DashboardClientsContext"

import {
	DashboardSummaryCard__TotalOffers,
	DashboardSummaryCard__WeeklyClients,
	DashboardSummaryCard__MonthlyClients,
} from "../_DashboardSummaryCards"

export const DashboardClients__Summary = () => {
	const { summary } = useDashboardClientsContext()
	const applicantWeek = summary.data?.applicant_week
	const allOffers = summary.data?.all_offers
	const applicantsLastMonth = summary.data?.applicants_last_month
	return (
		<Flex className={classes.summary} gap="1em" alignItems="flex-start" wrap w100>
			<DashboardSummaryCard__WeeklyClients
				isLoading={summary.isLoading}
				data={applicantWeek}
				withoutChart
				withoutRedirectLink
			/>
			<DashboardSummaryCard__MonthlyClients
				isLoading={summary.isLoading}
				value={applicantsLastMonth}
				withoutRedirectLink
			/>
			<DashboardSummaryCard__TotalOffers isLoading={summary.isLoading} value={allOffers} />
		</Flex>
	)
}
