import { useTranslation } from "react-i18next"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"

const createDataTab = (index) => ({
	id: `simple-tab-${index}`,
	"aria-controls": `simple-tabpanel-${index}`,
})

export const TabErrorsMenu = ({ tab }) => {
	const { t } = useTranslation("admin", { keyPrefix: "tab_errors" })
	const handleChange = (e, value) => tab.change(value)
	return (
		<Tabs value={tab.value} onChange={handleChange} indicatorColor="primary" textColor="inherit">
			<Tab label={t("tab.lenders")} {...createDataTab(0)} />
			<Tab label={t("tab.invoice_lenders")} {...createDataTab(1)} />
		</Tabs>
	)
}
