import "./tab_errors.scss"

import { useState } from "react"
import { TabErrorsMenu } from "./TabErrorsMenu"
import { TabErrorsLender } from "./TabErrorsLender"
import { TabErrorsInvoiceLender } from "./TabErrorsInvoiceLender"

const useTab = () => {
	const [value, change] = useState(0)
	return { value, change }
}

export const TabErrors = () => {
	const tab = useTab()
	return (
		<div className="admin__home__errors" data-testid="admin--errors">
			<TabErrorsMenu tab={tab} />
			{tab.value === 0 && <TabErrorsLender />}
			{tab.value === 1 && <TabErrorsInvoiceLender />}
		</div>
	)
}
