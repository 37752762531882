import { Route, Navigate, Routes } from "react-router-dom"

import { Auth } from "components/admin/Auth"
import { ChangeRecoveryPassword } from "components/admin/Auth/ChangeRecoveryPassword"

export const AuthAdminRouter = () => (
	<Routes>
		<Route path="/login/:step?/:user_type_id?" element={<Auth />} />
		<Route path="/change_password/:hash" element={<ChangeRecoveryPassword />} />
		<Route path="*" element={<Navigate to="/auth/admin/login" replace />} />
	</Routes>
)
