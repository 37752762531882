import { useState, useEffect, useCallback } from "react"

export const useDataLengthBySide = ({ donutData, data, keyName, keyValue }) => {
	const [dataLengthBySide, setDataLengthBySide] = useState({ left: 0, right: 0 })

	const getDataMidAngles = useCallback(() => {
		const total = donutData.reduce((prev, datum) => prev + datum[keyValue], 0)
		let lastAngle = 0
		return donutData.map((datum) => {
			const percentage = (datum[keyValue] * 360) / total
			const midAngle = lastAngle + percentage / 2
			lastAngle += percentage
			return { name: datum[keyName], angle: midAngle }
		})
	}, [donutData, keyValue, keyName])

	const getDataLengthBySide = () => {
		const dataMidAngles = getDataMidAngles()
		let left = 0
		let right = 0
		dataMidAngles.forEach(({ angle }) => {
			if (angle <= 180) left++
			else right++
		})
		setDataLengthBySide({ left, right, angles: dataMidAngles })
	}

	useEffect(getDataLengthBySide, [data, getDataMidAngles])

	return dataLengthBySide
}
