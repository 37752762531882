import {Grid} from "@arbolfinanciero/kikoya-lib-hub/controls/Grid"

import { Offers } from "components/Ecosystem/Solicitante/Carrousel/Offers"
import { HeaderAuth } from "../Home/HeaderAuth/HeaderAuth"

export const Layout = () => {
	return (
		<Grid gridTemplateColumns="100%" gridTemplateRows="auto 1fr" sx={{minHeight: "100%"}}>
			<HeaderAuth />
			<Offers />
		</Grid>
	)
}
