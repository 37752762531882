import axios from "axios"
import { getBaseURL } from "helpers"
import { handleCommonErrors } from "models/helpers"

const __endpoint = getBaseURL() + "/api/v1/folios"

const User = (() => {
	const resource = "user"
	const get = async (token, id) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const body = { headers }
			const url = `${__endpoint}/${resource}/${id}/`
			const response = await axios.get(url, body)
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
		}
	}
	return { resource, get }
})()

export const InvoiceModel = { __endpoint, User }
