import { useRef } from "react"

import { Modal } from "@arbolfinanciero/kikoya-lib-hub/controls/Modal"
import { CustomDataGrid } from "@arbolfinanciero/kikoya-lib-hub/controls/CustomDataGrid"
import { IconButton } from "@arbolfinanciero/kikoya-lib-hub/controls/IconButton"
import { Menu } from "@arbolfinanciero/kikoya-lib-hub/controls/Menu"
import { useModal } from "@arbolfinanciero/kikoya-lib-hub/hooks/useModal"

import AddBranchesIcon from "@mui/icons-material/AddBusinessOutlined"
import MoreIcon from "@mui/icons-material/MoreHoriz"
import ListIcon from "@mui/icons-material/ReorderOutlined"
import ActionIcon from "@mui/icons-material/BoltOutlined"

import { useAssociatedGroupsContext } from "./AssociatedGroupsContext"
import { AssociateNewBranchesModal } from "./AssociateNewBranchesModal"
import { DeleteAssociatedBranchesModal } from "./DeleteAssociatedBranchesModal"
import { DeleteAssociatedGroupModal } from "./DeleteAssociatedGroupModal"

const AssociateNewBranchesButton = (data) => {
	const { t } = useAssociatedGroupsContext()
	const associateNewBranchesModal = useModal()
	return (
		<>
			<IconButton
				onClick={associateNewBranchesModal.open}
				aria-label={t("button.associate_new_branches_arialabel")}>
				<AddBranchesIcon />
			</IconButton>
			<Modal open={associateNewBranchesModal.isOpen}>
				<AssociateNewBranchesModal groupId={data.pk} closeModal={associateNewBranchesModal.close} />
			</Modal>
		</>
	)
}

const MenuOption = ({ Icon, children }) => (
	<>
		<Icon />
		{children}
	</>
)

const MenuButton = (data) => {
	const { t } = useAssociatedGroupsContext()
	const $button = useRef()
	const menu = useModal()
	const deleteAssociatedBranchesModal = useModal()
	const deleteAssociatedGroupModal = useModal()

	const handleShowDeleteAssociatedBranchesModal = () => {
		deleteAssociatedBranchesModal.open()
		menu.close()
	}

	const handleShowDeleteAssociatedGroupModal = () => {
		deleteAssociatedGroupModal.open()
		menu.close()
	}

	const menuOptions = [
		{
			onClick: handleShowDeleteAssociatedBranchesModal,
			children: <MenuOption Icon={ListIcon}>{t("options.branches_list")}</MenuOption>,
		},
		{
			onClick: handleShowDeleteAssociatedGroupModal,
			children: <MenuOption Icon={ActionIcon}>{t("options.delete")}</MenuOption>,
		},
	]

	return (
		<>
			<IconButton onClick={menu.open} ref={$button} aria-label={t("button.menu_arialabel")}>
				<MoreIcon />
			</IconButton>
			<Menu open={menu.isOpen} onClose={menu.close} options={menuOptions} anchorEl={$button.current} />
			<DeleteAssociatedBranchesModal modal={deleteAssociatedBranchesModal} groupId={data.pk} />
			<DeleteAssociatedGroupModal modal={deleteAssociatedGroupModal} groupId={data.pk} />
		</>
	)
}

export const AssociatedGroupsTable = () => {
	const { associatedGroups, t } = useAssociatedGroupsContext()
	const columns = [
		{
			name: "identifier",
			text: t("columns.identifier"),
		},
		{
			name: "business_name",
			text: t("columns.business_name"),
		},
		{
			name: "name",
			text: t("columns.name"),
		},
		{
			name: "branches",
			text: t("columns.branches"),
		},
		{
			name: "actions",
			text: t("columns.actions"),
			render: (data) => (
				<>
					<AssociateNewBranchesButton {...data} />
					<MenuButton {...data} />
				</>
			),
		},
	]

	return <CustomDataGrid columns={columns} rowsByPage={10} defaultOrderBy="identifier" data={associatedGroups} />
}
