import classes from "./chart_bar.module.scss"

import { useTranslation } from "react-i18next"

import theme from "theme"
import PropTypes from "prop-types"
import { Bar, Legend } from "recharts"

import { generateRandomColor } from "helpers"

import { ChartBarBase } from "./ChartBarBase"

const CUSTOM_BAR_PROPS = { background: { fill: "transparent" } }

const ChartBar = ({ showLabelOnEmptyData = false, data = [], keyValue, barProps = {}, ...props }) => {
	const { t } = useTranslation("charts")

	if (data.length === 0 && showLabelOnEmptyData) return <h3 className={classes.bar__label_no_data}>{t("no_data")}</h3>

	return (
		<ChartBarBase {...props} data={data}>
			<Bar dataKey={keyValue} {...CUSTOM_BAR_PROPS} fill={theme.palette.primary.main} {...barProps} />
		</ChartBarBase>
	)
}

const ChartMultipleBar = ({
	showLabelOnEmptyData = false,
	values = [],
	data = [],
	barProps = {},
	CustomLegend,
	hideLegend = false,
	legendProps = {},
	...props
}) => {
	const { t } = useTranslation("charts")

	if (data.length === 0 && showLabelOnEmptyData) return <h3 className={classes.bar__label_no_data}>{t("no_data")}</h3>

	return (
		<ChartBarBase {...props} data={data}>
			{values.map(({ key, fill }, index) => (
				<Bar
					key={index}
					{...CUSTOM_BAR_PROPS}
					dataKey={key}
					fill={fill || generateRandomColor()}
					{...barProps}
				/>
			))}
			{!hideLegend && <Legend verticalAlign="top" content={CustomLegend} {...legendProps} />}
		</ChartBarBase>
	)
}

ChartBar.propTypes = {
	keyValue: PropTypes.string.isRequired,
	barProps: PropTypes.object,
	showLabelOnEmptyData: PropTypes.bool,
}

ChartMultipleBar.propTypes = {
	values: PropTypes.arrayOf(
		PropTypes.shape({ key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired })
	).isRequired,
	barProps: PropTypes.object,
	CustomLegend: PropTypes.any,
	hideLegend: PropTypes.bool,
	legendProps: PropTypes.object,
	showLabelOnEmptyData: PropTypes.bool,
}

export { ChartBar, ChartMultipleBar }
