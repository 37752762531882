import classes from "./dashboard_offers.module.scss"

import { Grid } from "@arbolfinanciero/kikoya-lib-hub/controls/Grid"

import { HeaderAuth } from "components/financiero/Home/HeaderAuth/HeaderAuth"

import { DashboardOffersProvider } from "./DashboardOffersContext"

import { DashboardOffers__Summary } from "./DashboardOffers__Summary"
import { DashboardOffers__Chart } from "./DashboardOffers__Chart"
import { DashboardOffers__Table } from "./DashboardOffers__Table"

export const DashboardOffers = () => {
	return (
		<Grid alignItems="start" gridTemplateRows="auto 1fr" gridTemplateColumns="100%" h100>
			<HeaderAuth />
			<Grid className={classes.container} justifyItems="center" gridTemplateColumns="100%" w100>
				<DashboardOffersProvider>
					<Grid className={classes.content} gap="1em" alignContent="start" gridTemplateColumns="100%" w100>
						<DashboardOffers__Summary />
						<Grid className={classes.statistics} gap="1em" alignItems="start" justifyItems="center" w100>
							<DashboardOffers__Chart />
							<DashboardOffers__Table />
						</Grid>
					</Grid>
				</DashboardOffersProvider>
			</Grid>
		</Grid>
	)
}
