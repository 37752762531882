import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import { setBranding } from "redux-kikoya/branding"

import { OffersSSO, Offers } from "@arbolfinanciero/kikoya-lib-hub/components"
import { Header } from "./Header"

import { getBaseURL } from "helpers"
import { LandingModel } from "models"

const getFeedbackConfig = () => {
	const {
		REACT_APP_NOTIFICATION_CENTER_ENDPOINT,
		REACT_APP_NOTIFICATION_CENTER_API_KEY,
		REACT_APP_NOTIFICATION_CENTER_DOMAIN,
		REACT_APP_NOTIFICATION_CENTER_SUBDOMAIN,
	} = process.env

	return {
		endpoint: REACT_APP_NOTIFICATION_CENTER_ENDPOINT,
		api_key: REACT_APP_NOTIFICATION_CENTER_API_KEY,
		domain: REACT_APP_NOTIFICATION_CENTER_DOMAIN,
		subdomain: REACT_APP_NOTIFICATION_CENTER_SUBDOMAIN,
	}
}

export const OfferSSO = () => {
	const { token } = useParams()
	const dispatch = useDispatch()
	const { logotipo } = useSelector((state) => state.branding)
	const [data, setData] = useState({})

	const loadBranding = (branding) => {
		dispatch(setBranding(LandingModel.__transformBranding(branding)))
	}

	const handleLoginOtp = (tokenData) => {
		setData({
			email: tokenData.user.email,
			tokenAccess: tokenData.access,
			name: tokenData.user.name,
			user_id: tokenData.user.id,
			user_type_id: tokenData.user.user_type_id,
		})
	}

	return (
		<>
			{logotipo && <Header />}
			{data.tokenAccess ? (
				<Offers
					endpoint={getBaseURL()}
					realTimeEnabled
					feedbackConfig={getFeedbackConfig()}
					applicantId={data.user_id}
					sessionData={{
						user_id: data.user_id,
						user_type_id: data.user_type_id,
						tokenAccess: data.tokenAccess,
						name: data.name,
						email: data.email,
					}}
				/>
			) : (
				<OffersSSO
					endpoint={getBaseURL()}
					token={token}
					feedbackConfig={getFeedbackConfig()}
					OTPActivated
					realTimeEnabled
					onValidateOTP={handleLoginOtp}
					onBrandingLoaded={loadBranding}
				/>
			)}
		</>
	)
}
