import React from "react"
import { Building } from "../../landing/Building"

export const Question = () => {
	return (
		<div>
			<Building />
		</div>
	)
}
