const createSessionController = (keyWord, initialData) => ({
	get: () => {
		const data = sessionStorage.getItem(keyWord)
		if (data) return JSON.parse(data)
		return initialData
	},
	change: (newData) => sessionStorage.setItem(keyWord, JSON.stringify(newData)),
	remove: () => sessionStorage.removeItem(keyWord),
})

export const SessionManager = {
	user: createSessionController("SESSION__USER"),
}
