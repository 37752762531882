import classes from "./associated_groups.module.scss"

import { useMemo } from "react"
import { useSelector } from "react-redux"

import { SelectList } from "@arbolfinanciero/kikoya-lib-hub/controls/SelectList"

import { useForm } from "@arbolfinanciero/kikoya-lib-hub/hooks/useForm"

import { useAssociatedGroupsContext } from "./AssociatedGroupsContext"
import { AssociateNewModal } from "./AssociateNewModal"

import { BranchModel } from "models/BranchModel"

const useBranchesForm = (groupId) => {
	const { nonAssociatedBranches } = useAssociatedGroupsContext()

	const form = useForm({
		inputs: {
			branches: { initialValue: [], required: true },
		},
	})
	const options = useMemo(() => {
		const _branchesAssociated = nonAssociatedBranches[groupId] || []
		return _branchesAssociated.map(({ pk, distributor }) => ({ value: pk, text: distributor }))
	}, [nonAssociatedBranches, groupId])

	return { form, options }
}

export const AssociateNewBranchesModal = ({ groupId, closeModal }) => {
	const { tokenAccess } = useSelector((state) => state.auth)
	const { wid, t } = useAssociatedGroupsContext()

	const { form, options } = useBranchesForm(groupId)

	const onSubmit = async () => {
		if (!form.validate()) return null
		await BranchModel.Associations.change(tokenAccess, wid, { associate: true, branches_id: form.values.branches })
		return true
	}

	const handleChange = (value) => form.change("branches", value)
	const handleBlur = () => form.validateFormat("branches")

	return (
		<AssociateNewModal title={t("modal_associate_new_branches.title")} onSubmit={onSubmit} closeModal={closeModal}>
			<SelectList
				name="branches"
				className={classes.modal_associate_new__select}
				value={form.values.branches}
				onChange={handleChange}
				onBlur={handleBlur}
				options={options}
				error={form.errors.branches ? true : false}
				helperText={form.errors.branches}
				aria-label={t("inputs.branches_arialabel")}
				showAllValuesOption
			/>
		</AssociateNewModal>
	)
}
