import { Route, Navigate, Routes } from "react-router-dom"
import { SubdomainManager } from "utils/SubdomainManager"
import { extractUrl } from "helpers"

import { AuthAdminRouter } from "./AuthAdminRouter"
import { AuthUserRouter } from "./AuthUserRouter"
import LoginFinanc from "components/financiero/Login/index"

export const AuthRouter = () => {
	const { subdomain } = extractUrl()

	return (
		<Routes>
			<Route path="/user/*" element={<AuthUserRouter />} />
			<Route path="/financier/login" element={<LoginFinanc />} />
			{SubdomainManager.is.default(subdomain) && <Route path="/admin/*" element={<AuthAdminRouter />} />}
			<Route path="*" element={<Navigate to="/auth/user/login" replace />} />
		</Routes>
	)
}
