import { Grid } from "@arbolfinanciero/kikoya-lib-hub/controls/Grid"

import { AddEditFinancierForm } from "components/financiero/Configuracion/FinancierUser/AddEdit/AddEditFinancierForm"
import { HeaderAuth } from "../HeaderAuth/HeaderAuth"

export const AddEdit = (props) => {
	return (
		<Grid w100 gap="1em" gridTemplateRows="auto 1fr" alignItems="start">
			<HeaderAuth />
			<AddEditFinancierForm {...props} admin />
		</Grid>
	)
}
