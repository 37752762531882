import { HeaderAuth } from "../Home/HeaderAuth/HeaderAuth"
import { Grid } from "@mui/material"
import React, { useState } from "react"
import Carousel from "../../NavigationCarrousel/Carousel"
import CardTermsAndConditions from "./CardSettings/CardTermsAndConditions"
import CardNoticePrivacy from "./CardSettings/CardNoticePrivacy"
import CardCookies from "./CardSettings/CardCookies"
import CardBranding from "./CardSettings/CardBranding"
import styles from "./styles.module.scss"
import "./styles.scss"

export const Configuracion = () => {
	const [collapsed] = useState(false)

	return (
		<>
			<HeaderAuth />
			<br />
			<br />
			<Grid container spacing={1}>
				<Grid xs={1} md={1} sm={1}></Grid>
				<Grid xs={10} md={10} sm={10}>
					<div className={styles.background}>
						<div className={styles.container}>
							<Carousel collapse={collapsed}>
								<CardTermsAndConditions/>
								<div></div>
								<CardNoticePrivacy />
								<div></div>
								<CardCookies />
								<div></div>
								<CardBranding />
							</Carousel>
						</div>
					</div>
				</Grid>
				<Grid xs={1} md={1} sm={1}></Grid>
			</Grid>
		</>
	)

}
