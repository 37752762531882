import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks/useShowHide"

import { MerchantModel } from "models/MerchantModel"
import { useAlertMessages } from "AlertMessageContext"

import { useClientsStatisticsDates } from "./useClientsStatisticsDates"

export const useClientStatisticsData = ({ t, range }) => {
	const { tokenAccess } = useSelector((state) => state.auth)
	const messages = useAlertMessages()
	const changeErrors = messages.errors.change

	const prepareClientsStatisticsDates = useClientsStatisticsDates({ range })

	const [branches, setBranches] = useState([])
	const [clients, setClients] = useState([])
	const { show: showLoading, hide: hideLoading, isVisible: isLoading } = useShowHide()

	const validateCustomDateRange = range.validateCustomDateRange

	useEffect(() => {
		const fetchData = async () => {
			showLoading()
			try {
				const body = { date_filter: range.value }
				const { isValid, startDate, endDate } = validateCustomDateRange()
				if (!isValid) return hideLoading()

				if (startDate) body.start_date = startDate
				if (endDate) body.end_date = endDate
				const response = await MerchantModel.Folio.getClientStatistics(tokenAccess, body)
				const preparedClients = prepareClientsStatisticsDates({
					clients: response.clients,
					customStartDate: startDate,
					customEndDate: endDate,
				})
				setClients(preparedClients)
				setBranches(response.branches)
			} catch (error) {
				changeErrors([t("error.message")], { title: t("error.title"), sx: { maxWidth: "20em" } })
			}
			hideLoading()
		}
		fetchData()
	}, [
		t,
		tokenAccess,
		showLoading,
		hideLoading,
		changeErrors,
		range.value,
		validateCustomDateRange,
		prepareClientsStatisticsDates,
	])

	return { branches, clients, isLoading }
}
