import classes from "./groups.module.scss"

import { useNavigate } from "react-router-dom"

import { CustomDataGrid } from "@arbolfinanciero/kikoya-lib-hub/controls/CustomDataGrid"
import { Loading } from "@arbolfinanciero/kikoya-lib-hub/controls/Loading"
import { IconButton } from "@arbolfinanciero/kikoya-lib-hub/controls/IconButton"

import { useModal } from "@arbolfinanciero/kikoya-lib-hub/hooks/useModal"

import List from "@mui/icons-material/Reorder"
import Visibility from "@mui/icons-material/Visibility"
import Edit from "@mui/icons-material/Edit"

import { useGroupsContext } from "./GroupsContext"
import { GroupsForm } from "./GroupsForm"

const Actions = (data) => {
	const { form } = useGroupsContext()
	const modal = useModal()
	const navigate = useNavigate()

	const handleClickSee = async () => {
		modal.open()
		const valid = await form.openReadOnly(data?.pk)
		if (!valid) modal.close()
	}

	const handleClickEdit = async () => {
		modal.open()
		const valid = await form.startEditing(data?.pk)
		if (!valid) modal.close()
	}

	const handleShowBranches = () => {
		navigate(`/admin/whitelabel/groups/${data?.pk}/branches`)
	}

	return (
		<div className={classes.actions}>
			<IconButton onClick={handleShowBranches} className={classes.actions__icon}>
				<List fontSize="inherit" />
			</IconButton>
			<IconButton onClick={handleClickSee} className={classes.actions__icon}>
				<Visibility fontSize="inherit" />
			</IconButton>
			<IconButton onClick={handleClickEdit} className={classes.actions__icon}>
				<Edit fontSize="inherit" />
			</IconButton>
			<GroupsForm modal={modal} />
		</div>
	)
}

export const GroupsDataGrid = () => {
	const { t, groups, loading } = useGroupsContext()

	const columns = [
		{
			name: "identifier",
			text: t("column.identifier"),
		},
		{
			name: "business_name",
			text: t("column.business_name"),
		},
		{
			name: "name",
			text: t("column.name"),
		},
		{
			name: "branches",
			text: t("column.branches"),
		},
		{
			name: "actions",
			text: t("column.actions"),
			render: Actions,
		},
	]

	return (
		<Loading loading={loading.isVisible} data-testid="admin--groups--loading">
			<CustomDataGrid
				className={classes.data_grid}
				columns={columns}
				data={groups}
				height="100%"
				defaultOrderBy="identifier"
			/>
		</Loading>
	)
}
