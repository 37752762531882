import { useState, useEffect, useCallback, useMemo } from "react"
import { useSearchParams } from "react-router-dom"

import { format, isValid } from "date-fns"

import { useDateRange } from "@arbolfinanciero/kikoya-lib-hub/hooks/useDateRange"

const OPTIONS = {
	ALL: "all",
	TODAY: "today",
	MONTH_TO_DATE: "month_to_date",
	CUSTOM: "custom",
}

const formatDate = "yyyy-MM-dd"

export const useRange = ({ t }) => {
	const [searchParams, setSearchParams] = useSearchParams()
	const [range, setRange] = useState(searchParams.get("range") || OPTIONS.MONTH_TO_DATE)
	const currentDate = new Date()
	const customDateRange = useDateRange({ defaultFrom: currentDate, defaultTo: currentDate, dayLimit: 95 })

	useEffect(() => {
		const range = searchParams.get("range")

		if (range) {
			searchParams.delete("range")
			setSearchParams(searchParams)
		}
	}, [searchParams, setSearchParams])

	const validateCustomDateRange = useCallback(() => {
		if (range === OPTIONS.CUSTOM) {
			const isAValidRange =
				isValid(customDateRange.from.value) &&
				isValid(customDateRange.to.value) &&
				customDateRange.errors.length === 0

			if (isAValidRange) {
				const startDate = format(customDateRange.from.value, formatDate)
				const endDate = format(customDateRange.to.value, formatDate)
				return { isValid: true, startDate, endDate }
			}

			return { isValid: false }
		}

		return { isValid: true }
	}, [range, customDateRange.from.value, customDateRange.to.value, customDateRange.errors.length])

	return {
		value: range,
		change: setRange,
		options: Object.values(OPTIONS).map((option) => ({ value: option, text: t(`range_select.${option}`) })),
		customDateRange,
		validateCustomDateRange,
		formatDate,
		is: useMemo(() => ({
			custom: range === OPTIONS.CUSTOM,
			all: range === OPTIONS.ALL,
			today: range === OPTIONS.TODAY,
			monthToDate: range === OPTIONS.MONTH_TO_DATE,
		}), [range]),
	}
}
