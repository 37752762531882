import axios from "axios"
import { getBaseURL } from "helpers"
import { handleCommonErrors } from "models/helpers"

const __endpoint = getBaseURL() + "/api/v1/folios"

const getByUser = async (token, user_id) => {
	try {
		const url = `${__endpoint}/user/${user_id}/`
		const headers = { Authorization: "Bearer " + token }
		const body = { headers }
		const res = await axios.get(url, body)
		const response = res.data || {}
		response.folio_vehicle = {}
		response.folio_vehicles.forEach((item) => {
			response.folio_vehicle[item.category_vehicle] = item.specs
		})
		delete response.folio_vehicles
		return response
	} catch (error) {
		await handleCommonErrors(error)
	}
}

export const FoliosModel = { __endpoint, getByUser }
