import "./header_auth.scss"

import { AppBar } from "@mui/material"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { useStyles } from "./useStyles"
import { AccountButton } from "./AccountButton"
import { AdminButton } from "./AdminButton"
import { DashboardButton } from "./DashboardButton"

export const HeaderAuth = () => {
	const classes = useStyles()
	const navigate = useNavigate()
	const { logotipo } = useSelector((state) => state.branding)
	const redirect = () => {
		navigate("financier/Layout")
	}
	return (
		<AppBar position="sticky" className={classes.header}>
			<div className={`header__container_logo ${classes.logo}`}>
				<img className={`logo ${classes.logo}`} src={logotipo} alt="logo" onClick={redirect}></img>
			</div>
			<DashboardButton />
			<AdminButton />
			<AccountButton />
		</AppBar>
	)
}
