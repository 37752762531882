import createTheme from "@mui/material/styles/createTheme"
import { primary, secondary } from "helpers"

const theme = createTheme({
	palette: {
		primary: {
			main: primary(),
			// main: "#fcbb13"
		},
		secondary: {
			main: secondary(),
			light: "#ffb644",
			dark: "#c55600",
			contrastText: "#000000",
		},
		error: {
			main: "#A21C2B",
		},
	},
})

export const reloadTheme = () => {
	theme.palette.primary.main = primary()
	theme.palette.secondary.main = secondary()
}

export default theme
