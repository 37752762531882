import classes from "./dashboard_chart.module.scss"

import { useTranslation } from "react-i18next"

import { Grid } from "@arbolfinanciero/kikoya-lib-hub/controls/Grid"
import { Select } from "@arbolfinanciero/kikoya-lib-hub/controls/Select"
import { DateRangeInput } from "@arbolfinanciero/kikoya-lib-hub/controls/DateRangeInput"

export const DashboardChart = ({ children, extraControls, range }) => {
	const { t } = useTranslation("financier", { keyPrefix: "dashboard" })
	return (
		<Grid
			className={classes.container}
			gap="2em"
			justifyItems="center"
			alignItems="center"
			gridTemplateColumns="100%"
			gridTemplateRows="auto 1fr">
			<Grid
				className={extraControls ? classes.controls : classes.controls_simple}
				alignItems="start"
				gap="1em"
				w100>
				<Select
					className={classes.range_select}
					value={range.value}
					onChange={range.change}
					options={range.options}
					aria-label={t("range_select.arialabel")}
				/>
				{range.is.custom && (
					<DateRangeInput
						className={classes.date_range_input}
						dateRange={range.customDateRange}
						fromDateInputProps={{ inputHTMLProps: { "aria-label": t("custom_date.from.arialabel") } }}
						toDateInputProps={{ inputHTMLProps: { "aria-label": t("custom_date.to.arialabel") } }}
						withArrow
					/>
				)}
				{extraControls && <div className={classes.extra}>{extraControls}</div>}
			</Grid>
			{children}
		</Grid>
	)
}
