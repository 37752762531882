import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import makeStyles from "@mui/styles/makeStyles"

import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import MenuItem from "@mui/material/MenuItem"
import Menu from "@mui/material/Menu"
import ListItemIcon from "@mui/material/ListItemIcon"

import ExitToApp from "@mui/icons-material/ExitToApp"
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined"

import { logout } from "redux-kikoya/auth"

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		height: "80px",
		flexDirection: "row",
	},
	container: {
		margin: 0,
		display: "grid",
		alignItems: "center",
		gridTemplateColumns: "1fr auto",
		gridTemplateRows: "100%",
		padding: "10px 20px",
		width: "100%",
		height: "100%",
	},
	miniContainer: {
		height: "100%",
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
}))

export const HeaderAuth = () => {
	const classes = useStyles()
	const { t } = useTranslation()
	const { logotipo } = useSelector((state) => state.branding)
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleLogout = () => {
		dispatch(logout())
		navigate("auth/admin/login")
	}

	return (
		<AppBar className={classes.root} position="sticky">
			<div className={classes.container}>
				<div className="header__container_logo">
					<img className="logo" src={logotipo} alt="logo"></img>
				</div>
				<Toolbar>
					<div>
						<IconButton
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleMenu}
							color="inherit">
							<AccountCircleOutlined style={{ color: "var(--app-colorMenu)" }} />
						</IconButton>
						<Menu id="menu-appbar" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
							<MenuItem>
								<Link
									to={"/admin/ChangePassword"}
									style={{
										textDecoration: "none",
										color: "#000000DE",
									}}>
									{t("login.user.profile_change_password")}
								</Link>
							</MenuItem>
							<MenuItem onClick={handleLogout} sx={{ justifyContent: "space-between" }}>
								{t("login.user.profile_close_session")}
								<ListItemIcon sx={{ justifyContent: "end" }}>
									<ExitToApp fontSize="small" />
								</ListItemIcon>
							</MenuItem>
						</Menu>
					</div>
				</Toolbar>
			</div>
		</AppBar>
	)
}
