import axios from "axios"
import { getBaseURL } from "helpers"
import { handleCommonErrors } from "models/helpers"

const __endpoint = getBaseURL() + "/api/v1/representation_type"

const getAll = async (token) => {
	try {
		const headers = { Authorization: "Bearer " + token }
		const body = { headers }
		const url = `${__endpoint}/`
		const response = await axios.get(url, body)
		return response.data
	} catch (error) {
		await handleCommonErrors(error)
		throw error
	}
}

export const RepresentationTypeModel = { __endpoint, getAll }
