import { useTranslation } from "react-i18next"
import validator from "validator"

export const useValidations = () => {
	const { t } = useTranslation("admin", { keyPrefix: "whitelabel.form" })

	const validate_host = (host) => {
		if (host.includes(",") || host.includes(":") || host.includes(";") || host.includes("//"))
			return t("error.invalid_host_format")
		if (host.includes("www") || host.includes("http") || host.includes("https")) return t("error.invalid_host_url")
		return null
	}

	const validate_principal_email = (principal_email) => {
		if (!validator.isEmail(principal_email)) return t("error.email_invalid")
	}

	const validate_contact_email = (contact_email) => {
		if (!validator.isEmail(contact_email)) return t("error.email_invalid")
	}

	const validate_id_folio = (id_folio) => {
		if (!validator.isAlphanumeric(id_folio)) return t("error.invalid__format")
		return null
	}

	return {
		host: validate_host,
		principal_email: validate_principal_email,
		contact_email: validate_contact_email,
		id_folio: validate_id_folio,
	}
}
