import classes from "./dashboard_summary.module.scss"

import { useTranslation } from "react-i18next"

import { BarChart, Bar, ResponsiveContainer, Tooltip, XAxis } from "recharts"
import { format, subDays } from "date-fns"

import { Text } from "@arbolfinanciero/kikoya-lib-hub/controls/Text"
import { Grid } from "@arbolfinanciero/kikoya-lib-hub/controls/Grid"
import { Flex } from "@arbolfinanciero/kikoya-lib-hub/controls/Flex"
import { Skeleton } from "@arbolfinanciero/kikoya-lib-hub/controls/Skeleton"

import CalendarIcon from "@mui/icons-material/TodayOutlined"

import { DashboardSummaryCard } from "./DashboardSummaryCard"

const BAR_WIDTH = 13
const CHART_WIDTH = 150
const BAR_RADIUS = 3

const SkeletonWeeklyClientsChart = ({ children, show }) => {
	if (!show) return children
	return (
		<Flex
			sx={{ width: CHART_WIDTH }}
			alignItems="flex-end"
			justifyContent="space-between"
			data-testid="financier--dashboard__summary--skeleton">
			{["20%", "60%", "30%", "80%", "40%", "70%", "100%"].map((height, index) => (
				<Skeleton
					key={index}
					height={height}
					width={BAR_WIDTH}
					sx={{ borderRadius: `${BAR_RADIUS}px ${BAR_RADIUS}px 0 0` }}
					dark
					show
				/>
			))}
		</Flex>
	)
}

const DashboardSummaryCard_WeeklyClientsChart = ({ data, dateFormat = "" }) => {
	const { t } = useTranslation("financier", { keyPrefix: "dashboard" })

	const preparedData = Array.from({ length: 7 }, (_, index) => {
		const dateOfTheWeek = subDays(new Date(), index)
		const formattedDateOfTheWeek = format(dateOfTheWeek, dateFormat)
		const datum = data.find((datumOfTheWeek) => datumOfTheWeek.created_at === formattedDateOfTheWeek)
		if (datum) return datum
		return { created_at: formattedDateOfTheWeek, total: 0 }
	}).reverse()

	return (
		<figure className={classes["card--weekly__chart"]} aria-label={t("summary_card.weekly_chart_arialabel")}>
			<ResponsiveContainer width={CHART_WIDTH} height="100%">
				<BarChart data={preparedData} barSize={BAR_WIDTH} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
					<Bar
						dataKey="total"
						fill="#fff"
						background={{ fill: "transparent" }}
						radius={[BAR_RADIUS, BAR_RADIUS, 0, 0]}
					/>
					<Tooltip
						cursor={{ fill: "rgba(0,0,0,0.2)" }}
						itemStyle={{ color: "#000" }}
						labelStyle={{ color: "#000" }}
						position={{ y: 50 }}
					/>
					<XAxis dataKey="created_at" hide />
				</BarChart>
			</ResponsiveContainer>
		</figure>
	)
}

export const DashboardSummaryCard__WeeklyClients = ({
	data = [],
	isLoading,
	withoutRedirectLink = false,
	withoutChart = false,
}) => {
	const { t } = useTranslation("financier", { keyPrefix: "dashboard" })
	const dateFormat = "yyyy-MM-dd"
	const currentDate = format(new Date(), dateFormat)

	const todayData = data.find(({ created_at }) => created_at === currentDate) || { total: 0 }

	return (
		<DashboardSummaryCard
			variant={withoutChart ? "weekly_without_chart" : "weekly"}
			Icon={CalendarIcon}
			redirectTo="/financier/dashboard/clients?range=today"
			redirectToAriaLabel={t("summary_card.link.range_today.arialabel")}
			withoutRedirectLink={withoutRedirectLink}
			isLoading={isLoading}>
			<Grid h100 gap="0.2em 1em" gridTemplateColumns="1fr auto">
				<Grid alignContent="center" gap="0.3em">
					<Skeleton show={isLoading} data-testid="financier--dashboard__summary--skeleton" dark>
						<Text className={classes.card__number}>
							{todayData.total} <span>{t("summary_card.new_clients")}</span>
						</Text>
					</Skeleton>
					<Skeleton show={isLoading} data-testid="financier--dashboard__summary--skeleton" dark>
						<Text>{t("summary_card.today")}</Text>
					</Skeleton>
				</Grid>
				{!withoutChart && (
					<Grid gridTemplateRows="auto 1fr" justifyItems="center" sx={{ marginBottom: "-10px" }}>
						<Skeleton show={isLoading} data-testid="financier--dashboard__summary--skeleton" dark>
							<Text>{t("summary_card.weekly")}</Text>
						</Skeleton>
						<SkeletonWeeklyClientsChart show={isLoading}>
							<DashboardSummaryCard_WeeklyClientsChart data={data} dateFormat={dateFormat} />
						</SkeletonWeeklyClientsChart>
					</Grid>
				)}
			</Grid>
		</DashboardSummaryCard>
	)
}
