import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, Link, useParams } from "react-router-dom"
import validator from "validator"

import { TextField, Button, IconButton, CircularProgress } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"

import { useForm } from "hooks"

import { UserModel } from "models/UserModel"

export const RecoveryPass = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [{ email }, handleChange] = useForm({ email: "" })
	const { user_type_id } = useParams()

	const [errors, setErrors] = useState({})
	const [loading, setLoading] = useState(false)
	const [buttonLogin, setButtonLogin] = useState(true)
	const [labelSuccess, setLabelSuccess] = useState(false)

	const handleSubmit = async (e) => {
		e.preventDefault()
		const errors = validForm()
		if (Object.keys(errors).length === 0) {
			setLoading(true)
			try {
				await UserModel.Password.recovery({ email, user_type_id })
				setLabelSuccess(true)
			} catch (error) {
				errors.email = t("general.recover_password.submit_exist")
			}
			setLoading(false)
		}
		setErrors(errors)
	}

	const validForm = () => {
		const errors = {}
		if (!validator.isEmail(email)) errors.email = t("general.recover_password.email_validate_label")
		return errors
	}

	const handleBack = () => {
		navigate(-1)
	}

	const onChange = (e) => {
		let error = ""
		if (e.target.name === "email") {
			if (!validator.isEmail(e.target.value)) {
				error = t("general.recover_password.email_validate_label")
				setLabelSuccess(false)
			}
		}

		if (validator.isEmpty(e.target.value)) {
			error = t("general.error.validator")
			setLabelSuccess(false)
		}

		setButtonLogin(error !== "")
		setErrors({ ...errors, [e.target.name]: error })
		handleChange(e)
	}

	return (
		<div className="registro__container">
			<div className="container_back">
				<IconButton onClick={handleBack} color="primary" className="container_back__button">
					<ArrowBackIcon />
				</IconButton>
			</div>
			<div className="registro__form">
				<form onSubmit={handleSubmit}>
					<h1 style={{ textAlign: "center" }} className="titulo">
						{t("general.recover_password.h1_message")}
					</h1>
					<p className="informacion_parrafo">{t("general.recover_password.p_message")}</p>
					<div className="registro__containerInput">
						<TextField
							className="registro__input"
							label={t("general.recover_password.email")}
							name="email"
							value={email}
							onChange={onChange}
							error={errors.email ? true : false}
							helperText={errors.email && errors.email}
							variant="outlined"
						/>
						{labelSuccess && (
							<div
								style={{
									paddingBottom: "2%",
									paddingRight: "50%",
									color: "green",
								}}>
								<label>{t("general.recover_password.label_message")}</label>
							</div>
						)}
						{loading ? (
							<CircularProgress style={{ width: "30px", height: "30px" }} />
						) : (
							<Button
								variant="contained"
								color="primary"
								style={{
									width: "50%",
									padding: "15px 0",
									maxWidth: "400px",
									borderRadius: "40px",
								}}
								type="submit"
								disabled={buttonLogin}>
								{t("general.recover_password.button_message")}
							</Button>
						)}
						{labelSuccess && (
							<Link style={{ display: "block", margin: "8px 0" }} className="forgotPass">
								{t("general.recover_password.link_resend_email")}
							</Link>
						)}
					</div>
				</form>
			</div>
			<div className="capaLogo"></div>
		</div>
	)
}
