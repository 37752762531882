import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles(() => ({
	root: { flexGrow: 1 },
	header: {
		display: "grid",
		gridTemplateColumns: "1fr auto auto auto",
		gridTemplateRows: "100%",
		alignItems: "center",
		height: "80px",
		paddingBlock: "10px",
		gap: "10px",
	},
	logo: {
		height: "100%",
		width: "100%",
		maxWidth: "200px",
		objectFit: "contain",
	},
	title: { flexGrow: 1, textTransform: "capitalize", display: "flex", alignItems: "center" },
	menuItem: { justifyContent: "space-between" },
	link: {
		textDecoration: "none",
		color: "#000000DE",
		display: "contents",
	},
	menuIcon: { justifyContent: "end", color: "#000" },
}))
