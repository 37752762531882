import { useState, useEffect } from "react"

export const useSize = ($container, updateOn) => {
	const [size, setSize] = useState({ width: 200, height: 200 })

	const resize = () => {
		if ($container.current) {
			const chart = $container.current.getElementsByClassName("recharts-pie")[0]
			if (chart) {
				const client = chart.getBoundingClientRect()
				setSize({ width: client.width, height: client.height })
			}
		}
	}

	useEffect(resize, [updateOn, $container])

	return size
}
