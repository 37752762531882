import "./style.scss"

import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import makeStyles from "@mui/styles/makeStyles"

import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import MenuItem from "@mui/material/MenuItem"
import Menu from "@mui/material/Menu"
import ListItemIcon from "@mui/material/ListItemIcon"

import ExitToApp from "@mui/icons-material/ExitToApp"
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined"

import { logout } from "redux-kikoya/auth"

const useStyles = makeStyles((theme) => ({
	root: {
		height: "80px",
		display: "grid",
		gap: "10px",
		alignItems: "center",
		paddingBlock: "10px",
		gridTemplateRows: "100%",
		gridTemplateColumns: "1fr auto auto auto",
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
}))

export const HeaderAuth = () => {
	const classes = useStyles()
	const { logotipo } = useSelector((state) => state.branding)
	const [anchorEl, setAnchorEl] = useState()
	const open = Boolean(anchorEl)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const { t } = useTranslation()

	const handleLogout = () => {
		dispatch(logout())
		navigate(".")
	}

	return (
		<AppBar position="sticky" className={classes.root}>
			<div className="header__container_logo">
				<img className="logo" src={logotipo} alt="logo"></img>
			</div>
			<Toolbar>
				<div>
					<IconButton
						aria-label="account of current user"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={handleMenu}
						color="inherit">
						<AccountCircleOutlined style={{ color: "var(--app-colorMenu)" }} />
					</IconButton>
					<Menu id="menu-appbar" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
						<MenuItem>
							<Link
								to={"/user/ChangePassword"}
								style={{
									textDecoration: "none",
									color: "#000000DE",
								}}>
								{t("login.user.profile_change_password")}
							</Link>
						</MenuItem>
						<MenuItem onClick={handleLogout} sx={{ justifyContent: "space-between" }}>
							{t("login.user.profile_close_session")}
							<ListItemIcon sx={{ justifyContent: "end" }}>
								<ExitToApp fontSize="small" />
							</ListItemIcon>
						</MenuItem>
					</Menu>
				</div>
			</Toolbar>
		</AppBar>
	)
}
