import axios from "axios"
import { getBaseURL } from "helpers"
import { handleCommonErrors, handleCustomErrors } from "models/helpers"

const __endpoint = getBaseURL() + "/api/v1/product"
const __model = "product"

const formatData = (datum) => ({
	id: datum.id,
	name: datum.name,
	description: datum.description,
	total_approved: Number(datum.total_approved),
	hitch_approved: Number(datum.hitch_approved),
	cat: Number(datum.cat),
	initial_payment: Number(datum.initial_payment),
	interest_rate: Number(datum.interest_rate),
	iva: Number(datum.iva),
	product_terms: datum.product_terms,
	lender: datum.lender,
	whitelabel: datum.whitelabel,
})

const formatDataNew = (datum) => {
	const data = formatData(datum)
	delete data.id
	return data
}

const getByLender = async (token, lender_id) => {
	try {
		const headers = { Authorization: "Bearer " + token }
		const body = { headers }
		const response = await axios.get(`${__endpoint}/?lender_id=${lender_id}`, body)
		const data = response.data
		return data.map(formatData)
	} catch (error) {
		await handleCommonErrors(error)
	}
}

const find = async (token, id) => {
	try {
		const headers = { Authorization: "Bearer " + token }
		const body = { headers }
		const response = await axios.get(`${__endpoint}/${id}/`, body)
		return formatData(response.data)
	} catch (error) {
		await handleCommonErrors(error)
	}
}

const post = async (token, body) => {
	try {
		const headers = { headers: { Authorization: "Bearer " + token } }
		const response = await axios.post(`${__endpoint}/`, formatDataNew(body), headers)
		return response.data
	} catch (error) {
		const response = await handleCommonErrors(error)
		await handleCustomErrors(response, __model)
	}
}

const put = async (token, body) => {
	try {
		const headers = { headers: { Authorization: "Bearer " + token } }
		const response = await axios.put(`${__endpoint}/${body.id}/`, formatData(body), headers)
		return response.data
	} catch (error) {
		const response = await handleCommonErrors(error)
		await handleCustomErrors(response, __model)
	}
}

export const ProductModel = { __endpoint, getByLender, find, post, put }
