import { useState, useEffect, useCallback } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"

import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"

import EditIcon from "@mui/icons-material/Edit"
import makeStyles from "@mui/styles/makeStyles"

import { Loading } from "@arbolfinanciero/kikoya-lib-hub/controls/Loading"
import { CustomDataGrid } from "@arbolfinanciero/kikoya-lib-hub/controls/CustomDataGrid"
import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks/useShowHide"

import { WhitelabelModel } from "models/WhitelabelModel"

const useStyles = makeStyles(() => ({
	container: {
		display: "grid",
		gridTemplateRows: "auto 1fr",
		gap: "1em",
		height: "100%",
		overflow: "hidden",
	},
	button: {
		background: "#FFFFFF",
		width: "100%",
		maxWidth: "12em",
		padding: "7px 0",
		borderRadius: "40px",
		borderRight: "2px solid var(--app-primaryColor)",
		borderLeft: "2px solid var(--app-primaryColor)",
		borderBottom: "2px solid var(--app-primaryColor)",
		borderTop: "2px solid var(--app-primaryColor)",
		textTransform: "capitalize",
		color: "black",
		justifySelf: "end",
	},
}))

const EditableWhitelabelButton = ({ id }) => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const onClick = (e) => {
		e.stopPropagation()
		navigate(`/admin/whiteLabel/edit/${id}`)
	}

	return (
		<IconButton
			onClick={onClick}
			color="primary"
			aria-label={t("admin.index.col_actions_edit_button_place")}
			size="small">
			<EditIcon fontSize="small" />
		</IconButton>
	)
}

export const TabWhitelabels = () => {
	const { t } = useTranslation()
	const { tokenAccess } = useSelector((state) => state.auth)
	const [whitelabels, setWhiteLabel] = useState()
	const { hide: hideLoading, show: showLoading, isVisible: isLoading } = useShowHide()
	const styles = useStyles()

	const columns = [
		{ name: "id", text: t("admin.index.col_id"), size: "2fr" },
		{ name: "host", text: t("admin.index.col_host"), size: "2fr" },
		{
			name: "actions",
			text: t("admin.index.col_actions"),
			size: "2fr",
			render: EditableWhitelabelButton,
		},
	]

	const getTablesIndex = useCallback(async () => {
		showLoading()
		try {
			const whitelabels = await WhitelabelModel.get(tokenAccess)
			setWhiteLabel(whitelabels)
		} catch (error) {
			console.error(error)
		}
		hideLoading()
	}, [tokenAccess, showLoading, hideLoading])

	useEffect(() => {
		try {
			getTablesIndex()
		} catch (error) {
			console.log(error)
		}
	}, [getTablesIndex])

	return (
		<Loading loading={isLoading} data-testid="admin--layout--whitelabels__loading">
			<Grid className={styles.container} data-testid="admin--whitelabels">
				<Button
					variant="contained"
					className={styles.button}
					type="submit"
					component={Link}
					to="/admin/Whitelabel/create">
					{t("admin.tab.button_add_whitelabel")}
				</Button>
				<CustomDataGrid data={whitelabels} columns={columns} height="100%" />
			</Grid>
		</Loading>
	)
}
