import React, { useState, useEffect, useRef, Children, cloneElement, createRef, useCallback } from "react"
import PropTypes from "prop-types"
import styles from "./Carousel.module.scss"
import ArrowNavigator from "./ArrowNav"
import styled from "styled-components"
import { collapseCards, expandCards } from "./collapseHelper"
import scrollTo from "./scrolHelper"

const CardCollapseContainer = styled.div`
	opacity: 1;
	left: ${(props) => props.cardWidth * props.index}px;
	&.collapse {
		animation: ${({ cardWidth, gap, index }) => collapseCards(cardWidth, gap, index)} 0.5s 0.7s forwards;
	}
	&.expand {
		animation: ${({ cardWidth, gap, index }) => expandCards(cardWidth, gap, index)} 0.5s forwards;
	}
`

const Carousel = ({ children, stepSize, navOnTop, navTitle, collapse, splitIndex }) => {
	const viewport = useRef(null)
	const cardRefs = useRef([...Array(children.length)].map(() => createRef()))
	const [atLeftEdge, setAtLeftEdge] = useState(true)
	const [atRightEdge, setAtRightEdge] = useState(false)
	const [expanded, setExpanded] = useState(false)
	const [cardWidth, setCardWidth] = useState(0)

	useEffect(() => {
		setCardWidth(cardRefs.current[0].current.clientWidth)
	}, [children])

	// const cardWidth = () => cardRefs.current[0].current.clientWidth;

	const checkIfAtEdge = () => {
		if (viewport.current) {
			const scrollLength = viewport.current.scrollLeft
			const viewportLength = viewport.current.clientWidth
			const totalLength = viewport.current.scrollWidth
			setAtRightEdge(scrollLength + viewportLength === totalLength)
			setAtLeftEdge(scrollLength === 0)
		}
	}

	const getScrollParams = (steps) => {
		const durationUnit = 300
		return {
			scrollWidth: steps * cardWidth,
			scrollTime: Math.min(steps * durationUnit, 600),
		}
	}
	const handleScroll = (isForward) => {
		const { scrollWidth, scrollTime } = getScrollParams(stepSize)
		const newPosition = viewport.current.scrollLeft + (isForward ? scrollWidth : -scrollWidth)
		return scrollTo({
			element: viewport.current,
			to: newPosition,
			duration: scrollTime,
			scrollDirection: "scrollLeft",
			callback: checkIfAtEdge,
			context: this,
		})
	}
	const moveToSplitIndex = useCallback(() => {
		const scrollTime = 600
		const newPosition = Math.max(splitIndex - 1, 0) * cardWidth
		return scrollTo({
			element: viewport.current,
			to: newPosition,
			duration: scrollTime,
			scrollDirection: "scrollLeft",
			callback: checkIfAtEdge,
			context: this,
		})
	}, [cardWidth, splitIndex])

	const handleGoForward = (e) => {
		e.preventDefault()
		handleScroll(true)
	}
	const handleGoBack = (e) => {
		e.preventDefault()
		handleScroll(false)
	}
	const toggleExpansion = () => {
		setExpanded(!expanded)
	}

	const handleCollapse = useCallback(() => {
		moveToSplitIndex()
		collapseCards()
	}, [moveToSplitIndex])
	const handleExpand = () => {}

	useEffect(() => {
		console.log("carousel")
		if (collapse) handleCollapse()
		else handleExpand()
	}, [collapse, handleCollapse])

	const wrapCard = (baseCard, index) => {
		const style1 = { paddingRight: "70px" }
		const style2 = {
			paddingRight: "0px",
			zIndex: children.length - index,
			border: "solid .5px",
			borderRadius: "7px",
		}
		const style = index === 1 || index === 3 || index === 5 ? style1 : index === children.length ? null : style2
		return (
			<div className={styles.card} ref={cardRefs.current[index]} style={style}>
				{index < splitIndex ? (
					baseCard
				) : (
					<CardCollapseContainer
						cardWidth={cardWidth}
						gap={10}
						index={index - splitIndex}
						className={collapse ? "collapse" : "expand"}>
						{baseCard}
					</CardCollapseContainer>
				)}
			</div>
		)
	}

	useEffect(() => {
		checkIfAtEdge()
		window.addEventListener("resize", checkIfAtEdge)
	})

	const ArrowNavPair = () => (
		<div className={styles.arrows}>
			<ArrowNavigator
				handleClick={handleGoBack}
				backward
				className={`${styles.arrow} ${styles.left} ${atLeftEdge && styles.hide}`}
			/>
			<ArrowNavigator
				handleClick={handleGoForward}
				className={`${styles.arrow} ${styles.right} ${atRightEdge && styles.hide}`}
			/>
		</div>
	)

	return (
		<div className={`${styles.container} ${navOnTop && styles.extendToEdge}`}>
			<div className={styles.titlebar}>
				{navOnTop && <ArrowNavPair />}
				{navTitle()}
			</div>

			<div className={styles.slider}>
				{!navOnTop && <ArrowNavPair />}
				<div className={styles.cardContainer}>
					<div className={styles.scrollable} ref={viewport} onScroll={checkIfAtEdge}>
						{Children.map(children, (child) =>
							cloneElement(child, {
								expanded,
								setExpanded: toggleExpansion,
							})
						).map(wrapCard)}
					</div>
				</div>
			</div>
		</div>
	)
}

Carousel.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	stepSize: PropTypes.number,
	cardDist: PropTypes.number,
	navOnTop: PropTypes.bool,
	navTitle: PropTypes.func,
}

Carousel.defaultProps = {
	children: [],
	stepSize: 1,
	cardDist: 13,
	navOnTop: false,
	navTitle: () => {},
}

export default Carousel
