import classes from "./dashboard_offers.module.scss"

import { Grid } from "@arbolfinanciero/kikoya-lib-hub/controls/Grid"

import { useDashboardOffersContext } from "./DashboardOffersContext"

import {
	DashboardSummaryCard__TotalClients,
	DashboardSummaryCard__WeeklyClients,
	DashboardSummaryCard__MonthlyClients,
} from "../_DashboardSummaryCards"

export const DashboardOffers__Summary = () => {
	const { summary } = useDashboardOffersContext()
	const applicantWeek = summary.data?.applicant_week
	const allApplicants = summary.data?.all_applicants
	const applicantsLastMonth = summary.data?.applicants_last_month
	return (
		<Grid className={classes.summary} gap="1em" justifyItems="center" alignItems="start" w100>
			<DashboardSummaryCard__TotalClients isLoading={summary.isLoading} value={allApplicants} />
			<DashboardSummaryCard__WeeklyClients isLoading={summary.isLoading} data={applicantWeek} />
			<DashboardSummaryCard__MonthlyClients isLoading={summary.isLoading} value={applicantsLastMonth} />
		</Grid>
	)
}
