import { useCallback, useEffect } from "react"
import { useSelector } from "react-redux"

import { useShowHide } from "@arbolfinanciero/kikoya-lib-hub/hooks"
import { AddressModel } from "models"

import { useAlertMessages } from "AlertMessageContext"

export const useSuburb = (form) => {
	const { tokenAccess } = useSelector((state) => state.auth)
	const messages = useAlertMessages()

	const { show: showLoading, hide: hideLoading, isVisible: isLoading } = useShowHide(false)
	const { change: changeErrors } = messages.errors || {}
	const setForm = form.set

	const find = useCallback(
		async (zip_code) => {
			try {
				showLoading()
				const response = (await AddressModel.Suburb.findByZipCode(tokenAccess, zip_code)) || []
				const data = response[0]
				setForm((values) => ({
					...values,
					cityId: data?.city?.pk || "",
					city: data?.city?.description || "",
					state: data?.city?.state?.description || "",
				}))
			} catch (error) {
				changeErrors(Object.values(error))
			}
			hideLoading()
		},
		[showLoading, tokenAccess, hideLoading, changeErrors, setForm]
	)

	useEffect(() => {
		const cp = form.values.cp
		if (cp.length === 5) find(cp)
		else
			setForm((values) => ({
				...values,
				cityId: null,
				city: "",
				state: "",
			}))
	}, [find, form.values.cp, setForm])

	return { isLoading }
}
