import classes from "./styles/products.module.scss"

import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import { HeaderAuth } from "../Home/HeaderAuth/HeaderAuth"

import { ProductsContext } from "./ProductsContext"
import { useProductsPage } from "./useProductsPage"
import { ProductsList } from "./ProductsList"
import { ProductsForm } from "./ProductsForm"

export const Products = () => {
	const { tokenAccess } = useSelector((state) => state.auth)
	const { lender_id } = useParams()
	const page = useProductsPage()

	return (
		<ProductsContext.Provider value={{ page, tokenAccess, lender_id }}>
			<div className={classes.products}>
				<HeaderAuth />
				{page.is.list ? <ProductsList /> : <ProductsForm />}
			</div>
		</ProductsContext.Provider>
	)
}
