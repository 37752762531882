import * as Sentry from "@sentry/react"
import { getEnvironment } from "helpers"

export const initSentry = () => {
	const env = getEnvironment()
	const dsn = process.env.REACT_APP_SENTRY_DSN
	if (!env.isDevelopment)
		Sentry.init({
			dsn,
			environment: env.value,
			integrations: [
				new Sentry.BrowserTracing({
					tracePropagationTargets: ["hub-test.kikoya.io", "hub-staging.kikoya.io", "api.kikoyahub.io"],
				}),
				new Sentry.Replay({
					networkDetailAllowUrls: [""],
					networkRequestHeaders: ["Authorization"],
				}),
			],
			tracesSampleRate: 1.0,
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,
		})
}
