import axios from "axios"
import { getBaseURL } from "helpers"
import { CacheManager } from "utils/CacheManager"

import { handleMaintenanceError, handleCommonErrors } from "models/helpers"

const __endpoint = getBaseURL() + "/api/v1/user"


const get = async (tokenAccess) => {
	try {
		const headers = { Authorization: `Bearer ${tokenAccess}` }
		const body = { headers }
		const res = await axios.get(`${__endpoint}/`, body)
		const response = res.data?.results || [{}]
		return response
	} catch (error) {
		await handleCommonErrors(error)
		throw error.response.data
	}
}

const find = async (tokenAccess, { id, user_type, host = CacheManager.branding.get().host }) => {
	try {
		const headers = { Authorization: `Bearer ${tokenAccess}` }
		const body = { id, user_type, host }
		const response = await axios.post(`${__endpoint}/client_by_id/`, body, { headers })
		return response.data
	} catch (error) {
		await handleCommonErrors(error)
		throw error.response.data
	}
}

const getRoles = async (tokenAccess) => {
	try {
		const headers = { Authorization: `Bearer ${tokenAccess}` }
		const body = { headers }
		const res = await axios.get(`${__endpoint}/roles/`, body)
		const response = res.data?.results || [{}]
		return response
	} catch (error) {
		await handleCommonErrors(error)
	}
}

const enable = async (tokenAccess, id) => {
	try {
		const headers = { Authorization: `Bearer ${tokenAccess}` }
		const body = { id }
		const response = await axios.post(`${__endpoint}/enable/`, body, { headers })
		return response.data
	} catch (error) {
		await handleCommonErrors(error)
	}
}

const update = async (tokenAccess, id, data) => {
	try {
		const headers = { Authorization: `Bearer ${tokenAccess}` }
		const response = await axios.put(`${__endpoint}/${id}/`, data, { headers })
		return response.data
	} catch (error) {
		console.log(error, "jeje")
		await handleCommonErrors(error)
		throw error.response.data
	}
}

const Password = {
	recovery: async ({ user_type_id, email, host = CacheManager.branding.get().host }) => {
		try {
			const bodyData = { host, email, user_type_id }
			const response = await axios.post(`${__endpoint}/recovery_password/`, bodyData)
			return response
		} catch (error) {
			handleMaintenanceError(error)
			throw error.response?.data
		}
	},
	changeRecovery: async ({ hash_recover, password, host = CacheManager.branding.get().host }) => {
		try {
			const bodyData = { host, hashRecover: hash_recover, password }
			const response = await axios.post(`${__endpoint}/change_recovery_password/`, bodyData)
			return response
		} catch (error) {
			handleMaintenanceError(error)
			throw error.response?.data
		}
	},
	reset: async (tokenAccess, { old_password, new_password, confirm_new_password }) => {
		try {
			const headers = { Authorization: `Bearer ${tokenAccess}` }
			const body = { old_password, new_password, confirm_new_password }
			const response = await axios.post(`${__endpoint}/reset_password/`, body, { headers })
			return response
		} catch (error) {
			await handleCommonErrors(error)
			throw error.response?.data
		}
	}
}

const Admin = {
	get: async (tokenAccess) => {
		try {
			const headers = { Authorization: `Bearer ${tokenAccess}` }
			const body = { headers }
			const res = await axios.get(`${__endpoint}/admin/`, body)
			const response = res.data?.results || [{}]
			return response
		} catch (error) {
			await handleCommonErrors(error)
			throw error.response.data
		}
	},
	create: async (tokenAccess, data) => {
		try {
			const headers = { Authorization: `Bearer ${tokenAccess}` }
			const response = await axios.post(`${__endpoint}/create_admin/`, data, { headers })
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
			throw error.response.data
		}
	},
}

const Financier = {
	create: async (tokenAccess, data) => {
		try {
			const headers = { Authorization: `Bearer ${tokenAccess}` }
			const response = await axios.post(`${__endpoint}/create_financier/`, data, { headers })
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
			throw error.response.data
		}
	},
}

export const UserModel = {
	__endpoint,
	Password,
	find,
	getRoles,
	enable,
	update,
	get,
	Admin,
	Financier,
}
