import { useParams } from "react-router-dom"
import { Header } from "components/NoSession/Header"
import { getBaseURL } from "helpers"

import { OffersSSO } from "@arbolfinanciero/kikoya-lib-hub/components"
import { Grid } from "@arbolfinanciero/kikoya-lib-hub/controls/Grid"

const getFeedbackConfig = () => {
	const {
		REACT_APP_NOTIFICATION_CENTER_ENDPOINT,
		REACT_APP_NOTIFICATION_CENTER_API_KEY,
		REACT_APP_NOTIFICATION_CENTER_DOMAIN,
		REACT_APP_NOTIFICATION_CENTER_SUBDOMAIN,
	} = process.env

	return {
		endpoint: REACT_APP_NOTIFICATION_CENTER_ENDPOINT,
		api_key: REACT_APP_NOTIFICATION_CENTER_API_KEY,
		domain: REACT_APP_NOTIFICATION_CENTER_DOMAIN,
		subdomain: REACT_APP_NOTIFICATION_CENTER_SUBDOMAIN,
	}
}

export const Offer = () => {
	const { token } = useParams()

	return (
		<Grid gridTemplateColumns="100%" gridTemplateRows="auto 1fr" sx={{ minHeight: "100%" }}>
			<Header />
			<OffersSSO endpoint={getBaseURL()} token={token} feedbackConfig={getFeedbackConfig()} realTimeEnabled />
		</Grid>
	)
}
