import { Grid } from "@arbolfinanciero/kikoya-lib-hub/controls/Grid"

import { AddEditFinancierForm } from "./AddEditFinancierForm"
import { HeaderAuth } from "../../../Home/HeaderAuth/HeaderAuth"

export const AddEdit = (props) => {
	return (
		<Grid gridTemplateRows="auto 1fr" alignItems="start" gap="1em">
			<HeaderAuth />
			<AddEditFinancierForm {...props} />
		</Grid>
	)
}
