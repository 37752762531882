import "./header.scss"
import { useSelector } from "react-redux"

import { AppBar } from "@mui/material"

export const Header = () => {
	const { logotipo } = useSelector((state) => state.branding)

	return (
		<AppBar position="sticky" className="no_session__header">
			<img className="no_session__header__logo" src={logotipo} alt="logo"></img>
		</AppBar>
	)
}
