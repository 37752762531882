import classes from "./profile.module.scss"

import { useTranslation } from "react-i18next"

import {
	TabButtons,
	Text,
	Icon as IconControl,
	StatusTag,
	Loading,
	Avatar,
} from "@arbolfinanciero/kikoya-lib-hub/controls"
import { formatPhoneNumber } from "@arbolfinanciero/kikoya-lib-hub/helpers"

import { TagFacesOutlined as Face, LocationOnOutlined as Location } from "@mui/icons-material"

import { HeaderAuth } from "../Home/HeaderAuth/HeaderAuth"
import { useProfileData, useProfileTab } from "./hooks"

const ProfileField = ({ Icon, label, children }) => {
	const { t } = useTranslation("financier", { keyPrefix: "profile" })
	return (
		<>
			{Icon && <IconControl Icon={Icon} />}
			<Text className={classes.profile__data__field}>
				<span className={classes.profile__data__field__label}>{label}</span>
				{children || t("label.no_info")}
			</Text>
		</>
	)
}

export const Profile = () => {
	const { t } = useTranslation("financier", { keyPrefix: "profile" })
	const data = useProfileData()
	const tab = useProfileTab()

	const subtitle = [data.whitelabelName, t("subtitle"), data.fullName].filter((v) => v).join(" • ")

	return (
		<main className={classes.container}>
			<HeaderAuth />
			<section className={classes.content}>
				<Loading loading={data.loading}>
					<Text className={classes.title} tag="h1">
						{t("title")}
					</Text>
					<Text>{subtitle}</Text>
					<TabButtons
						value={tab.current}
						onChange={tab.change}
						options={tab.options}
						variant="underlined"
						disabled
					/>
					<div className={classes.profile}>
						<div className={classes.profile__basic}>
							<Avatar big>
								{data.name[0]}
								{data.paternalName[0]}
							</Avatar>
							<StatusTag statusID={data.status} />
						</div>
						<div className={classes.profile__data}>
							<ProfileField label={t("label.full_name")} Icon={Face}>
								{data.fullName}
							</ProfileField>
							<ProfileField label={t("label.mobile_phone")}>
								{formatPhoneNumber(data.mobilePhone)}
							</ProfileField>
							<ProfileField label={t("label.email")}>{data.email}</ProfileField>
							<ProfileField label={t("label.state")} Icon={Location}>
								{data.state}
							</ProfileField>
							<ProfileField label={t("label.address")}>{data.address}</ProfileField>
							<ProfileField label={t("label.zip_code")}>{data.zipCode}</ProfileField>
						</div>
					</div>
				</Loading>
			</section>
		</main>
	)
}
