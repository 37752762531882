import axios from "axios"
import { getBaseURL } from "helpers"
import {
	handleCommonErrors,
	handleMaintenanceError,
	createCustomErrorsHandler,
	handleGenericErrors,
	handleAbortError,
	modelTranslations,
} from "models/helpers"

const __endpoint = getBaseURL() + "/api/v1/merchant"
const __endpointBranch = getBaseURL() + "/api/v1/branch"

const Folio = {
	getStatistics: async (token, { date_filter, start_date, end_date }) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const body = { headers, params: { date_filter, start_date, end_date } }
			const url = `${__endpoint}/folio_statistics/`
			const response = await axios.get(url, body)
			return response.data
		} catch (error) {
			handleMaintenanceError(error)
			throw error
		}
	},
	getSummary: async (token) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const body = { headers }
			const url = `${__endpoint}/folio_summary/`
			const response = await axios.get(url, body)
			return response.data
		} catch (error) {
			handleMaintenanceError(error)
			throw error
		}
	},
	getOfferReport: async (token, { date_filter, start_date, end_date }, signal) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const body = { headers, params: { date_filter, start_date, end_date }, signal }
			const url = `${__endpoint}/offer_report/`
			const response = await axios.get(url, body)
			return response.data
		} catch (error) {
			handleMaintenanceError(error)
			handleAbortError(error)
			throw error
		}
	},
	getClientStatistics: async (token, { date_filter, start_date, end_date }) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const body = { headers, params: { date_filter, start_date, end_date } }
			const url = `${__endpoint}/client_statistics/`
			const response = await axios.get(url, body)
			return response.data
		} catch (error) {
			handleMaintenanceError(error)
			throw error
		}
	},
	getClientReport: async (token, { date_filter, start_date, end_date }, signal) => {
		try {
			const t = await modelTranslations("merchant")
			const headers = { Authorization: "Bearer " + token }
			const body = { headers, params: { date_filter, start_date, end_date }, signal }
			const url = `${__endpoint}/client_report/`
			const response = await axios.get(url, body)
			const data = response.data.map(({ gender, ...rest }) => ({
				...rest,
				gender: gender ? t("folio.gender.female") : t("folio.gender.male"),
			}))
			return data
		} catch (error) {
			handleMaintenanceError(error)
			handleAbortError(error)
			throw error
		}
	},
}

const Group = {
	getAll: async (token) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const body = { headers }
			const url = `${__endpoint}/group/`
			const response = await axios.get(url, body)
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
			await handleGenericErrors(error)
		}
	},
	get: async (token, gid) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const body = { headers }
			const url = `${__endpoint}/group/${gid}/`
			const result = await axios.get(url, body)
			const { state, ...response } = result.data
			const states = state.map(({ pk }) => pk)
			const data = { ...response, states }
			return data
		} catch (error) {
			await handleCommonErrors(error)
			await handleGenericErrors(error)
		}
	},
	create: async (token, { identifier, name, business_name, rfc, states, manage_new_cars, manage_semi_new_cars }) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const body = { identifier, name, business_name, rfc, state: states, manage_new_cars, manage_semi_new_cars }
			const url = `${__endpoint}/group/`
			const response = await axios.post(url, body, { headers })
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
			const { t, t_general } = await createCustomErrorsHandler(error, "merchant")
			if (error.response.status === 400) {
				let _error
				const identifier = error?.response?.data?.identifier || []
				if (identifier.includes("duplicated_record")) _error = { identifier: [t("group.error.duplicated")] }
				else _error = { non_field_errors: [t_general("error.problem")] }
				throw _error
			}
		}
	},
	update: async (
		token,
		{ id, identifier, name, business_name, rfc, states, manage_new_cars, manage_semi_new_cars }
	) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const body = { identifier, name, business_name, rfc, state: states, manage_new_cars, manage_semi_new_cars }
			const url = `${__endpoint}/group/${id}/`
			const response = await axios.patch(url, body, { headers })
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
			const { t, t_general } = await createCustomErrorsHandler(error, "merchant")
			if (error.response.status === 400) {
				let _error
				const identifier = error?.response?.data?.identifier || []
				if (identifier.includes("duplicated_record")) _error = { identifier: [t("group.error.duplicated")] }
				else _error = { non_field_errors: [t_general("error.problem")] }
				throw _error
			}
		}
	},
}

const GroupAssociation = {
	get: async (token, wid) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const body = { headers }
			const url = `${__endpoint}/${wid}/group/`
			const response = await axios.get(url, body)
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
			await handleGenericErrors(error)
		}
	},
	associate: async (token, wid, groups_id) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const body = { group_id: groups_id }
			const url = `${__endpoint}/${wid}/associate_groups/`
			const response = await axios.post(url, body, { headers })
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
			await handleGenericErrors(error)
		}
	},
	delete: async (token, wid, group_id) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const data = { group_id }
			const url = `${__endpoint}/${wid}/delete_associated_group/`
			const response = await axios.delete(url, { data, headers })
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
			await handleGenericErrors(error)
		}
	},
}

const Branch = {
	get: async (token, gid) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const body = { headers }
			const url = `${__endpoint}/group/${gid}/branch/`
			const response = await axios.get(url, body)
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
			throw error
		}
	},

	create: async (token, gid, data) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const url = `${__endpoint}/group/${gid}/branch/`
			const response = await axios.post(url, data, { headers })
			return response.data
		} catch (error) {
			const { t, t_general } = await createCustomErrorsHandler(error, "merchant")
			await handleCommonErrors(error)
			if (error.response.status === 400) {
				const identifier = error?.response?.data?.identifier || []
				let _error
				if (identifier.includes("duplicated_record")) _error = { identifier: [t("branch.error.duplicated")] }
				else _error = { non_field_errors: [t_general("error.problem")] }
				throw _error
			}
			throw error
		}
	},

	update: async (token, gid, id, data) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const url = `${__endpoint}/group/${gid}/branch/${id}/`
			const response = await axios.patch(url, data, { headers })
			return response.data
		} catch (error) {
			const { t, t_general } = await createCustomErrorsHandler(error, "merchant")
			await handleCommonErrors(error)
			if (error.response.status === 400) {
				const identifier = error?.response?.data?.identifier || []
				let _error
				if (identifier.includes("duplicated_record")) _error = { identifier: [t("branch.error.duplicated")] }
				else _error = { non_field_errors: [t_general("error.problem")] }
				throw _error
			}
			throw error
		}
	},

	getById: async (token, gid, id) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const body = { headers }
			const url = `${__endpoint}/group/${gid}/branch/${id}/`
			const response = await axios.get(url, body)
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
			throw error
		}
	},

	switch: async (token, id) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const url = `${__endpointBranch}/${id}/switch_active/`
			const response = await axios.post(url, null, { headers })
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
			throw error
		}
	},
}

export const MerchantModel = { __endpoint, Folio, Group, GroupAssociation, Branch }
