import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { HeaderAuth } from "components/financiero/Home/HeaderAuth/HeaderAuth"

import { Offers as KikoyaOffers } from "@arbolfinanciero/kikoya-lib-hub/components"

import { getBaseURL } from "helpers"

const getFeedbackConfig = () => {
	const {
		REACT_APP_NOTIFICATION_CENTER_ENDPOINT,
		REACT_APP_NOTIFICATION_CENTER_API_KEY,
		REACT_APP_NOTIFICATION_CENTER_DOMAIN,
		REACT_APP_NOTIFICATION_CENTER_SUBDOMAIN,
	} = process.env

	return {
		endpoint: REACT_APP_NOTIFICATION_CENTER_ENDPOINT,
		api_key: REACT_APP_NOTIFICATION_CENTER_API_KEY,
		domain: REACT_APP_NOTIFICATION_CENTER_DOMAIN,
		subdomain: REACT_APP_NOTIFICATION_CENTER_SUBDOMAIN,
	}
}

export const OffersFinancier = () => {
	const { user_id: applicantId } = useParams()
	const { id: user_id, userType: user_type_id, tokenAccess, email, name } = useSelector((store) => store.auth)

	return (
		<>
			<HeaderAuth />
			<KikoyaOffers
				endpoint={getBaseURL()}
				applicantId={applicantId}
				sessionData={{ user_id, user_type_id, tokenAccess, email, name }}
				realTimeEnabled
				feedbackConfig={getFeedbackConfig()}
			/>
		</>
	)
}
