import axios from "axios"
import { getBaseURL } from "helpers"
import { handleCommonErrors, handleGenericErrors } from "models/helpers"

const __endpoint = getBaseURL() + "/api/v1/address"

const States = {
	get: async (token) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const body = { headers }
			const url = `${__endpoint}/state/`
			const response = await axios.get(url, body)
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
			await handleGenericErrors(error)
		}
	},
}

const Suburb = {
	findByZipCode: async(token, zip_code) => {
		try {
			const headers = { Authorization: "Bearer " + token }
			const body = { headers }
			const url = `${__endpoint}/suburb/${zip_code}/`
			const response = await axios.get(url, body)
			return response.data
		} catch (error) {
			await handleCommonErrors(error)
		}
	}
}

export const AddressModel = { __endpoint, States, Suburb }
