import classes from "./dashboard_summary.module.scss"

import { Link } from "react-router-dom"

import { Icon } from "@arbolfinanciero/kikoya-lib-hub/controls/Icon"
import { Grid } from "@arbolfinanciero/kikoya-lib-hub/controls/Grid"
import { Skeleton } from "@arbolfinanciero/kikoya-lib-hub/controls/Skeleton"

import ArrowIcon from "@mui/icons-material/CallMade"

export const DashboardSummaryCard = ({
	children,
	Icon: CustomIcon,
	className = "",
	variant,
	redirectTo,
	redirectToAriaLabel,
	withoutRedirectLink,
	isLoading = false,
}) => {
	const classNames = [className, classes.card, classes[`card--${variant}`]]

	return (
		<Grid
			className={classNames.join(" ")}
			gap="1em"
			gridTemplateColumns="auto 1fr"
			gridTemplateRows="100%"
			alignItems="center"
			w100>
			<CustomIcon className={classes.card__icon} />
			{children}
			{redirectTo && !withoutRedirectLink && (
				<Skeleton
					show={isLoading}
					variant="circular"
					sx={{ alignSelf: "start" }}
					data-testid="financier--dashboard__summary--skeleton"
					dark>
					<Link to={redirectTo} className={classes.card__link} aria-label={redirectToAriaLabel}>
						<Icon Icon={ArrowIcon} />
					</Link>
				</Skeleton>
			)}
		</Grid>
	)
}
