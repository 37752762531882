import { createContext, useContext } from "react"
import { useMessages } from "@arbolfinanciero/kikoya-lib-hub/hooks/useMessages"

export const AlertMessageContext = createContext()

export const AlertMessageProvider = ({ children }) => {
	const { ControlledAlertMessage, ...messages } = useMessages()
	return (
		<AlertMessageContext.Provider value={{ messages }}>
			<ControlledAlertMessage />
			{children}
		</AlertMessageContext.Provider>
	)
}

export const useAlertMessages = () => {
	const { messages } = useContext(AlertMessageContext)
	return messages
}
