import { useSelector } from "react-redux"

import { format } from "date-fns"

import { useRequestController } from "@arbolfinanciero/kikoya-lib-hub/hooks/useRequestController"

import { createCSV, downloadCSV } from "@arbolfinanciero/kikoya-lib-hub/helpers/files"

import { useDashboardOffersContext } from "./DashboardOffersContext"
import { DashboardChartMenu } from "../_DashboardChartMenu"

import { MerchantModel } from "models/MerchantModel"
import { useAlertMessages } from "AlertMessageContext"

export const DashboardOffers__ChartBarMenu = () => {
	const { tokenAccess } = useSelector((state) => state.auth)
	const { name_financier } = useSelector((state) => state.branding)
	const { t, range } = useDashboardOffersContext()
	const messages = useAlertMessages()

	const downloadController = useRequestController()

	const startDownload = (data) => {
		const currentDate = format(new Date(), "d-MM-yyyy")
		const filename = t("export.filename_offers", { currentDate, merchant: name_financier.replace(" ", "_") })
		const columns = {
			folio_id: t("export.columns.folio_id"),
			score: t("export.columns.score"),
			pk: t("export.columns.folio_hub"),
			created_at: t("export.columns.created_at"),
			updated_at: t("export.columns.updated_at"),
			lender: t("export.columns.lender"),
			applicant: t("export.columns.name"),
			rfc: t("export.columns.rfc"),
			email: t("export.columns.email"),
			mobile_phone: t("export.columns.mobile_phone"),
			status: t("export.columns.status"),
			prepaid: t("export.columns.down_payment"),
			total_to_finance: t("export.columns.total_to_finance"),
			select_down_payment_lender: t("export.columns.select_down_payment_lender"),
			total_to_finance_lender: t("export.columns.total_to_finance_lender"),
			select_payment_term: t("export.columns.select_payment_term"),
			select_annual_rate: t("export.columns.select_annual_rate")
		}
		const csv = createCSV({ data, columns })
		downloadCSV({ csv, name: filename })
	}

	const fetchExportData = async () => {
		try {
			const body = { date_filter: range.value }
			const { isValid, startDate, endDate } = range.validateCustomDateRange()
			if (!isValid) return null
			downloadController.showLoading()

			if (startDate) body.start_date = startDate
			if (endDate) body.end_date = endDate
			const response = await MerchantModel.Folio.getOfferReport(tokenAccess, body, downloadController.signal)
			startDownload(response)
		} catch (error) {
			if (Object.keys(error).length > 0)
				messages.errors.change([t("error.message")], {
					title: t("error.exporting_title"),
					sx: { maxWidth: "20em" },
				})
		}
		downloadController.hideLoading()
	}

	return <DashboardChartMenu downloadController={downloadController} fetchExportData={fetchExportData} />
}
